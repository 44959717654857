import { useState, useEffect } from 'react'
import LessonsAPI, { Lesson } from '../../api/lessons'
import { useNavigate, useParams } from 'react-router-dom'
import { Anchor, Breadcrumbs, Button } from '@mantine/core'
import { Link } from 'react-router-dom'
import routes from '../../config/routes'
import TitleWithButton from '../../components/title/TitleWithButton'
import ConfirmModal from '../../components/modal/confirm'
import FormLocalizationSection from '../../components/forms/form-localization-section'
import LessonResourceCard from '../../components/card/lesson-resource-card'
import SelectLessonResourceModal from '../../components/modal/composite/select-lesson-resource'
import ArticleCard from '../../components/card/article-card'
import SelectArticleModal from '../../components/modal/composite/select-article'
import AddOrEditVideo from '../../components/add-or-edit-video'
import SelectImageModal from '../../components/modal/composite/select-image'
import SelectVideoModal from '../../components/modal/composite/select-video'
import useAuthEffect from '../../hooks/useAuthEffect'
import LessonResourcesCrud from '../../components/crud/lesson-resources-crud'
import ArticlesCrud from '../../components/crud/articles-crud'
import Loader from '../../components/loader'

type LessonsShowModalType = 'delete' | 'add_lesson_resource' | 'add_article' | 'add_video'

export default function LessonsShowPage({ lessonId }: { lessonId?: string } = {}) {
  const params = useParams()
  const navigate = useNavigate()
  const [lesson, setLesson] = useState<Lesson | null>(null)
  const [errorFetchingLesson, setErrorFetchingLesson] = useState(false)
  const [showDeleteModal, setShowDeleteModal] = useState(false)
  const [modalType, setModalType] = useState<LessonsShowModalType | null>(null)
  const id = lessonId || params.id!

  async function fetchLesson(id: string) {
    try {
      const lesson = await LessonsAPI.show(id)
      setLesson(lesson)
    } catch (error) {
      setErrorFetchingLesson(true)
    }
  }

  useAuthEffect(() => {
    // eslint-disable-next-line
    fetchLesson(id)
  })

  if (errorFetchingLesson) return <div>There was an error fetching the lesson you requested.</div>
  if (!lesson) return <Loader />

  return (
    <div className="page">
      <Breadcrumbs>
        <Anchor component={Link} to={routes.app.lessons.index}>
          lessons
        </Anchor>
        <Anchor component={Link} to={routes.app.lessons.show(lesson.id)}>
          {lesson.name}
        </Anchor>
      </Breadcrumbs>

      <TitleWithButton title={lesson.name}>
        <Button
          className="outlined blue"
          style={{ marginRight: 10 }}
          component={Link}
          to={routes.app.lessons.edit(lesson.id)}
        >
          edit
        </Button>
        <Button className="outlined red" onClick={() => setShowDeleteModal(true)}>
          delete
        </Button>
      </TitleWithButton>

      <AddOrEditVideo
        video={lesson.video}
        onAddOrEditClick={async () => {
          setModalType('add_video')
        }}
        onRemoveClick={async () => {
          try {
            await LessonsAPI.update(lesson.id, { videoId: null })
            await fetchLesson(lesson.id)
          } catch (err) {
            // TODO: handle this
          }
        }}
      />

      <LessonResourcesCrud
        lessonResources={lesson.lessonResources}
        lessonResourceShowRouteCB={lessonResource =>
          routes.app.lessons.lessonResources.show(lesson.id, lessonResource.id)
        }
        onSelectLessonResource={async lessonResource => {
          try {
            await LessonsAPI.attachLessonResource(lesson.id, lessonResource.id)
            await fetchLesson(lesson.id)
          } catch (err) {
            // TODO: handle this
          }
        }}
      />

      <ArticlesCrud
        articles={lesson.articles}
        articleShowRouteCB={article => routes.app.lessons.articles.show(lesson.id, article.id)}
        onSelectArticle={async article => {
          try {
            await LessonsAPI.attachArticle(lesson.id, article.id)
            await fetchLesson(lesson.id)
          } catch (err) {
            // TODO: handle this
          }
        }}
      />

      <ConfirmModal
        opened={showDeleteModal}
        onCancel={() => setShowDeleteModal(false)}
        onConfirm={async () => {
          await LessonsAPI.delete(lesson.id)
          navigate(routes.app.lessons.index)
        }}
        title="Are you sure?"
        message="Are you sure you want to delete this lesson?"
        confirmText="Really delete"
      />

      <SelectVideoModal
        opened={modalType === 'add_video'}
        onCancel={() => setModalType(null)}
        onSelectVideo={async video => {
          setModalType(null)
          try {
            await LessonsAPI.update(lesson.id, { videoId: video.id })
            await fetchLesson(lesson.id)
          } catch (err) {
            // TODO: handle this
          }
        }}
      />
    </div>
  )
}

import { Anchor, Button, Paper } from '@mantine/core'
import TitleWithButton from '../title/TitleWithButton'
import { ReactNode } from 'react'
import EmptyMessage from '../display/empty-message'
import { SupportCoachTip } from '../../api/supportCoachTips'
import { Link } from 'react-router-dom'
import routes from '../../config/routes'
import SortableItems from '../sortable-items'
import SupportCoachTipCard from '../card/support-coach-tip-card'

export default function SupportCoachTipsCrud({
  supportId,
  coachTips,
  emptyMessage = 'No coach tips found',
  onSort,
}: {
  supportId: string
  coachTips: SupportCoachTip[]
  onSort: (
    coachTip: SupportCoachTip,
    position: number,
    newCoachTips: SupportCoachTip[]
  ) => void | Promise<void>
  emptyMessage?: ReactNode
}) {
  return (
    <>
      <Paper mt={20} p={20} className="support-coach-tips-list">
        <TitleWithButton order={2} title="Coach Tips">
          <Button className="green filled" component={Link} to={routes.app.supports.coachTips.new(supportId)}>
            Add coach tip
          </Button>
        </TitleWithButton>

        {!coachTips.length && <EmptyMessage>{emptyMessage}</EmptyMessage>}
        <SortableItems
          itemType="SupportCoachTip"
          items={coachTips}
          onSort={onSort}
          render={coachTip => (
            <SupportCoachTipCard
              coachTip={coachTip}
              href={routes.app.supports.coachTips.show(supportId, coachTip.id)}
            />
          )}
        />
      </Paper>
    </>
  )
}

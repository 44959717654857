import { DateString } from '../config/DateStringTypes'
import routes from '../config/routes'
import { api } from './common'
import { UserContentTreeJourney } from './userJourneys'
import { UserNotes } from './userNotes'
import { UserSettings } from './userSettings'
import { UserSupport } from './userSupports'

export default class UsersAPI {
  public static async index({ query }: { query?: string } = {}) {
    const res = await api.get(routes.api.users.index, { params: { query } })
    return res.data as User[]
  }

  public static async show(id: string) {
    const res = await api.get(routes.api.users.show(id))
    return res.data as User
  }

  public static async journeys(id: string) {
    const res = await api.get(routes.api.users.journeys.index(id), { params: { reduxId: 'journeys.index' } })
    return res.data as UserContentTreeJourney[]
  }

  public static async supports(id: string) {
    const res = await api.get(routes.api.users.supports.index(id), { params: { reduxId: 'supports.index' } })
    return res.data as UserSupportsResponse
  }

  public static async activityLogEntries(id: string) {
    const res = await api.get(routes.api.users.activityLogEntries.index(id), {
      params: { reduxId: 'activityLogEntries.index' },
    })
    return res.data as ActivityResponse
  }

  public static async weightLogEntries(id: string) {
    const res = await api.get(routes.api.users.weightLogEntries.index(id), {
      params: { reduxId: 'weightLogEntries.index' },
    })
    return res.data as WeightResponse
  }

  public static async insightsReports(id: string) {
    const res = await api.get(routes.api.users.insightsReports.index(id), {
      params: { reduxId: 'insightsReports.index' },
    })
    return res.data as InsightsReportSummary[]
  }

  public static async insightsReport(id: string, insightsReportId: string) {
    const res = await api.get(routes.api.users.insightsReports.show(id, insightsReportId), {
      params: { reduxId: 'insightsReports.show' },
    })
    return res.data as InsightsReportDetail
  }

  public static async lookup({
    intercomId,
    intercomConversationId,
  }: {
    intercomId?: string
    intercomConversationId?: string
  }) {
    const res = await api.get(routes.api.users.lookup(), {
      params: { intercomId, intercomConversationId, reduxId: 'users.show' },
    })
    return res.data as User | null
  }

  public static async resetJourneys(id: string) {
    return await api.post(routes.api.users.resetJourneys(id), {
      reduxId: 'users.resetJourneys',
    })
  }
}

export interface User extends UserSettings {
  id: string
  rvoId: string
  email: string
  notes: UserNotes
}

export interface UserSupportsResponse {
  active: UserSupport | null
  complete: UserSupport[]
  recommended: UserSupport[]
  notYetRecommended: UserSupport[]
}

type ActivityUnits = 'minutes'
type DateMinutes = Partial<Record<DateString, number>>
type ActivityTypeDateMinutes = Record<string, DateMinutes>

export interface ActivityResponse {
  units: ActivityUnits
  activityMinutesGoal: number
  data: ActivityTypeDateMinutes
}

type WeightUnits = 'lbs'
type DateLbs = Partial<Record<DateString, number>>

export interface WeightResponse {
  units: WeightUnits
  weightGoal: number
  data: DateLbs
}

export interface InsightsReportSummary {
  id: string
  startOn: string
  endOn: string
  title: string
  numberOfMealsTracked: number
}

export interface InsightsReportDetail extends InsightsReportSummary {
  insights: CompositeInsights[]
  overview: CompositeInsightsOverview[]
}

export interface CompositeInsights {
  hidden: boolean
  label: string
  value: {
    value: string
    label: string
  }
  status: {
    label: string
    value: {
      value: string | null
      label: string
    }
  }
  graphUrl: string
}

export interface CompositeInsightsOverview {
  hidden: boolean
  label: string
  value: {
    value: string
    label: string
  }
}

import { Anchor, Button, Paper } from '@mantine/core'
import TitleWithButton from '../title/TitleWithButton'
import { Article } from '../../api/articles'
import { ReactNode, useState } from 'react'
import EmptyMessage from '../display/empty-message'
import SelectArticleModal from '../modal/composite/select-article'
import { Link, useNavigate } from 'react-router-dom'
import routes from '../../config/routes'
import ArticleCard from '../card/article-card'

export default function ArticlesCrud({
  articles,
  onSelectArticle,
  articleShowRouteCB,
  emptyMessage = 'No articles found',
}: {
  articles: Article[]
  onSelectArticle: (article: Article) => void
  articleShowRouteCB: (article: Article) => string
  emptyMessage?: ReactNode
}) {
  const navigate = useNavigate()
  const [showModal, setShowModal] = useState(false)

  return (
    <>
      <Paper mt={20} p={20} className="articles-list">
        <TitleWithButton mb={40} order={2} title="Articles">
          <Button className="green filled add" onClick={() => setShowModal(true)}>
            Add article
          </Button>
        </TitleWithButton>

        {!articles.length && <EmptyMessage>{emptyMessage}</EmptyMessage>}
        {articles.map((article, index) => (
          <Anchor key={index} component={Link} to={articleShowRouteCB(article)}>
            <ArticleCard
              onClick={() => navigate(routes.app.articles.show(article.id))}
              article={article}
              key={index}
            />
          </Anchor>
        ))}
      </Paper>

      <SelectArticleModal
        opened={showModal}
        onCancel={() => setShowModal(false)}
        onSelectArticle={async article => {
          setShowModal(false)
          onSelectArticle(article)
        }}
      />
    </>
  )
}

import { Button, Paper } from '@mantine/core'
import TitleWithButton from '../title/TitleWithButton'
import { ReactNode, useState } from 'react'
import EmptyMessage from '../display/empty-message'
import JourneyTrackerQuestionCard from '../card/journey-tracker-question-card'
import SortableItems from '../sortable-items'
import { AdminJourneyTrackerQuestion, AdminTrackerQuestion, TrackingPillarTypesEnum } from '../../api/schema'
import SelectTrackerQuestionModal from '../modal/composite/select-tracker-question'
import { capitalize } from 'lodash'

export default function JourneyTrackerQuestionsCrud({
  journeyId,
  journeyTrackerQuestions,
  onSelectTrackerQuestion,
  onDeleteTrackerQuestion,
  onSort,
  emptyMessage = 'No tracker questions found',
}: {
  journeyId: string
  journeyTrackerQuestions: AdminJourneyTrackerQuestion[]
  onSelectTrackerQuestion: (trackerQuestion: AdminTrackerQuestion) => void
  onDeleteTrackerQuestion: (trackerQuestion: AdminJourneyTrackerQuestion) => void
  onSort: (
    journeyTrackerQuestion: AdminJourneyTrackerQuestion,
    position: number,
    newJourneyTrackerQuestions: AdminJourneyTrackerQuestion[]
  ) => void | Promise<void>
  emptyMessage?: ReactNode
}) {
  const [showModal, setShowModal] = useState(false)
  const pillarToQuestionsMap: Partial<Record<TrackingPillarTypesEnum, AdminJourneyTrackerQuestion[]>> =
    journeyTrackerQuestions.reduce(
      (acc, question) => {
        acc[question.pillar] ||= []
        acc[question.pillar]!.push(question)
        return acc
      },
      {} as Partial<Record<TrackingPillarTypesEnum, AdminJourneyTrackerQuestion[]>>
    )

  return (
    <>
      <Paper mt={20} p={20} className="tracker-questions-list">
        <TitleWithButton order={2} title="Tracker Questions">
          <Button className="green filled" onClick={() => setShowModal(true)}>
            Add tracker question
          </Button>
        </TitleWithButton>

        {!journeyTrackerQuestions.length && <EmptyMessage>{emptyMessage}</EmptyMessage>}

        {Object.keys(pillarToQuestionsMap)
          .sort()
          .map(pillar => (
            <div key={pillar}>
              <h3>{capitalize(pillar)} pillar</h3>
              <SortableItems
                itemType="JourneyTrackerQuestion"
                items={pillarToQuestionsMap[pillar as keyof typeof pillarToQuestionsMap]!}
                onSort={async (journeyTrackerQuestion, position, newJourneyTrackerQuestions) => {
                  await onSort(journeyTrackerQuestion, position, [
                    ...journeyTrackerQuestions.filter(jtq => jtq.pillar !== pillar),
                    ...newJourneyTrackerQuestions,
                  ])
                }}
                render={trackerQuestion => (
                  <JourneyTrackerQuestionCard
                    journeyTrackerQuestion={trackerQuestion}
                    onDelete={async trackerQuestion => {
                      onDeleteTrackerQuestion(trackerQuestion)
                    }}
                  />
                )}
              />
            </div>
          ))}
      </Paper>

      <SelectTrackerQuestionModal
        associationType="journey"
        opened={showModal}
        selectedTrackerQuestionIds={journeyTrackerQuestions.map(question => question.trackerQuestion.id)}
        onCancel={() => setShowModal(false)}
        onSelectTrackerQuestion={async trackerQuestion => {
          setShowModal(false)
          onSelectTrackerQuestion(trackerQuestion)
        }}
      />
    </>
  )
}

import { useState } from 'react'
import SupportsAPI, {
  MEAL_PLANNER_MODIFIERS,
  MealPlannerModifiersEnum,
  SUPPORT_FOCUS_NUTRIENTS,
  SupportFocusNutrientsEnum,
} from '../../api/supports'
import routes from '../../config/routes'
import { useNavigate } from 'react-router-dom'
import FormGroup from './utils/form-group'
import FormButtonGroup from './utils/form-button-group'
import { Button } from '@mantine/core'
import { AdminSupport } from '../../api/schema'

export default function SupportForm({ support }: { support?: AdminSupport }) {
  const navigate = useNavigate()
  const [name, setName] = useState(support?.name || '')
  const [nameErrors, setNameErrors] = useState<string[]>([])
  const [mealPlannerModifier, setMealPlannerModifier] = useState<MealPlannerModifiersEnum | null>(
    support?.mealPlannerModifier || null
  )
  const [mealPlannerModifierErrors, setMealPlannerModifierErrors] = useState<string[]>([])
  const [supportFocusNutrient, setSupportFocusNutrient] = useState<SupportFocusNutrientsEnum | null>(
    support?.supportFocusNutrient || null
  )
  const [supportFocusNutrientErrors, setSupportFocusNutrientErrors] = useState<string[]>([])
  const [defaultSupport, setDefaultSupport] = useState<boolean>(support?.defaultSupport || false)
  const [defaultSupportErrors, setDefaultSupportErrors] = useState<string[]>([])

  const formId = support ? 'edit-support-form' : 'new-support-form'
  const hasChanges = support
    ? name !== support.name ||
      mealPlannerModifier !== support.mealPlannerModifier ||
      supportFocusNutrient !== support.supportFocusNutrient ||
      defaultSupport !== support.defaultSupport
    : true

  return (
    <form
      id={formId}
      onSubmit={async event => {
        event.preventDefault()

        try {
          if (support) {
            await SupportsAPI.update(support.id, {
              name,
              mealPlannerModifier,
              supportFocusNutrient,
              defaultSupport,
            })
            navigate(routes.app.supports.show(support.id))
          } else {
            const res = await SupportsAPI.create({
              name,
              mealPlannerModifier,
              supportFocusNutrient,
              defaultSupport,
            })
            navigate(routes.app.supports.show(res.data))
          }
        } catch (err: any) {
          if (Array.isArray(err.name)) setNameErrors(err.name)
          if (Array.isArray(err.mealPlannerModifier)) setMealPlannerModifierErrors(err.mealPlannerModifier)
          if (Array.isArray(err.supportFocusNutrient)) setSupportFocusNutrientErrors(err.supportFocusNutrient)
          if (Array.isArray(err.defaultSupport)) setDefaultSupportErrors(err.defaultSupport)
        }
      }}
    >
      <FormGroup
        required
        id="name"
        label="name"
        name="name"
        value={name}
        errors={nameErrors}
        onChange={val => {
          setName(val)
          setNameErrors([])
        }}
      />

      <FormGroup
        required
        inputType="select"
        id="meal-planner-modifier"
        label="meal planner modifier"
        name="mealPlannerModifier"
        value={mealPlannerModifier}
        errors={mealPlannerModifierErrors}
        options={MEAL_PLANNER_MODIFIERS.map(l => ({ label: l, value: l }))}
        onChange={val => {
          setMealPlannerModifier(val)
          setMealPlannerModifierErrors([])
        }}
      />

      <FormGroup
        required
        inputType="select"
        id="support-focus-nutrient"
        label="support focus nutrient"
        name="supportFocusNutrient"
        value={supportFocusNutrient}
        errors={supportFocusNutrientErrors}
        options={SUPPORT_FOCUS_NUTRIENTS.map(l => ({ label: l, value: l }))}
        onChange={val => {
          setSupportFocusNutrient(val)
          setSupportFocusNutrientErrors([])
        }}
      />

      <FormGroup
        id="default-support"
        inputType="switch"
        label="Default support"
        name="default-support"
        value={defaultSupport}
        errors={defaultSupportErrors}
        onChange={val => {
          setDefaultSupport(val)
          setDefaultSupportErrors([])
        }}
      />

      <FormButtonGroup>
        <Button className="filled green" disabled={!hasChanges} type="submit">
          {support ? 'Save' : 'Create'}
        </Button>
      </FormButtonGroup>
    </form>
  )
}

import { InteractionRequiredAuthError } from '@azure/msal-browser'
import { pca } from '../../..'

export default async function refreshAccessToken(onAuthError: () => void) {
  if (process.env.REACT_APP_PSYCHIC_ENV === 'test') {
    // Test logic
    return sessionStorage.getItem('test-token')
  }

  // MSAL logic
  const account = pca.getActiveAccount()
  if (account) {
    try {
      // acquire access token
      const token = await pca.acquireTokenSilent({
        scopes: ['User.Read'],
        account,
      })
      return token.idToken
    } catch (error) {
      if (error instanceof InteractionRequiredAuthError) {
        // if token has expired, prompt user to login screen
        return pca.acquireTokenRedirect({ scopes: ['User.Read'] })
      } else {
        onAuthError()
      }
    }
  }
}

import { useEffect, useState } from 'react'
import FormGroup from '../../components/forms/utils/form-group'
import { Anchor, Breadcrumbs, Button, Checkbox, Paper, Title } from '@mantine/core'
import PracticesAPI, { AdminPractice, Practice, PracticeOccasionTypesEnum } from '../../api/practices'
import routes from '../../config/routes'
import { useNavigate, useParams } from 'react-router-dom'
import FormButtonGroup from '../../components/forms/utils/form-button-group'
import { Link } from 'react-router-dom'
import unique from '../../helpers/unique'
import useAuthEffect from '../../hooks/useAuthEffect'
import Loader from '../../components/loader'
import allOccasionTypes from '../../config/occasionTypes'

export default function PracticesEditPage() {
  const params = useParams()
  const navigate = useNavigate()
  const [name, setName] = useState('')
  const [nameErrors, setNameErrors] = useState<string[]>([])
  const [minCommitmentDays, setMinCommitmentDays] = useState(0)
  const [minCommitmentDaysErrors, setMinCommitmentDaysErrors] = useState<string[]>([])
  const [fixedCommitmentCountPerDay, setFixedCommitmentCountPerDay] = useState(0)
  const [fixedCommitmentCountPerDayErrors, setFixedCommitmentCountPerDayErrors] = useState<string[]>([])
  const [occasionTypes, setOccasionTypes] = useState<PracticeOccasionTypesEnum[]>([])
  const [occasionTypeErrors, setOccasionTypeErrors] = useState<string[]>([])
  const [practice, setPractice] = useState<AdminPractice | null>(null)
  const [errorFetchingPractice, setErrorFetchingPractice] = useState(false)

  const hasChanges =
    practice &&
    (name !== practice.name ||
      minCommitmentDays !== practice.minCommitmentDays ||
      fixedCommitmentCountPerDay !== practice.fixedCommitmentCountPerDay ||
      occasionTypes !== practice.occasionTypes)

  useAuthEffect(() => {
    async function fetchPractice(id: string) {
      try {
        const practice = await PracticesAPI.show(id)
        setPractice(practice)
        setName(practice.name)
        setMinCommitmentDays(practice.minCommitmentDays || 0)
        setFixedCommitmentCountPerDay(practice.fixedCommitmentCountPerDay || 0)
        setOccasionTypes(practice.occasionTypes || [])
      } catch (error) {
        setErrorFetchingPractice(true)
      }
    }

    // eslint-disable-next-line
    fetchPractice(params.id!)
  })

  if (errorFetchingPractice) return <div>Failed to locate practice</div>
  if (!practice) return <Loader />

  return (
    <div className="page">
      <Breadcrumbs>
        <Anchor component={Link} to={routes.app.practices.index}>
          practices
        </Anchor>
        <Anchor component={Link} to={routes.app.practices.show(practice.id)}>
          {practice.name}
        </Anchor>
        <Anchor component={Link} to={routes.app.practices.edit(practice.id)}>
          edit
        </Anchor>
      </Breadcrumbs>

      <Title>Edit {practice.name}</Title>
      <form
        id="edit-practice-form"
        onSubmit={async event => {
          event.preventDefault()
          try {
            await PracticesAPI.update(practice.id, {
              name,
              minCommitmentDays,
              fixedCommitmentCountPerDay,
              occasionTypes,
            })
            navigate(routes.app.practices.show(practice.id))
          } catch (err: any) {
            if (Array.isArray(err.name)) setNameErrors(err.name)
            if (Array.isArray(err.minCommitmentDays)) setMinCommitmentDaysErrors(err.minCommitmentDays)
            if (Array.isArray(err.occasionTypes)) setOccasionTypeErrors(err.occasionTypes)
            if (Array.isArray(err.fixedCommitmentCountPerDay))
              setFixedCommitmentCountPerDayErrors(err.fixedCommitmentCountPerDay)
          }
        }}
      >
        <FormGroup
          required
          id="name"
          label="name"
          name="name"
          value={name}
          errors={nameErrors}
          onChange={val => {
            setName(val)
            setNameErrors([])
          }}
        />

        <FormGroup
          required
          inputType="number"
          id="minCommitmentDays"
          label="minCommitmentDays"
          name="minCommitmentDays"
          value={minCommitmentDays}
          errors={minCommitmentDaysErrors}
          onChange={val => {
            setMinCommitmentDays(val)
            setMinCommitmentDaysErrors([])
          }}
        />

        <FormGroup
          required
          inputType="number"
          id="fixedCommitmentCountPerDay"
          label="fixedCommitmentCountPerDay"
          name="fixedCommitmentCountPerDay"
          value={fixedCommitmentCountPerDay}
          errors={fixedCommitmentCountPerDayErrors}
          onChange={val => {
            setFixedCommitmentCountPerDay(val)
            setFixedCommitmentCountPerDayErrors([])
          }}
        />

        <Title mt="md" size={20}>
          Occasion types
        </Title>
        <Paper p="md">
          {allOccasionTypes.map(occasionType => (
            <Checkbox
              key={occasionType}
              name={occasionType}
              label={occasionType}
              checked={occasionTypes.includes(occasionType as any)}
              onChange={event => {
                const checked = event.target.checked
                if (checked) setOccasionTypes([...unique([...occasionTypes, occasionType])])
                else setOccasionTypes([...occasionTypes.filter(t => t !== occasionType)])
              }}
            />
          ))}
        </Paper>

        <FormButtonGroup>
          <Button disabled={!hasChanges} type="submit">
            Save
          </Button>
        </FormButtonGroup>
      </form>
    </div>
  )
}

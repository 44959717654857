import { useState } from 'react'
import { Anchor, Breadcrumbs, Title } from '@mantine/core'
import JourneysAPI from '../../api/journeys'
import routes from '../../config/routes'
import { useParams } from 'react-router-dom'
import { Link } from 'react-router-dom'
import useAuthEffect from '../../hooks/useAuthEffect'
import JourneyForm from '../../components/forms/journey-form'
import Loader from '../../components/loader'
import { AdminJourney } from '../../api/schema'

export default function JourneysEditPage() {
  const params = useParams()
  const [journey, setJourney] = useState<AdminJourney | null>(null)
  const [errorFetchingJourney, setErrorFetchingJourney] = useState(false)

  useAuthEffect(() => {
    async function fetchJourney(id: string) {
      try {
        const journey = await JourneysAPI.show(id)
        setJourney(journey)
      } catch (error) {
        setErrorFetchingJourney(true)
      }
    }

    // eslint-disable-next-line
    fetchJourney(params.id!)
  })

  if (!journey) return <Loader />

  return (
    <div className="page">
      <Breadcrumbs>
        <Anchor component={Link} to={routes.app.journeys.index}>
          journeys
        </Anchor>
        <Anchor component={Link} to={routes.app.journeys.show(journey.id)}>
          {journey.name}
        </Anchor>
        <Anchor component={Link} to={routes.app.journeys.edit(journey.id)}>
          edit
        </Anchor>
      </Breadcrumbs>

      <Title>Edit {journey.name}</Title>

      <JourneyForm journey={journey} />
    </div>
  )
}

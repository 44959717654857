/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-unsafe-argument */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
import { store } from '../../../stores'
import { clearIntercomIds, setIntercomConversationId, setIntercomId } from '../../../stores/chromeExtension'
import {
  setActiveUserSupport,
  setActivityLogEntryData,
  setAdminUserRoles,
  setCompleteUserSupports,
  setCurrentInsightsReport,
  setInsightsReports,
  setNotYetRecommendedUserSupports,
  setRecommendedUserSupports,
  setUser,
  setUserJourneys,
  setWeightLogEntryData,
  showAdminUser,
} from '../../../stores/users'
import { AdminUser } from '../../adminUsers'
import { HttpMethod, api } from '../../common'
import { UserSupportsResponse } from '../../users'
import addChromeExtensionListener from './addChromeExtensionListener'
import chromeExtensionTestOnlyBindings from './chromeExtensionTestOnlyBindings'
import proxyAxiosRequestToChromeExtension from './proxyAxiosRequestToChromeExtension'

export const inChromeExtension = new URL(window.location.href).searchParams.get('chromeExtension')
// uncomment when testing dev version of Chrome extension
// export const CHROME_EXTENSION_DOMAIN = 'chrome-extension://ohhcfmblobmceffnhjijcnmajlgejjbc'
export const CHROME_EXTENSION_DOMAIN = 'chrome-extension://caoaepggankmfkadpmmmjgggioclalma'

let currentPath: string | null = null

export default function chromeExtensionBindings() {
  if (inChromeExtension) {
    let chromeExtensionInitialized = false

    addChromeExtensionListener('wellos-admin-results', event => {
      const data = event.data as WellosAdminProxyEventData
      const payload = data.payload
      let supportData: UserSupportsResponse
      let adminData: AdminUser

      switch (event.data.reduxId) {
        case 'users.show':
          store.dispatch(setUser(payload.responseData))
          return

        case 'journeys.index':
          store.dispatch(setUserJourneys(payload.responseData))
          return

        case 'activityLogEntries.index':
          store.dispatch(setActivityLogEntryData(payload.responseData))
          return

        case 'weightLogEntries.index':
          store.dispatch(setWeightLogEntryData(payload.responseData))
          return

        case 'insightsReports.index':
          store.dispatch(setInsightsReports(payload.responseData))
          return

        case 'insightsReports.show':
          store.dispatch(setCurrentInsightsReport(payload.responseData))
          return

        case 'supports.index':
          supportData = payload.responseData as UserSupportsResponse
          store.dispatch(setActiveUserSupport(supportData.active))
          store.dispatch(setCompleteUserSupports(supportData.complete))
          store.dispatch(setRecommendedUserSupports(supportData.recommended))
          store.dispatch(setNotYetRecommendedUserSupports(supportData.notYetRecommended))
          return

        case 'users.resetJourneys':
          event.source!.postMessage({ action: 'user-reset-journeys-succeeded' }, event.origin as any)
          return

        case 'adminUsers.me':
          adminData = payload.responseData as AdminUser
          store.dispatch(setAdminUserRoles(adminData.roles))
          return

        case 'userNotes.update':
          event.source!.postMessage({ action: 'user-notes-updated' }, event.origin as any)
          return

        default:
          throw new Error(`unhandled reduxId value given: ${data.reduxId}`)
      }
    })

    addChromeExtensionListener('initialize-chrome-extension', event => {
      if (chromeExtensionInitialized) return
      chromeExtensionInitialized = true

      event.source!.postMessage({ action: 'chrome-extension-initialized' }, event.origin as any)

      api.interceptors.request.use(config => {
        proxyAxiosRequestToChromeExtension(config, event)
        return config
      })

      // eslint-disable-next-line
      store.dispatch(showAdminUser())
    })

    addChromeExtensionListener('neither-conversation-nor-contact', () => {
      const newPath = null
      if (newPath === currentPath) return
      currentPath = newPath

      store.dispatch(clearIntercomIds())
    })

    addChromeExtensionListener('set-intercom-contact-id', event => {
      const newPath = `contact:${event.data.payload.intercomContactId}`
      if (newPath === currentPath) return
      currentPath = newPath

      store.dispatch(setIntercomId(event.data.payload.intercomContactId))
    })

    addChromeExtensionListener('set-intercom-conversation-id', event => {
      const newPath = `conversation:${event.data.payload.intercomConversationId}`
      if (newPath === currentPath) return
      currentPath = newPath

      store.dispatch(setIntercomConversationId(event.data.payload.intercomConversationId))
    })

    if (process.env.REACT_APP_PSYCHIC_ENV === 'test') chromeExtensionTestOnlyBindings()
  }
}

export interface WellosAdminProxyEventData {
  payload: WellosAdminApiResultPayload
  reduxId: ReduxId
}

export interface WellosAdminApiResultPayload {
  requestData: WellosAdminApiRequestData
  responseData: any
}

export interface WellosAdminApiRequestData {
  method: HttpMethod
  url: string
  data: any
  params: any
}

export type ReduxId = 'users.show'

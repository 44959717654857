import { Button, Paper, Text } from '@mantine/core'
import { ReactNode, useRef, useState } from 'react'

export default function FileInput({
  onChange,
  accept = [],
  addFileText = 'Add file',
  changeFileText = 'Change file',
}: {
  onChange: (file: File | null) => void
  accept?: string[]
  addFileText?: string
  changeFileText?: string
}) {
  const ref = useRef<HTMLInputElement>(null)
  const [file, setFile] = useState<File | null>(null)

  return (
    <div className={`file-input ${file ? 'has-file' : ''}`}>
      <Paper py={40} px={100} mt={20} style={{ textAlign: 'center' }}>
        <input
          ref={ref}
          style={{ display: 'none' }}
          type="file"
          onChange={e => {
            const file = e.target.files?.[0] || null
            setFile(file)
            onChange(file)
          }}
          accept={accept.join(',')}
        />
        {file && (
          <>
            <Text>{file.name}</Text>
            <Text>{(file.size / 1048576).toFixed(2)} mb</Text>
            <Button mt={20} className="outlined gray" onClick={() => ref.current?.click()}>
              {changeFileText}
            </Button>
          </>
        )}
        {!file && (
          <Button className="outlined gray" onClick={() => ref.current?.click()}>
            {addFileText}
          </Button>
        )}
      </Paper>
    </div>
  )
}

import { ReactNode, useRef } from 'react'
import { useDrag, useDrop } from 'react-dnd'
import update from 'immutability-helper'

// pattern adapted from https://codesandbox.io/s/react-dnd-simple-sort-4pwrw?file=/src/example.jsx:79-119

export default function SortableItems<T extends any>({
  items,
  onSort,
  sortableField = 'position',
  idField = 'id',
  itemType,
  render,
}: {
  items: T[]
  onSort: (item: T, position: number, newItems: T[]) => void | Promise<void>
  render: (item: T) => ReactNode
  itemType: string
  sortableField?: string
  idField?: string
}) {
  return (
    <>
      {items.map((item, index) => (
        <SortableItem
          key={index}
          itemType={itemType}
          index={index}
          id={(item as any)[idField]}
          onSort={(dragIndex, hoverIndex) => {
            const draggingItem = items[dragIndex]
            const sortedItems = update(items, {
              $splice: [
                [dragIndex, 1],
                [hoverIndex, 0, draggingItem],
              ],
            }).map((item: any, index) => ({
              ...item,
              [sortableField]: index + 1,
            }))

            // eslint-disable-next-line
            onSort(draggingItem, hoverIndex + 1, sortedItems)
          }}
        >
          {render(item)}
        </SortableItem>
      ))}
    </>
  )
}

function SortableItem({
  id,
  children,
  itemType,
  index,
  onSort,
}: {
  id: any
  itemType: string
  index: number
  children: ReactNode
  onSort: (dragIndex: number, hoverIndex: number) => void
}) {
  const ref = useRef<HTMLDivElement>(null)

  const [, drop] = useDrop({
    accept: itemType,
    drop(item: any, monitor) {
      if (!ref.current) return
      const dragIndex = item.index
      const hoverIndex = index
      if (dragIndex === hoverIndex) return

      onSort(dragIndex, hoverIndex)

      // Note: we're mutating the monitor item here!
      // Generally it's better to avoid mutations,
      // but it's good here for the sake of performance
      // to avoid expensive index searches.
      item.index = hoverIndex
    },
  })

  const [{ isDragging }, drag] = useDrag({
    type: itemType,
    item: { type: itemType, id, index },
    collect: monitor => ({
      isDragging: monitor.isDragging(),
    }),
  })

  // const opacity = isDragging ? 0 : 1
  drag(drop(ref))

  return <div ref={ref}>{children}</div>
}

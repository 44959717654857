import routes from '../config/routes'
import { api } from './common'

export default class JourneyTrackerQuestionsAPI {
  public static async update(id: string, attrs: Partial<JourneyTrackerQuestionParams>) {
    return await api.patch(routes.api.journeys.journeyTrackerQuestions.update(id), attrs)
  }

  public static async destroy(id: string) {
    return await api.delete(routes.api.journeys.journeyTrackerQuestions.delete(id))
  }
}

export interface JourneyTrackerQuestionParams {
  position: number
}

import { Button, Title, Text } from '@mantine/core'
import { useState } from 'react'
import ConfirmModal from '../../../components/modal/confirm'
import SystemApi from '../../../api/system'
import { useNavigate } from 'react-router-dom'
import routes from '../../../config/routes'
import lowerEnvironmentType from '../../../helpers/lowerEnvironmentType'
import currentEnvironmentType from '../../../helpers/currentEnvironmentType'
import { AxiosError } from 'axios'

export default function SystemPortContentPage() {
  const sourceEnvName = currentEnvironmentType()
  const targetEnvName = lowerEnvironmentType()

  const [showPortContentConfirmation, setShowPortContentConfirmation] = useState(false)
  const navigate = useNavigate()
  return (
    <div className="page">
      <Title order={1} mb={20}>
        Content Port
      </Title>

      <Text style={{ fontWeight: 500 }} mb={20}>
        Porting content will send content from <strong>{sourceEnvName}</strong> to{' '}
        <strong>{targetEnvName}</strong>. This will delete all user progress through content and supports on{' '}
        <strong>{targetEnvName}</strong>.
      </Text>

      <Button className="outlined red" onClick={() => setShowPortContentConfirmation(true)}>
        Initiate Content Porting
      </Button>

      <ConfirmModal
        opened={showPortContentConfirmation}
        onCancel={() => setShowPortContentConfirmation(false)}
        onConfirm={async () => {
          try {
            await SystemApi.portContent()
            navigate(routes.app.system.portContentInitiated)
          } catch (err) {
            navigate(routes.app.system.portContentInsufficientPermission)
          }
        }}
        title="Are you sure?"
        message={`Really overwrite all content on ${targetEnvName}?`}
        confirmText="Yes"
      />
    </div>
  )
}

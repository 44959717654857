import { ImageContentType } from '../api/images'

export default function getImageContentType(file: File | null): ImageContentType | null {
  switch (file?.type) {
    case 'image/jpeg':
      return 'jpeg'
    case 'image/jpg':
      return 'jpg'
    case 'image/png':
      return 'png'
    default:
      return null
  }
}

import { Button, Paper } from '@mantine/core'
import { SupportEventVerb } from '../api/supportEventVerbs'
import SupportEventVerbCard from './card/support-event-verb-card'
import SelectSupportEventVerbModal from './modal/composite/select-support-event-verb'
import { useState } from 'react'

export default function AddOrEditSupportEventVerb({
  supportEventVerb,
  onSelectSupportEventVerb,
}: {
  supportEventVerb: SupportEventVerb | null
  onSelectSupportEventVerb?: (SupportEventVerb: SupportEventVerb) => void
}) {
  const [showModal, setShowModal] = useState(false)

  const addOrEditSupportEventVerbButton = (
    <Button
      className="outlined blue"
      style={{ marginRight: 10 }}
      fullWidth
      onClick={async () => {
        setShowModal(true)
      }}
    >
      {supportEventVerb ? 'Edit' : 'Add'} verb
    </Button>
  )

  return (
    <div style={{ marginTop: 20 }}>
      {supportEventVerb ? (
        <div style={{ textAlign: 'center' }}>
          <SupportEventVerbCard supportEventVerb={supportEventVerb}>
            {addOrEditSupportEventVerbButton}
          </SupportEventVerbCard>
        </div>
      ) : (
        <Paper pt={100} pb={100} pl={150} pr={150} style={{ textAlign: 'center' }}>
          <p>No verb set</p>
          {addOrEditSupportEventVerbButton}
        </Paper>
      )}

      <SelectSupportEventVerbModal
        opened={showModal}
        onCancel={() => setShowModal(false)}
        onSelectSupportEventVerb={async supportEventVerb => {
          setShowModal(false)
          onSelectSupportEventVerb?.(supportEventVerb)
        }}
      />
    </div>
  )
}

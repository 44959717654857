import { Card } from '@mantine/core'
import { AdminTrackerQuestion } from '../../api/schema'
import TitleWithButton from '../title/TitleWithButton'

export default function TrackerQuestionCard({
  trackerQuestion,
  color = 'primary',
}: {
  trackerQuestion: AdminTrackerQuestion
  color?: 'primary' | 'secondary'
}) {
  const card = (
    <Card
      className={`journey-tracker-question-card ${trackerQuestion.type}`}
      mb={10}
      padding="sm"
      radius="md"
      withBorder
    >
      <div style={{ color: color === 'primary' ? 'black' : 'gray' }}>
        <TitleWithButton mb={10} title={trackerQuestion.type} order={5}>
          <div></div>
        </TitleWithButton>
      </div>
    </Card>
  )
  return card
}

import { useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { Anchor, Breadcrumbs, Button, Paper, Table } from '@mantine/core'
import { Link } from 'react-router-dom'
import routes from '../../../config/routes'
import TitleWithButton from '../../../components/title/TitleWithButton'
import ConfirmModal from '../../../components/modal/confirm'
import useAuthEffect from '../../../hooks/useAuthEffect'
import ChecklistTriggerEventTriggersAPI, {
  ChecklistTriggerEventTrigger,
} from '../../../api/supportChecklistTriggerEventTriggers'
import Loader from '../../../components/loader'

export default function ChecklistTriggerEventTriggersShowPage() {
  const params = useParams()
  const navigate = useNavigate()
  const [checklistTriggerEventTrigger, setChecklistTriggerEventTrigger] =
    useState<ChecklistTriggerEventTrigger | null>(null)
  const [errorFetchingChecklistTriggerEventTrigger, setErrorFetchingChecklistTriggerEventTrigger] =
    useState(false)
  const [showDeleteModal, setShowDeleteModal] = useState(false)

  async function fetchChecklistTriggerEventTrigger(id: string) {
    try {
      const checklistTriggerEventTrigger = await ChecklistTriggerEventTriggersAPI.show(id)
      setChecklistTriggerEventTrigger(checklistTriggerEventTrigger)
    } catch (error) {
      setErrorFetchingChecklistTriggerEventTrigger(true)
    }
  }

  useAuthEffect(() => {
    // eslint-disable-next-line
    fetchChecklistTriggerEventTrigger(params.id!)
  })

  if (errorFetchingChecklistTriggerEventTrigger)
    return <div>There was an error fetching the checklist trigger event trigger you requested.</div>
  if (!checklistTriggerEventTrigger) return <Loader />

  return (
    <div className="page">
      <Breadcrumbs>
        <Anchor component={Link} to={routes.app.checklistTriggers.index}>
          checklist triggers
        </Anchor>
        <Anchor
          component={Link}
          to={routes.app.checklistTriggers.show(checklistTriggerEventTrigger.checklistTrigger.id)}
        >
          {checklistTriggerEventTrigger.checklistTrigger.name}
        </Anchor>
        <Anchor
          component={Link}
          to={routes.app.checklistTriggers.eventTriggers.show(checklistTriggerEventTrigger.id)}
        >
          {checklistTriggerEventTrigger.eventTrigger.name}
        </Anchor>
      </Breadcrumbs>

      <TitleWithButton title={checklistTriggerEventTrigger.eventTrigger.name}>
        <Button
          className="outlined blue"
          style={{ marginRight: 10 }}
          component={Link}
          to={routes.app.checklistTriggers.eventTriggers.edit(checklistTriggerEventTrigger.id)}
        >
          edit
        </Button>
        <Button
          id={`delete-checklist-trigger-event-trigger-${checklistTriggerEventTrigger.id}`}
          className="outlined red"
          onClick={() => setShowDeleteModal(true)}
          mr={10}
        >
          detach from checklist trigger
        </Button>
      </TitleWithButton>

      <Paper p={20}>
        <Table>
          <tbody>
            {[
              <tr key={1}>
                <td>frequency:</td>
                <td style={{ textAlign: 'right' }}>
                  every{' '}
                  {checklistTriggerEventTrigger.checklistTrigger.type
                    .replace(/ChecklistTrigger/, '')
                    .toLowerCase()}
                </td>
              </tr>,
              <tr key={2}>
                <td>desired state:</td>
                <td style={{ textAlign: 'right' }}>{checklistTriggerEventTrigger.desiredState}</td>
              </tr>,
              checklistTriggerEventTrigger.desiredState === 'match' &&
              checklistTriggerEventTrigger.matchCount ? (
                <tr key={3}>
                  <td>match count:</td>
                  <td style={{ textAlign: 'right' }}>{checklistTriggerEventTrigger.matchCount}</td>
                </tr>
              ) : null,
            ]}
          </tbody>
        </Table>
      </Paper>

      <ConfirmModal
        opened={showDeleteModal}
        onCancel={() => setShowDeleteModal(false)}
        onConfirm={async () => {
          await ChecklistTriggerEventTriggersAPI.delete(checklistTriggerEventTrigger.id)
          navigate(routes.app.checklistTriggers.show(checklistTriggerEventTrigger.checklistTrigger.id))
        }}
        title="Are you sure?"
        message="Are you sure you want to detach this event trigger from this checklist trigger?"
        confirmText="Really detach?"
      />
    </div>
  )
}

import { Badge, Button, Card, Group, Image, Text } from '@mantine/core'
import { Part } from '../../api/parts'

export default function PartCard({ part, onClick }: { part: Part; onClick?: () => void }) {
  const card = (
    <Card onClick={onClick} mb={10} padding="sm" radius="md" withBorder>
      <Group mt="md" mb="xs">
        <Text style={{ fontWeight: 500, marginLeft: 'auto' }}>{part.name}</Text>
      </Group>
      <Text style={{ marginLeft: 'auto' }} size="xs" color="gray">
        Approximate weeks: {part.approximateMinutes}
      </Text>
    </Card>
  )
  return onClick ? <button onClick={onClick}>{card}</button> : card
}

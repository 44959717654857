import { DateString } from '../../../../app/config/DateStringTypes'

export default function datesToGraphIntervals(dates: DateString[]) {
  const labelInterval = Math.ceil(dates.length / 12)
  const gridInterval = Math.ceil(dates.length / 36)

  const xAxisLabels: string[] = []
  const gridXValues: string[] = []

  dates.forEach((date, index) => {
    if (index % labelInterval === 0) xAxisLabels.push(date)
    if (index % gridInterval === 0) gridXValues.push(date)
  })

  return [xAxisLabels, gridXValues]
}

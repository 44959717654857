import type { PayloadAction } from '@reduxjs/toolkit'
import { createSlice } from '@reduxjs/toolkit'
import { User } from '../api/users'

export interface AppState {
  // whether or not the chrome extension is active
  active: boolean
  user: User | null
  intercomId: string | null
  intercomConversationId: string | null
}

const initialState: AppState = {
  active: false,
  intercomId: null,
  intercomConversationId: null,
  user: null,
}

const chromeExtensionSlice = createSlice({
  name: 'app',
  initialState,
  reducers: {
    setUser(state, data: PayloadAction<User>) {
      state.user = data.payload
    },
    setIntercomId(state, data: PayloadAction<string | null>) {
      state.intercomConversationId = null
      state.intercomId = data.payload
    },
    setIntercomConversationId(state, data: PayloadAction<string | null>) {
      state.intercomId = null
      state.intercomConversationId = data.payload
    },
    setChromeExtensionActive(state, data: PayloadAction<boolean>) {
      state.active = data.payload
    },
    clearIntercomIds(state) {
      state.intercomConversationId = null
      state.intercomId = null
    },
  },
})

export const {
  setUser,
  setChromeExtensionActive,
  setIntercomId,
  setIntercomConversationId,
  clearIntercomIds,
} = chromeExtensionSlice.actions
export default chromeExtensionSlice.reducer

import { useMantineTheme, AppShell as MantineAppShell } from '@mantine/core'
import { ReactNode, useState } from 'react'
import Navbar from './navbar'

export default function AppShell(props: { children: ReactNode }) {
  const theme = useMantineTheme()
  const [opened, setOpened] = useState(false)

  return (
    <MantineAppShell
      styles={{
        main: {
          background: theme.colors.gray[0],
        },
      }}
      navbar={{ width: 230, breakpoint: 'sm', collapsed: { mobile: !opened } }}
      p={'md'}
    >
      <Navbar opened={opened} />
      <MantineAppShell.Main>
        <div id="app-shell-inner">{props.children}</div>
      </MantineAppShell.Main>
    </MantineAppShell>
  )
}

import routes from '../config/routes'
import { api } from './common'

export default class UserNotesAPI {
  public static async update(id: string, attrs: Partial<UserNotesParams>) {
    return await api.patch(routes.api.users.notes.update(id), { notes: attrs, reduxId: 'userNotes.update' })
  }
}

export interface UserNotes {
  id: string
  goals: string
  barriers: string
  medical: string
  other: string
}

export interface UserNotesParams {
  goals: string
  barriers: string
  medical: string
  other: string
}

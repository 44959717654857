export default function getVideoContentType(file: File | null) {
  switch (file?.type) {
    case 'video/mp4':
      return 'mp4'
    case 'video/m4v':
    case 'video/x-m4v':
      return 'm4v'
    default:
      return null
  }
}

import { useEffect } from 'react'
import { useAppSelector } from '../hooks'

const useAuthEffect = (callback: () => void) => {
  const authToken = useAppSelector(state => state.app.authToken)

  useEffect(() => {
    if (!authToken) return
    callback()
  }, [authToken])
}

export default useAuthEffect

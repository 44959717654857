import { Anchor, Breadcrumbs, Title } from '@mantine/core'
import routes from '../../config/routes'
import { Link } from 'react-router-dom'
import EventTriggerForm from '../../components/forms/event-trigger-form'

export default function EventTriggersNewPage() {
  return (
    <div className="page">
      <Breadcrumbs>
        <Anchor component={Link} to={routes.app.eventTriggers.index}>
          event triggers
        </Anchor>
        <Anchor component={Link} to={routes.app.eventTriggers.new}>
          new
        </Anchor>
      </Breadcrumbs>

      <Title>Create an event trigger</Title>

      <EventTriggerForm />
    </div>
  )
}

import { Badge, Button, Card, Group, Image, Text } from '@mantine/core'
import { Lesson } from '../../api/lessons'
import TitleWithButton from '../title/TitleWithButton'

export default function LessonListItem({
  lesson,
  onClick,
  onDelete,
}: {
  lesson: Lesson
  onDelete: (lesson: Lesson) => Promise<void>
  onClick?: () => void
}) {
  return (
    <Card mb={10} padding="sm" radius="md" withBorder className="lesson-list-item">
      <TitleWithButton mb={10} onTitleClick={onClick} title={lesson.name} order={5}>
        <Button
          className="delete"
          color="red"
          variant="outline"
          onClick={async () => {
            await onDelete(lesson)
            // try {
            //   await PracticesAPI.deleteOnboardingQuestion(practiceId, practiceOnboardingQuestion.id)
            //   onDelete()
            // } catch (err) {}
          }}
        >
          delete
        </Button>
      </TitleWithButton>
    </Card>
  )
}

import { Card, Group, Text } from '@mantine/core'
import { SupportEventVerb } from '../../api/supportEventVerbs'
import { ReactNode } from 'react'

export default function SupportEventVerbCard({
  supportEventVerb,
  onClick,
  children,
}: {
  supportEventVerb: SupportEventVerb
  onClick?: () => void
  children?: ReactNode
}) {
  const card = (
    <Card onClick={onClick} mb={10} padding="sm" radius="md" withBorder>
      <Group mt="md" mb="xs">
        <Text style={{ fontWeight: 500, marginLeft: 'auto' }}>{supportEventVerb.verb}</Text>
      </Group>
      {children && <Card.Section p={20}>{children}</Card.Section>}
    </Card>
  )
  return onClick ? <button onClick={onClick}>{card}</button> : card
}

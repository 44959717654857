import { Card, Group, Image as MantineImage, Text } from '@mantine/core'
import { HumanCoach } from '../../api/humanCoaches'
import { ReactNode } from 'react'

export default function HumanCoachCard({
  humanCoach,
  onClick,
  width,
  height,
  children,
}: {
  humanCoach: HumanCoach
  width?: number
  height?: number
  onClick?: () => void
  children?: ReactNode
}) {
  const card = (
    <Card
      className="human-coach-card"
      title={humanCoach.name}
      display="inline-block"
      onClick={onClick}
      mt={10}
      mb={10}
      padding="sm"
      radius="md"
      withBorder
    >
      <Card.Section>
        <MantineImage src={humanCoach.image?.url} width={width} height={height} />
      </Card.Section>
      <Card.Section pl={20} pr={20} pt={20} style={{ textAlign: 'center' }}>
        <Text style={{ fontWeight: 500 }}>{humanCoach.name}</Text>
      </Card.Section>
      <Card.Section p={20}>{children}</Card.Section>
    </Card>
  )
  return onClick ? <button onClick={onClick}>{card}</button> : card
}

import { useState } from 'react'
import FormGroup from '../../../components/forms/utils/form-group'
import { Anchor, Breadcrumbs, Button, Title } from '@mantine/core'
import routes from '../../../config/routes'
import { useNavigate, useParams } from 'react-router-dom'
import FormButtonGroup from '../../../components/forms/utils/form-button-group'
import { Link } from 'react-router-dom'
import SupportTrackerTipsAPI from '../../../api/supportTrackerTips'
import SupportsAPI from '../../../api/supports'
import useAuthEffect from '../../../hooks/useAuthEffect'
import Loader from '../../../components/loader'
import { AdminSupport } from '../../../api/schema'

export default function SupportsTrackerTipsNewPage() {
  const navigate = useNavigate()
  const params = useParams()
  const [support, setSupport] = useState<AdminSupport | null>(null)
  const [errorFetchingSupport, setErrorFetchingSupport] = useState(false)
  const [name, setName] = useState('')
  const [nameErrors, setNameErrors] = useState<string[]>([])

  async function fetchSupport(id: string) {
    try {
      const support = await SupportsAPI.show(id)
      setSupport(support)
    } catch (error) {
      setErrorFetchingSupport(true)
    }
  }

  useAuthEffect(() => {
    // eslint-disable-next-line
    fetchSupport(params.supportId!)
  })

  if (!support) return <Loader />
  if (errorFetchingSupport) return <div>There was an error fetching the support you requested.</div>

  return (
    <div className="page">
      <Breadcrumbs>
        <Anchor component={Link} to={routes.app.supports.index}>
          supports
        </Anchor>
        <Anchor component={Link} to={routes.app.supports.show(support.id)}>
          {support.name}
        </Anchor>
        <Anchor component={Link} to={routes.app.supports.trackerTips.new(support.id)}>
          new tracker tip
        </Anchor>
      </Breadcrumbs>

      <Title>Create a Tracker tip</Title>

      <form
        id="new-tracker-tip-form"
        onSubmit={async event => {
          event.preventDefault()
          try {
            const res = await SupportTrackerTipsAPI.create(support.id, { name })
            const trackerTipId = res.data
            navigate(routes.app.supports.trackerTips.show(support.id, trackerTipId))
          } catch (err: any) {
            if (Array.isArray(err.name)) setNameErrors(err.name)
          }
        }}
      >
        <FormGroup
          required
          id="name"
          label="name"
          name="name"
          value={name}
          errors={nameErrors}
          onChange={val => {
            setName(val)
            setNameErrors([])
          }}
        />

        <FormButtonGroup>
          <Button className="filled green" type="submit">
            Create
          </Button>
        </FormButtonGroup>
      </form>
    </div>
  )
}

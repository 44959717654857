import { useEffect, useState } from 'react'
import FormGroup from '../../components/forms/utils/form-group'
import { Anchor, Breadcrumbs, Button, Title } from '@mantine/core'
import LessonsAPI, { Lesson } from '../../api/lessons'
import routes from '../../config/routes'
import { useNavigate, useParams } from 'react-router-dom'
import FormButtonGroup from '../../components/forms/utils/form-button-group'
import { Link } from 'react-router-dom'
import useAuthEffect from '../../hooks/useAuthEffect'
import Loader from '../../components/loader'

export default function LessonsEditPage() {
  const params = useParams()
  const navigate = useNavigate()
  const [name, setName] = useState('')
  const [nameErrors, setNameErrors] = useState<string[]>([])
  const [lesson, setLesson] = useState<Lesson | null>(null)
  const [errorFetchingLesson, setErrorFetchingLesson] = useState(false)

  const hasChanges = lesson && name !== lesson.name

  useAuthEffect(() => {
    async function fetchLesson(id: string) {
      try {
        const lesson = await LessonsAPI.show(id)
        setLesson(lesson)
        setName(lesson.name)
      } catch (error) {
        setErrorFetchingLesson(true)
      }
    }
    // eslint-disable-next-line
    fetchLesson(params.id!)
  })

  if (!lesson) return <Loader />

  return (
    <div className="page">
      <Breadcrumbs>
        <Anchor component={Link} to={routes.app.lessons.index}>
          lessons
        </Anchor>
        <Anchor component={Link} to={routes.app.lessons.show(lesson.id)}>
          {lesson.name}
        </Anchor>
        <Anchor component={Link} to={routes.app.lessons.edit(lesson.id)}>
          edit
        </Anchor>
      </Breadcrumbs>

      <Title>Edit {lesson.name}</Title>
      <form
        id="edit-lesson-form"
        onSubmit={async event => {
          event.preventDefault()
          try {
            await LessonsAPI.update(lesson.id, {
              name,
            })
            navigate(routes.app.lessons.show(lesson.id))
          } catch (err: any) {
            if (Array.isArray(err.name)) setNameErrors(err.name)
          }
        }}
      >
        <FormGroup
          required
          id="name"
          label="name"
          name="name"
          value={name}
          errors={nameErrors}
          onChange={val => {
            setName(val)
            setNameErrors([])
          }}
        />

        <FormButtonGroup>
          <Button className="filled green" disabled={!hasChanges} type="submit">
            Save
          </Button>
        </FormButtonGroup>
      </form>
    </div>
  )
}

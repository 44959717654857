import routes from '../config/routes'
import { api } from './common'

export default class LocalizedTextsAPI {
  public static async update(id: string, attrs: Partial<LocalizedTextParams>) {
    return await api.patch(routes.api.localizedTexts.update(id), { localizedText: attrs })
  }

  public static async delete(id: string) {
    return await api.delete(routes.api.localizedTexts.delete(id))
  }
}

export interface LocalizedText {
  id: string
  title: string
  type: LocalizedTextTypesEnum
  subtitle: string
  description: string
  summaryDescription: string
  activeSummaryDescription?: string
  name?: string
  body?: string
  cta?: string
  alt?: string
  callout?: string
  transcription?: string
  locale: LocalesEnum
  localizableType: LocalizableTypesEnum
  localizableId: string
}

export interface LocalizedTextParams {
  name?: string
  title?: string
  subtitle?: string
  alt?: string
  summaryDescription?: string
  description?: string
  transcription?: string
  activeSummaryDescription?: string
  body?: string
  cta?: string
  callout?: string
  locale: LocalesEnum
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  extraArgs?: any
}

export const LOCALES = [
  'de-DE',
  'en-AU',
  'en-BZ',
  'en-CA',
  'en-cb',
  'en-GB',
  'en-IE',
  'en-IN',
  'en-JM',
  'en-MT',
  'en-MY',
  'en-NZ',
  'en-PH',
  'en-SG',
  'en-TT',
  'en-US',
  'en-ZA',
  'en-ZW',
  'es-ES',
  'fr-FR',
  'it-IT',
  'ja-JP',
  'ko-KR',
  'pt-BR',
  'zh-CN',
  'zh-TW',
]

export type LocalesEnum =
  | 'de-DE'
  | 'en-AU'
  | 'en-BZ'
  | 'en-CA'
  | 'en-cb'
  | 'en-GB'
  | 'en-IE'
  | 'en-IN'
  | 'en-JM'
  | 'en-MT'
  | 'en-MY'
  | 'en-NZ'
  | 'en-PH'
  | 'en-SG'
  | 'en-TT'
  | 'en-US'
  | 'en-ZA'
  | 'en-ZW'
  | 'es-ES'
  | 'fr-FR'
  | 'it-IT'
  | 'ja-JP'
  | 'ko-KR'
  | 'pt-BR'
  | 'zh-CN'
  | 'zh-TW'

export type LocalizableTypesEnum =
  | 'CoachTipVideo'
  | 'HumanCoach'
  | 'Image'
  | 'Journey'
  | 'Lesson'
  | 'LessonResource'
  | 'Video'
  | 'Part'
  | 'Phase'
  | 'Practice'
  | 'Support'

export type LocalizedTextTypesEnum =
  | 'CtaAndCalloutLocalizedText'
  | 'ImageLocalizedText'
  | 'PracticeLocalizedText'
  | 'ShortMessageLocalizedText'
  | 'StandardLocalizedText'
  | 'VideoLocalizedText'

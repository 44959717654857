export default function lowerEnvironmentType() {
  switch (process.env.REACT_APP_API_HOST) {
    case 'https://central.wellos.com':
      return 'stage'
    case 'https://central-stage.wellos.com':
      return 'dev'
    default:
      return 'NO_LOWER_ENV'
  }
}

import { Anchor, Button, Paper, Table, Text } from '@mantine/core'
import { useState } from 'react'
import { Link } from 'react-router-dom'
import routes from '../../config/routes'
import ChecklistTriggersAPI, { ChecklistTrigger } from '../../api/supportChecklistTriggers'
import { NavLink } from 'react-router-dom'
import useAuthEffect from '../../hooks/useAuthEffect'
import TitleWithButton from '../../components/title/TitleWithButton'
import Loader from '../../components/loader'

export default function ChecklistTriggersIndexPage() {
  const [checklistTriggers, setChecklistTriggers] = useState<ChecklistTrigger[]>([])
  const [errorFetchingChecklistTrigger, setErrorFetchingChecklistTrigger] = useState(false)
  const [doneFetching, setDoneFetching] = useState(false)

  useAuthEffect(() => {
    async function fetchChecklistTriggers() {
      try {
        const checklistTriggers = await ChecklistTriggersAPI.index()
        setChecklistTriggers(checklistTriggers)
        setDoneFetching(true)
      } catch (error) {
        setErrorFetchingChecklistTrigger(true)
      }
    }

    // eslint-disable-next-line
    fetchChecklistTriggers()
  })

  return (
    <div className="page">
      <TitleWithButton title="Checklist Triggers">
        <Button className="filled green" component={Link} to={routes.app.checklistTriggers.new}>
          New checklist trigger
        </Button>
      </TitleWithButton>

      {checklistTriggers.length > 0 && (
        <Paper mt={20} p="md">
          <Table>
            <thead>
              <tr>
                <td>Name</td>
              </tr>
            </thead>
            <tbody>
              {checklistTriggers.map((checklistTrigger, index) => (
                <tr key={index}>
                  <td>
                    <Anchor component={NavLink} to={routes.app.checklistTriggers.show(checklistTrigger.id)}>
                      {checklistTrigger.name}
                    </Anchor>
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
        </Paper>
      )}

      {!doneFetching && <Loader />}

      {doneFetching && checklistTriggers.length === 0 && (
        <Text style={{ textAlign: 'center' }}>
          <Paper m="lg" p="md">
            No checklist triggers have been created yet.{' '}
            <Anchor component={Link} to={routes.app.checklistTriggers.new}>
              Create one
            </Anchor>{' '}
            to get started.
          </Paper>
        </Text>
      )}
    </div>
  )
}

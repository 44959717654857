import { useEffect, useState } from 'react'
import FormGroup from '../../components/forms/utils/form-group'
import { Anchor, Breadcrumbs, Button, Title } from '@mantine/core'
import HumanCoachesAPI, { HumanCoach } from '../../api/humanCoaches'
import routes from '../../config/routes'
import { useNavigate, useParams } from 'react-router-dom'
import FormButtonGroup from '../../components/forms/utils/form-button-group'
import { Link } from 'react-router-dom'
import useAuthEffect from '../../hooks/useAuthEffect'
import Loader from '../../components/loader'

export default function HumanCoachesEditPage() {
  const params = useParams()
  const navigate = useNavigate()
  const [name, setName] = useState('')
  const [nameErrors, setNameErrors] = useState<string[]>([])
  const [humanCoach, setHumanCoach] = useState<HumanCoach | null>(null)
  const [errorFetchingHumanCoach, setErrorFetchingHumanCoach] = useState(false)

  const hasChanges = humanCoach && name !== humanCoach.name

  useAuthEffect(() => {
    async function fetchHumanCoach(id: string) {
      try {
        const humanCoach = await HumanCoachesAPI.show(id)
        setHumanCoach(humanCoach)
        setName(humanCoach.name)
      } catch (error) {
        setErrorFetchingHumanCoach(true)
      }
    }

    // eslint-disable-next-line
    fetchHumanCoach(params.id!)
  })

  if (!humanCoach) return <Loader />

  return (
    <div className="page">
      <Breadcrumbs>
        <Anchor component={Link} to={routes.app.humanCoaches.index}>
          human coaches
        </Anchor>
        <Anchor component={Link} to={routes.app.humanCoaches.show(humanCoach.id)}>
          {humanCoach.name}
        </Anchor>
        <Anchor component={Link} to={routes.app.humanCoaches.edit(humanCoach.id)}>
          edit
        </Anchor>
      </Breadcrumbs>

      <Title>Edit {humanCoach.name}</Title>
      <form
        id="edit-human-coach-form"
        onSubmit={async event => {
          event.preventDefault()
          try {
            await HumanCoachesAPI.update(humanCoach.id, {
              name,
            })
            navigate(routes.app.humanCoaches.show(humanCoach.id))
          } catch (err: any) {
            if (Array.isArray(err.name)) setNameErrors(err.name)
          }
        }}
      >
        <FormGroup
          required
          id="name"
          label="name"
          name="name"
          value={name}
          errors={nameErrors}
          onChange={val => {
            setName(val)
            setNameErrors([])
          }}
        />

        <FormButtonGroup>
          <Button disabled={!hasChanges} type="submit">
            Save
          </Button>
        </FormButtonGroup>
      </form>
    </div>
  )
}

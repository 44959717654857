import { useState, useEffect } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { Anchor, Breadcrumbs, Button } from '@mantine/core'
import { Link } from 'react-router-dom'
import routes from '../../../config/routes'
import TitleWithButton from '../../../components/title/TitleWithButton'
import ConfirmModal from '../../../components/modal/confirm'
import PracticesAPI, { AdminPractice } from '../../../api/practices'
import SupportsAPI from '../../../api/supports'
import Loader from '../../../components/loader'
import { AdminSupport } from '../../../api/schema'

export default function SupportPracticesShowPage() {
  const params = useParams()
  const navigate = useNavigate()
  const [support, setSupport] = useState<AdminSupport | null>(null)
  const [errorFetchingSupport, setErrorFetchingSupport] = useState(false)
  const [practice, setPractice] = useState<AdminPractice | null>(null)
  const [errorFetchingPractice, setErrorFetchingPractice] = useState(false)
  const [showDeleteModal, setShowDeleteModal] = useState(false)

  async function fetchPractice(id: string) {
    try {
      const practice = await PracticesAPI.show(id)
      setPractice(practice)
    } catch (error) {
      setErrorFetchingPractice(true)
    }
  }

  async function fetchSupport(id: string) {
    try {
      const support = await SupportsAPI.show(id)
      setSupport(support)
    } catch (error) {
      setErrorFetchingSupport(true)
    }
  }

  useEffect(() => {
    // eslint-disable-next-line
    fetchPractice(params.practiceId!)
    // eslint-disable-next-line
    fetchSupport(params.id!)
  })

  if (errorFetchingPractice) return <div>There was an error fetching the practice you requested.</div>
  if (errorFetchingSupport) return <div>There was an error fetching the support you requested.</div>
  if (!practice || !support) return <Loader />

  return (
    <div className="page">
      <Breadcrumbs>
        <Anchor component={Link} to={routes.app.supports.index}>
          supports
        </Anchor>
        <Anchor component={Link} to={routes.app.supports.show(support.id)}>
          {support.name}
        </Anchor>
        <Anchor component={Link} to={routes.app.practices.new}>
          {practice.name}
        </Anchor>
      </Breadcrumbs>

      <TitleWithButton title={practice.name}>
        <Button onClick={() => setShowDeleteModal(true)} color="red" variant="outline">
          detach from support
        </Button>
      </TitleWithButton>

      <ConfirmModal
        opened={showDeleteModal}
        onCancel={() => setShowDeleteModal(false)}
        onConfirm={async () => {
          await SupportsAPI.detachPractice(support.id, practice.id)
          navigate(routes.app.supports.show(support.id))
        }}
        title="Are you sure?"
        message="Are you sure you want to detach this practice from this support?"
        confirmText="Really detach?"
      />
    </div>
  )
}

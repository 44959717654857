import { useState, useEffect } from 'react'
import { redirect, useNavigate, useParams } from 'react-router-dom'
import {
  Anchor,
  Breadcrumbs,
  Button,
  Group,
  Modal,
  Paper,
  SimpleGrid,
  Space,
  Table,
  Text,
  Title,
} from '@mantine/core'
import { Link } from 'react-router-dom'
import routes from '../../../config/routes'
import TitleWithButton from '../../../components/title/TitleWithButton'
import ConfirmModal from '../../../components/modal/confirm'
import PartsAPI, { Part } from '../../../api/parts'
import PartsShowPage from '../show'
import PhasesAPI, { Phase } from '../../../api/phases'
import useAuthEffect from '../../../hooks/useAuthEffect'
import Loader from '../../../components/loader'

export default function PhasePartsShowPage() {
  const params = useParams()
  const navigate = useNavigate()
  const [phase, setPhase] = useState<Phase | null>(null)
  const [errorFetchingPhase, setErrorFetchingPhase] = useState(false)
  const [part, setPart] = useState<Part | null>(null)
  const [errorFetchingPart, setErrorFetchingPart] = useState(false)
  const [showDeleteModal, setShowDeleteModal] = useState(false)

  async function fetchPart(id: string) {
    try {
      const part = await PartsAPI.show(id)
      setPart(part)
    } catch (error) {
      setErrorFetchingPart(true)
    }
  }

  async function fetchPhase(id: string) {
    try {
      const phase = await PhasesAPI.show(id)
      setPhase(phase)
    } catch (error) {
      setErrorFetchingPhase(true)
    }
  }

  useAuthEffect(() => {
    // eslint-disable-next-line
    fetchPart(params.partId!)
    // eslint-disable-next-line
    fetchPhase(params.id!)
  })

  if (errorFetchingPart) return <div>There was an error fetching the part you requested.</div>
  if (errorFetchingPhase) return <div>There was an error fetching the phase you requested.</div>
  if (!part || !phase) return <Loader />

  return (
    <div className="page">
      <Breadcrumbs>
        <Anchor component={Link} to={routes.app.phases.index}>
          phases
        </Anchor>
        <Anchor component={Link} to={routes.app.phases.show(phase.id)}>
          {phase.name}
        </Anchor>
        <Anchor component={Link} to={routes.app.parts.new}>
          {part.name}
        </Anchor>
      </Breadcrumbs>

      <TitleWithButton title={part.name}>
        <Button className="red outlined" onClick={() => setShowDeleteModal(true)}>
          detach from phase
        </Button>
      </TitleWithButton>

      <Paper p={20} mt={20}>
        <Table>
          <tbody>
            {[
              <tr key={1}>
                <td>Approximate Weeks:</td>
                <td style={{ textAlign: 'right' }}>{part.approximateMinutes}</td>
              </tr>,
            ]}
          </tbody>
        </Table>
      </Paper>

      <ConfirmModal
        opened={showDeleteModal}
        onCancel={() => setShowDeleteModal(false)}
        onConfirm={async () => {
          await PhasesAPI.detachPart(phase.id, part.id)
          navigate(routes.app.phases.show(phase.id))
        }}
        title="Are you sure?"
        message="Are you sure you want to detach this part from this phase?"
        confirmText="Really detach?"
      />
    </div>
  )
}

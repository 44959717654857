import routes from '../config/routes'
import { api } from './common'
import { Image } from './images'
import { JourneyOnboardingQuestion, JourneyOnboardingQuestionType } from './journeyOnboardingQuestions'
import { JourneySupport } from './journeySupports'
import { LocalizedText, LocalizedTextParams } from './localizedTexts'
import { Phase } from './phases'
import { AdminJourney } from './schema'

export default class JourneysAPI {
  public static async create(attrs: JourneyParams) {
    return await api.post(routes.api.journeys.create, { journey: attrs })
  }

  public static async update(id: string, attrs: Partial<JourneyParams>) {
    return await api.patch(routes.api.journeys.update(id), { journey: attrs })
  }

  public static async delete(id: string) {
    return await api.delete(routes.api.journeys.delete(id))
  }

  public static async index() {
    const res = await api.get(routes.api.journeys.index)
    return res.data as AdminJourney[]
  }

  public static async show(id: string) {
    const res = await api.get(routes.api.journeys.show(id))
    return res.data as AdminJourney
  }

  public static async attachPhase(journeyId: string, phaseId: string) {
    return await api.post(routes.api.journeys.phases.create(journeyId), { phaseId })
  }

  public static async detachPhase(journeyId: string, phaseId: string) {
    return await api.delete(routes.api.journeys.phases.delete(journeyId, phaseId))
  }

  public static async attachSupport(journeyId: string, supportId: string) {
    return await api.post(routes.api.journeys.supports.create(journeyId), { supportId })
  }

  public static async detachSupport(journeyId: string, supportId: string) {
    return await api.delete(routes.api.journeys.supports.delete(journeyId, supportId))
  }

  public static async createOnboardingQuestion(
    journeyId: string,
    { screen }: { screen: JourneyOnboardingQuestionType }
  ) {
    return await api.post(routes.api.journeys.onboardingQuestions.create(journeyId), {
      journeyOnboardingQuestion: { screen },
    })
  }

  public static async createJourneyTrackerQuestion(
    journeyId: string,
    { trackerQuestionId }: { trackerQuestionId: string }
  ) {
    return await api.post(routes.api.journeys.journeyTrackerQuestions.create(journeyId), {
      trackerQuestionId,
    })
  }

  public static async deleteOnboardingQuestion(journeyId: string, journeyOnboardingQuestionId: string) {
    return await api.delete(
      routes.api.journeys.onboardingQuestions.delete(journeyId, journeyOnboardingQuestionId)
    )
  }

  public static async createLocalizedText(journeyId: string, params: LocalizedTextParams) {
    return await api.post(routes.api.journeys.localizedTexts.create(journeyId), { localizedText: params })
  }
}

export interface Journey {
  id: string
  name: string
  approximateWeeks: number
  journeyPhases: JourneyPhase[]
  onboardingQuestions: JourneyOnboardingQuestion[]
  localizedTexts: LocalizedText[]
  image: Image | null
  imageId: string | null
  journeySupports: JourneySupport[]
  standardName: JourneyStandardNames
  published: boolean
}

export interface JourneyPhase {
  position: number
  phase: Phase
}

export interface JourneyParams {
  name: string
  imageId?: string | null
  standardName?: JourneyStandardNames | null
  published?: boolean
}

export enum JourneyStandardNamesEnum {
  TotalWellness = 'total_wellness',
  WeightLoss = 'weight_loss',
}

export type JourneyStandardNames = JourneyStandardNamesEnum[keyof JourneyStandardNamesEnum]

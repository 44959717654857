import { configureStore, ThunkAction, Action } from '@reduxjs/toolkit'
import app from './app'
import users from './users'
import chromeExtension from './chromeExtension'

export const store = configureStore({
  reducer: {
    app,
    chromeExtension,
    users,
  },
})

export type AppDispatch = typeof store.dispatch
export type RootState = ReturnType<typeof store.getState>
export type AppThunk<ReturnType = void> = ThunkAction<ReturnType, RootState, unknown, Action<string>>

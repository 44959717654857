export default function currentEnvironmentType() {
  switch (process.env.REACT_APP_API_HOST) {
    case 'https://central.wellos.com':
      return 'prod'
    case 'https://central-stage.wellos.com':
      return 'stage'
    default:
      return 'NO_CURRENT_ENV'
  }
}

import { Button } from '@mantine/core'
import { User } from '../../../app/api/users'
import { useState } from 'react'
import FormGroup from '../../../app/components/forms/utils/form-group'
import { notifications } from '@mantine/notifications'
import { IconCheck } from '@tabler/icons-react'
import { updateUserNotes } from '../../../app/stores/users'
import { useAppDispatch } from '../../../app/hooks'
import addChromeExtensionListener from '../../../app/api/helpers/chrome-extension/addChromeExtensionListener'

export default function UserNotesTable({ user }: { user: User }) {
  const dispatch = useAppDispatch()
  const [goals, setGoals] = useState(user.notes?.goals || '')
  const [barriers, setBarriers] = useState(user.notes?.barriers || '')
  const [medical, setMedical] = useState(user.notes?.medical || '')
  const [other, setOther] = useState(user.notes?.other || '')

  addChromeExtensionListener('user-notes-updated', () => {
    notifications.show({
      id: 'notes-saved',
      title: 'Success',
      message: 'Notes were saved successfully',
      color: 'green',
      icon: <IconCheck />,
    })
  })

  return (
    <div>
      <FormGroup
        required
        inputType="textarea"
        id="notes-goals"
        label="goals"
        name="notes-goals"
        value={goals || ''}
        errors={[]}
        onChange={val => {
          setGoals(val)
        }}
      />

      <FormGroup
        required
        inputType="textarea"
        id="notes-barriers"
        label="barriers"
        name="notes-barriers"
        value={barriers || ''}
        errors={[]}
        onChange={val => {
          setBarriers(val)
        }}
      />

      <FormGroup
        required
        inputType="textarea"
        id="notes-medical"
        label="medical"
        name="notes-medical"
        value={medical || ''}
        errors={[]}
        onChange={val => {
          setMedical(val)
        }}
      />

      <FormGroup
        required
        inputType="textarea"
        id="notes-other"
        label="other"
        name="notes-other"
        value={other || ''}
        errors={[]}
        onChange={val => {
          setOther(val)
        }}
      />

      <div style={{ textAlign: 'right', paddingTop: 20 }}>
        <Button
          onClick={async e => {
            await dispatch(
              updateUserNotes({
                userId: user.id,
                opts: {
                  goals,
                  barriers,
                  medical,
                  other,
                },
              })
            )
          }}
        >
          Save notes
        </Button>
      </div>
    </div>
  )
}

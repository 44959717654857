import { useState } from 'react'
import JourneysAPI, { JourneyStandardNames, JourneyStandardNamesEnum } from '../../api/journeys'
import routes from '../../config/routes'
import { useNavigate } from 'react-router-dom'
import FormGroup from './utils/form-group'
import FormButtonGroup from './utils/form-button-group'
import { Button } from '@mantine/core'
import { AdminJourney } from '../../api/schema'

export default function JourneyForm({ journey }: { journey?: AdminJourney }) {
  const navigate = useNavigate()
  const [name, setName] = useState(journey?.name || '')
  const [published, setPublished] = useState(journey?.published || false)
  const [nameErrors, setNameErrors] = useState<string[]>([])
  const [standardName, setStandardName] = useState<JourneyStandardNames | null>(journey?.standardName || null)
  const [standardNameErrors, setStandardNameErrors] = useState<string[]>([])

  const formId = journey ? 'edit-journey-form' : 'new-journey-form'
  const hasChanges = journey
    ? name !== journey.name || standardName !== journey.standardName || published !== journey.published
    : true

  return (
    <form
      id={formId}
      onSubmit={async event => {
        event.preventDefault()

        try {
          if (journey) {
            await JourneysAPI.update(journey.id, {
              name,
              standardName,
              published,
            })
            navigate(routes.app.journeys.show(journey.id))
          } else {
            const res = await JourneysAPI.create({
              name,
              standardName,
              published,
            })
            navigate(routes.app.journeys.show(res.data))
          }
        } catch (err: any) {
          if (Array.isArray(err.name)) setNameErrors(err.name)
          if (Array.isArray(err.standardName)) setStandardNameErrors(err.standardName)
        }
      }}
    >
      <FormGroup
        required
        id="name"
        label="name"
        name="name"
        value={name}
        errors={nameErrors}
        onChange={val => {
          setName(val)
          setNameErrors([])
        }}
      />

      <FormGroup
        required
        id="standard-name"
        inputType="select"
        options={
          [
            ...Object.values(JourneyStandardNamesEnum).map(v => ({ label: v, value: v })),
            { label: 'none', value: 'none' },
          ] as any[]
        }
        label="Standard name"
        name="standardName"
        value={standardName}
        errors={standardNameErrors}
        onChange={val => {
          setStandardName(val === 'none' ? null : val)
          setStandardNameErrors([])
        }}
      />

      <FormGroup
        id="published"
        inputType="switch"
        label="Published"
        name="published"
        value={published}
        errors={[]}
        onChange={val => {
          setPublished(val)
        }}
      />

      <FormButtonGroup>
        <Button className="filled green" disabled={!hasChanges} type="submit">
          {journey ? 'Save' : 'Create'}
        </Button>
      </FormButtonGroup>
    </form>
  )
}

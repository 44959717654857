import { useState } from 'react'
import FormGroup from '../../components/forms/utils/form-group'
import { Anchor, Breadcrumbs, Button, Title } from '@mantine/core'
import PartsAPI from '../../api/parts'
import routes from '../../config/routes'
import { useNavigate } from 'react-router-dom'
import FormButtonGroup from '../../components/forms/utils/form-button-group'
import { Link } from 'react-router-dom'

export default function PartsNewPage() {
  const navigate = useNavigate()
  const [name, setName] = useState('')
  const [nameErrors, setNameErrors] = useState<string[]>([])
  const [approximateMinutes, setApproximateMinutes] = useState(1)
  const [approximateMinuteErrors, setApproximateMinuteErrors] = useState<string[]>([])

  return (
    <div className="page">
      <Breadcrumbs>
        <Anchor component={Link} to={routes.app.parts.index}>
          parts
        </Anchor>
        <Anchor component={Link} to={routes.app.parts.new}>
          new
        </Anchor>
      </Breadcrumbs>

      <Title>Create a Part</Title>

      <form
        id="new-part-form"
        onSubmit={async event => {
          event.preventDefault()
          try {
            const res = await PartsAPI.create({ name, approximateMinutes })
            const partId = res.data
            navigate(routes.app.parts.show(partId))
          } catch (err: any) {
            if (Array.isArray(err.name)) setNameErrors(err.name)
            if (Array.isArray(err.approximate_minutes)) setApproximateMinuteErrors(err.approximate_minutes)
          }
        }}
      >
        <FormGroup
          required
          id="name"
          label="name"
          name="name"
          value={name}
          errors={nameErrors}
          onChange={val => {
            setName(val)
            setNameErrors([])
          }}
        />
        <FormGroup
          required
          id="approximate-minutes"
          inputType="number"
          label="Approximate minutes"
          name="approximateMinutes"
          value={approximateMinutes}
          errors={approximateMinuteErrors}
          onChange={val => {
            setApproximateMinutes(val)
            setApproximateMinuteErrors([])
          }}
        />

        <FormButtonGroup>
          <Button className="filled green" type="submit">
            Create
          </Button>
        </FormButtonGroup>
      </form>
    </div>
  )
}

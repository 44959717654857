import { User, WeightResponse } from '../../../app/api/users'
import { DateString } from '../../../app/config/DateStringTypes'
import LineChart from '../graphs/line-chart'
import datesToGraphIntervals from './helpers/datesToGraphIntervals'

export default function WeightLogEntryTable({
  user,
  weightLogEntryData,
}: {
  user: User
  weightLogEntryData: WeightResponse | null
}) {
  if (!weightLogEntryData) return null

  const dates = Object.keys(weightLogEntryData.data) as DateString[]
  const [xAxisLabels, gridXValues] = datesToGraphIntervals(dates)

  let minYValue: number = 1000
  let maxYValue: number = 0

  const graphableWeightData = [
    {
      id: weightLogEntryData.units,
      data: dates.map(date => {
        const weight = weightLogEntryData.data[date as DateString] as number
        if (weight) {
          minYValue = Math.min(weight, minYValue)
          maxYValue = Math.max(weight, maxYValue)
        }

        return {
          x: date,
          y: weight,
        }
      }),
    },
  ]

  return (
    <LineChart
      data={graphableWeightData}
      xAxisLabels={xAxisLabels}
      gridXValues={gridXValues}
      yAxisTitle={weightLogEntryData.units}
      minYValue={minYValue - 10}
      maxYValue={maxYValue + 10}
    />
  )
}

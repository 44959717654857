import React from 'react'
import { BrowserRouter as Router } from 'react-router-dom'
import { createRoot } from 'react-dom/client'
import { Provider } from 'react-redux'
import { store } from './app/stores'
import { MantineProvider } from '@mantine/core'
import { Notifications } from '@mantine/notifications'
import { PublicClientApplication } from '@azure/msal-browser'
import { MsalProvider } from '@azure/msal-react'
import { DndProvider } from 'react-dnd'
import { HTML5Backend } from 'react-dnd-html5-backend'

import '@mantine/core/styles.css'

import App from './app'
import reportWebVitals from './reportWebVitals'

const container = document.getElementById('root')!
const root = createRoot(container)

export const pca = new PublicClientApplication({
  auth: {
    clientId: process.env.REACT_APP_PONTIFEX_WELLOS_CENTRAL_ID ?? '',
    authority: process.env.REACT_APP_PONTIFEX_AUTHORITY,
    redirectUri: '/',
    postLogoutRedirectUri: '/',
  },
  cache: {
    cacheLocation: 'sessionStorage', // This configures where your cache will be stored
  },
})

root.render(
  <React.StrictMode>
    <DndProvider backend={HTML5Backend}>
      <MsalProvider instance={pca}>
        <Provider store={store}>
          <Router>
            <MantineProvider>
              <Notifications />
              <App />
            </MantineProvider>
          </Router>
        </Provider>
      </MsalProvider>
    </DndProvider>
  </React.StrictMode>
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()

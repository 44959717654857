import { useState } from 'react'
import { Anchor, Breadcrumbs, Title } from '@mantine/core'
import SupportsAPI from '../../api/supports'
import routes from '../../config/routes'
import { useParams } from 'react-router-dom'
import { Link } from 'react-router-dom'
import useAuthEffect from '../../hooks/useAuthEffect'
import SupportForm from '../../components/forms/support-form'
import Loader from '../../components/loader'
import { AdminSupport } from '../../api/schema'

export default function SupportsEditPage() {
  const params = useParams()
  const [support, setSupport] = useState<AdminSupport | null>(null)
  const [errorFetchingSupport, setErrorFetchingSupport] = useState(false)

  useAuthEffect(() => {
    async function fetchSupport(id: string) {
      try {
        const support = await SupportsAPI.show(id)
        setSupport(support)
      } catch (error) {
        setErrorFetchingSupport(true)
      }
    }

    // eslint-disable-next-line
    fetchSupport(params.supportId!)
  })

  if (!support) return <Loader />

  return (
    <div className="page">
      <Breadcrumbs>
        <Anchor component={Link} to={routes.app.supports.index}>
          supports
        </Anchor>
        <Anchor component={Link} to={routes.app.supports.show(support.id)}>
          {support.name}
        </Anchor>
        <Anchor component={Link} to={routes.app.supports.edit(support.id)}>
          edit
        </Anchor>
      </Breadcrumbs>

      <Title>Edit {support.name}</Title>
      <SupportForm support={support} />
    </div>
  )
}

import { Anchor, Button, Paper } from '@mantine/core'
import TitleWithButton from '../title/TitleWithButton'
import { ReactNode, useState } from 'react'
import EmptyMessage from '../display/empty-message'
import SelectPhaseModal from '../modal/composite/select-phase'
import { Phase } from '../../api/phases'
import { Link, useNavigate } from 'react-router-dom'
import routes from '../../config/routes'
import PhaseCard from '../card/phase-card'

export default function PhasesCrud({
  phases,
  onSelectPhase,
  phaseShowRouteCB,
  emptyMessage = 'No phases found',
}: {
  phases: Phase[]
  onSelectPhase: (phase: Phase) => void
  phaseShowRouteCB: (phase: Phase) => string
  emptyMessage?: ReactNode
}) {
  const navigate = useNavigate()
  const [showModal, setShowModal] = useState(false)

  return (
    <>
      <Paper mt={20} p={20} className="phases-crud">
        <TitleWithButton order={2} title="Phases">
          <Button className="green filled" onClick={() => setShowModal(true)}>
            Add phase
          </Button>
        </TitleWithButton>

        {!phases.length && <EmptyMessage>{emptyMessage}</EmptyMessage>}
        {phases.map((phase, index) => (
          <Anchor key={index} component={Link} to={phaseShowRouteCB(phase)}>
            <PhaseCard phase={phase} key={index} />
          </Anchor>
        ))}
      </Paper>

      <SelectPhaseModal
        opened={showModal}
        onCancel={() => setShowModal(false)}
        onSelectPhase={async phase => {
          setShowModal(false)
          onSelectPhase(phase)
        }}
      />
    </>
  )
}

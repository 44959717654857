import { Button, Modal, Text, TextInput } from '@mantine/core'
import { ReactNode, useEffect, useState } from 'react'
import SupportEventModifiersAPI, { SupportEventModifier } from '../../../api/supportEventModifiers'
import SupportEventModifierCard from '../../card/support-event-modifier-card'
import occasionTypes from '../../../config/occasionTypes'
import { SupportEventVerb } from '../../../api/supportEventVerbs'

export default function SelectSupportEventModifierModal({
  opened,
  onCancel,
  onSelectSupportEventModifier,
  title = 'Select a modifier',
  verb,
}: {
  opened: boolean
  onSelectSupportEventModifier: (supportEventModifier: SupportEventModifier) => void
  onCancel: () => void
  title?: string
  confirmText?: string
  cancelText?: string
  verb: SupportEventVerb | null
}) {
  const [query, setQuery] = useState('')
  const [supportEventModifiers, setSupportEventModifiers] = useState<SupportEventModifier[]>([])

  useEffect(() => {
    async function fetchSupportEventModifiers() {
      setSupportEventModifiers(await SupportEventModifiersAPI.index({ query }))
    }

    if (opened) {
      // eslint-disable-next-line
      fetchSupportEventModifiers()
    }
  }, [query, opened])

  const filteredModifiers =
    verb?.verb === 'logged'
      ? supportEventModifiers
      : supportEventModifiers.filter(modifier => !occasionTypes.includes(modifier.modifier))

  return (
    <Modal fullScreen opened={opened} onClose={onCancel} title={title}>
      <TextInput
        id="search-support-event-modifiers-input"
        mb={20}
        value={query}
        onChange={e => setQuery(e.target.value)}
        label="Search"
      />
      {filteredModifiers.map((supportEventModifier, index) => (
        <button
          key={index}
          onClick={async () => {
            onSelectSupportEventModifier(supportEventModifier)
          }}
        >
          <SupportEventModifierCard supportEventModifier={supportEventModifier} />
        </button>
      ))}
    </Modal>
  )
}

import axios, { AxiosError } from 'axios'
import { api } from '../../api/common'
import { useAppDispatch } from '../../hooks'
import { setAuthToken } from '../../stores/app'

export default function dispatchNewAuthToken(
  dispatch: ReturnType<typeof useAppDispatch>,
  token: string,
  requestConfig?: AxiosError['config']
) {
  updateAxiosTokenHeaders(token, requestConfig)
  dispatch(setAuthToken(token ?? ''))
}

function updateAxiosTokenHeaders(token: string, requestConfig?: AxiosError['config']) {
  const tokenStr = `Bearer ${token}`
  api.defaults.headers.common['Authorization'] = tokenStr
  axios.defaults.headers.common['Authorization'] = tokenStr
  if (requestConfig) requestConfig.headers.Authorization = tokenStr
}

import { useState } from 'react'
import FormGroup from '../../components/forms/utils/form-group'
import { Anchor, Breadcrumbs, Button, Title } from '@mantine/core'
import HumanCoachesAPI from '../../api/humanCoaches'
import routes from '../../config/routes'
import { useNavigate } from 'react-router-dom'
import FormButtonGroup from '../../components/forms/utils/form-button-group'
import { Link } from 'react-router-dom'

export default function HumanCoachesNewPage() {
  const navigate = useNavigate()
  const [name, setName] = useState('')
  const [nameErrors, setNameErrors] = useState<string[]>([])

  return (
    <div className="page">
      <Breadcrumbs>
        <Anchor component={Link} to={routes.app.humanCoaches.index}>
          human coaches
        </Anchor>
        <Anchor component={Link} to={routes.app.humanCoaches.new}>
          new
        </Anchor>
      </Breadcrumbs>

      <Title>Create a Human Coach</Title>

      <form
        id="new-human-coach-form"
        onSubmit={async event => {
          event.preventDefault()
          try {
            const res = await HumanCoachesAPI.create({ name })
            const humanCoachId = res.data
            navigate(routes.app.humanCoaches.show(humanCoachId))
          } catch (err: any) {
            if (Array.isArray(err.name)) setNameErrors(err.name)
          }
        }}
      >
        <FormGroup
          required
          id="name"
          label="name"
          name="name"
          value={name}
          errors={nameErrors}
          onChange={val => {
            setName(val)
            setNameErrors([])
          }}
        />

        <FormButtonGroup>
          <Button className="filled green" type="submit">
            Create
          </Button>
        </FormButtonGroup>
      </form>
    </div>
  )
}

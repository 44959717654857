export default function ImagePreview({
  url,
  width = 300,
  height = 300,
}: {
  url: string
  width?: number
  height?: number
}) {
  return (
    <div className="image-preview" style={{ textAlign: 'center' }}>
      <div style={{ backgroundImage: `url(${url})`, display: 'inline-block', width, height }}></div>
    </div>
  )
}

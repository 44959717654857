import { Modal, Text, TextInput } from '@mantine/core'
import { useEffect, useState } from 'react'
import { AdminSupport } from '../../../api/schema'
import SupportsAPI, { Support } from '../../../api/supports'
import SupportCard from '../../card/support-card'

export default function SelectSupportModal({
  opened,
  onCancel,
  onSelectSupport,
  title = 'Select a support',
}: {
  opened: boolean
  onSelectSupport: (support: AdminSupport) => void
  onCancel: () => void
  title?: string
  confirmText?: string
  cancelText?: string
}) {
  const [query, setQuery] = useState('')
  const [supports, setSupports] = useState<AdminSupport[]>([])

  useEffect(() => {
    async function fetchSupports() {
      setSupports(await SupportsAPI.index({ query }))
    }
    // eslint-disable-next-line
    fetchSupports()
  }, [query])

  return (
    <Modal fullScreen opened={opened} onClose={onCancel} title={<h3>{title}</h3>}>
      <Text size={'md'} mt={20} mb={20}>
        Select a support to add to your journey
      </Text>
      <TextInput
        id="search-supports-input"
        mb={20}
        value={query}
        onChange={e => setQuery(e.target.value)}
        label="Search"
      />
      {supports.map((support, index) => (
        <button
          key={index}
          onClick={async () => {
            onSelectSupport(support)
          }}
        >
          <SupportCard support={support} />
        </button>
      ))}
    </Modal>
  )
}

import { useState } from 'react'
import FormGroup from '../../components/forms/utils/form-group'
import { Anchor, Breadcrumbs, Button, Title } from '@mantine/core'
import LessonResourcesAPI from '../../api/lessonResources'
import routes from '../../config/routes'
import { useNavigate } from 'react-router-dom'
import FormButtonGroup from '../../components/forms/utils/form-button-group'
import { Link } from 'react-router-dom'

export default function LessonResourcesNewPage() {
  const navigate = useNavigate()
  const [name, setName] = useState('')
  const [nameErrors, setNameErrors] = useState<string[]>([])
  const [approximateMinutes, setApproximateMinutes] = useState(1)
  const [approximateMinuteErrors, setApproximateMinuteErrors] = useState<string[]>([])
  const [externalId, setExternalId] = useState('')
  const [externalIdErrors, setExternalIdErrors] = useState<string[]>([])

  return (
    <div className="page">
      <Breadcrumbs>
        <Anchor component={Link} to={routes.app.lessonResources.index}>
          lesson resources
        </Anchor>
        <Anchor component={Link} to={routes.app.lessonResources.new}>
          new
        </Anchor>
      </Breadcrumbs>

      <Title>Create a Lesson Resource</Title>

      <form
        id="new-lesson-resource-form"
        onSubmit={async event => {
          event.preventDefault()
          try {
            const res = await LessonResourcesAPI.create({ name, approximateMinutes, externalId })
            const lessonResourceId = res.data
            navigate(routes.app.lessonResources.show(lessonResourceId))
          } catch (err: any) {
            if (Array.isArray(err.name)) setNameErrors(err.name)
            if (Array.isArray(err.approximateMinutes)) setApproximateMinuteErrors(err.approximateMinutes)
            if (Array.isArray(err.externalId)) setApproximateMinuteErrors(err.externalId)
          }
        }}
      >
        <FormGroup
          required
          id="name"
          label="name"
          name="name"
          value={name}
          errors={nameErrors}
          onChange={val => {
            setName(val)
            setNameErrors([])
          }}
        />
        <FormGroup
          required
          id="approximate-minutes"
          inputType="number"
          label="Approximate minutes"
          name="approximateMinutes"
          value={approximateMinutes}
          errors={approximateMinuteErrors}
          onChange={val => {
            setApproximateMinutes(val)
            setApproximateMinuteErrors([])
          }}
        />
        <FormGroup
          required
          id="external-id"
          inputType="text"
          label="External Id"
          name="externalId"
          value={externalId}
          errors={externalIdErrors}
          onChange={val => {
            setExternalId(val)
            setExternalIdErrors([])
          }}
        />

        <FormButtonGroup>
          <Button className="filled green" type="submit">
            Create
          </Button>
        </FormButtonGroup>
      </form>
    </div>
  )
}

import { Badge, Button, Card, Group, Image, Text } from '@mantine/core'
import { Lesson } from '../../api/lessons'

export default function LessonCard({ lesson, onClick }: { lesson: Lesson; onClick?: () => void }) {
  const card = (
    <Card onClick={onClick} mb={10} padding="sm" radius="md" withBorder>
      <Group mt="md" mb="xs">
        <Text style={{ fontWeight: 500, marginLeft: 'auto' }}>{lesson.name}</Text>
      </Group>
    </Card>
  )
  return onClick ? <button onClick={onClick}>{card}</button> : card
}

import { useState, useEffect } from 'react'
import PartsAPI, { Part } from '../../api/parts'
import { useNavigate, useParams } from 'react-router-dom'
import { Anchor, Breadcrumbs, Button, Paper, Table } from '@mantine/core'
import { Link } from 'react-router-dom'
import routes from '../../config/routes'
import TitleWithButton from '../../components/title/TitleWithButton'
import ConfirmModal from '../../components/modal/confirm'
import AddOrEditImage from '../../components/add-or-edit-image'
import SelectImageModal from '../../components/modal/composite/select-image'
import FormLocalizationSection from '../../components/forms/form-localization-section'
import useAuthEffect from '../../hooks/useAuthEffect'
import LessonsCrud from '../../components/crud/lessons-crud'
import Loader from '../../components/loader'

type PartsShowModalType = 'delete' | 'add_lesson' | 'add_image' | null

export default function PartsShowPage({ partId }: { partId?: string } = {}) {
  const params = useParams()
  const navigate = useNavigate()
  const [part, setPart] = useState<Part | null>(null)
  const [errorFetchingPart, setErrorFetchingPart] = useState(false)
  const [modalType, setModalType] = useState<PartsShowModalType>(null)
  const id = partId || params.id!

  async function fetchPart(id: string) {
    try {
      const part = await PartsAPI.show(id)
      setPart(part)
    } catch (error) {
      setErrorFetchingPart(true)
    }
  }

  useAuthEffect(() => {
    // eslint-disable-next-line
    fetchPart(id)
  })

  if (errorFetchingPart) return <div>There was an error fetching the part you requested.</div>
  if (!part) return <Loader />

  return (
    <div className="page">
      <Breadcrumbs>
        <Anchor component={Link} to={routes.app.parts.index}>
          parts
        </Anchor>
        <Anchor component={Link} to={routes.app.parts.new}>
          {part.name}
        </Anchor>
      </Breadcrumbs>

      <TitleWithButton title={part.name}>
        <Button
          className="outlined blue"
          style={{ marginRight: 10 }}
          component={Link}
          to={routes.app.parts.edit(part.id)}
        >
          edit
        </Button>
        <Button className="outlined red" onClick={() => setModalType('delete')}>
          delete
        </Button>
      </TitleWithButton>

      <AddOrEditImage
        image={part.image}
        onAddOrEditClick={async () => {
          setModalType('add_image')
        }}
        onRemoveClick={async () => {
          try {
            await PartsAPI.update(part.id, { imageId: null })
            await fetchPart(part.id)
          } catch (err) {
            // TODO: handle this
          }
        }}
      />

      <Paper mt={20} p={20}>
        <Table>
          <tbody>
            {[
              <tr key={1}>
                <td>Approximate Minutes:</td>
                <td style={{ textAlign: 'right' }}>{part.approximateMinutes}</td>
              </tr>,
            ]}
          </tbody>
        </Table>
      </Paper>

      <FormLocalizationSection
        apiModule={PartsAPI}
        existingLocalizedTexts={part.localizedTexts}
        localizableId={part.id}
        onChange={async _ => {
          await fetchPart(part.id)
        }}
      />

      <LessonsCrud
        lessons={part.partLessons.map(p => p.lesson)}
        onSelectLesson={async lesson => {
          try {
            await PartsAPI.attachLesson(part.id, lesson.id)
            await fetchPart(part.id)
          } catch (err) {
            // TODO: handle this
          }
        }}
        onLessonClick={lesson => {
          navigate(routes.app.parts.lessons.show(part.id, lesson.id))
        }}
        onLessonDelete={async lesson => {
          await PartsAPI.detachLesson(part.id, lesson.id)
          await fetchPart(part.id)
        }}
      />

      <ConfirmModal
        opened={modalType === 'delete'}
        onCancel={() => setModalType(null)}
        onConfirm={async () => {
          await PartsAPI.delete(part.id)
          navigate(routes.app.parts.index)
        }}
        title="Are you sure?"
        message="Are you sure you want to delete this part?"
        confirmText="Really delete"
      />

      <SelectImageModal
        opened={modalType === 'add_image'}
        onCancel={() => setModalType(null)}
        onSelectImage={async image => {
          setModalType(null)
          try {
            await PartsAPI.update(part.id, { imageId: image.id })
            await fetchPart(part.id)
          } catch (err) {
            // TODO: handle this
          }
        }}
      />
    </div>
  )
}

import { Button, Paper } from '@mantine/core'
import { Video } from '../api/videos'
import VideoCard from './card/video-card'

export default function AddOrEditVideo({
  video,
  onAddOrEditClick,
  onRemoveClick,
}: {
  video: Video | null
  onAddOrEditClick?: () => Promise<void>
  onRemoveClick?: () => Promise<void>
}) {
  const addOrEditVideoButton = (
    <Button
      style={{ marginRight: 10 }}
      variant="outline"
      fullWidth
      onClick={async () => {
        await onAddOrEditClick?.()
      }}
    >
      {video ? 'Edit' : 'Add'} video
    </Button>
  )

  return video ? (
    <div style={{ textAlign: 'center', marginTop: 20 }}>
      <VideoCard video={video} width={300} height={300}>
        <>
          {addOrEditVideoButton}
          <Button
            fullWidth
            mt={10}
            color="red"
            variant="outline"
            onClick={async () => {
              await onRemoveClick?.()
            }}
          >
            remove video
          </Button>
        </>
      </VideoCard>
    </div>
  ) : (
    <Paper pt={100} pb={100} pl={150} mt={20} pr={150} style={{ textAlign: 'center' }}>
      <p>No video set on this practice yet.</p>
      {addOrEditVideoButton}
    </Paper>
  )
}

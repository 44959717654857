import routes from '../config/routes'
import { api } from './common'
import { ShortChecklistTrigger } from './supportChecklistTriggers'
import { EventTrigger } from './supportEventTriggers'

export default class ChecklistTriggerEventTriggersAPI {
  public static async show(id: string) {
    const res = await api.get(routes.api.supports.checklistTriggers.eventTriggers.show(id))
    return res.data as ChecklistTriggerEventTrigger
  }

  public static async update(id: string, attrs: Partial<ChecklistTriggerEventTriggerParams>) {
    await api.patch(routes.api.supports.checklistTriggers.eventTriggers.update(id), {
      checklistTriggerEventTrigger: attrs,
    })
  }

  public static async delete(id: string) {
    const res = await api.delete(routes.api.supports.checklistTriggers.eventTriggers.delete(id))
    return res.data as ChecklistTriggerEventTrigger
  }
}

export interface ChecklistTriggerEventTrigger {
  id: string
  desiredState: ChecklistTriggerDesiredStatesEnum
  matchCount: number
  eventTrigger: EventTrigger
  checklistTrigger: ShortChecklistTrigger
}

export interface ChecklistTriggerEventTriggerParams {
  desiredState: ChecklistTriggerDesiredStatesEnum
  eventTriggerId: string
  matchCount?: number
}

export type ChecklistTriggerDesiredStatesEnum = 'match' | 'no-match'

import { Title, Text } from '@mantine/core'
import lowerEnvironmentType from '../../../helpers/lowerEnvironmentType'

export default function SystemPortContentInsufficientPermissionPage() {
  const targetEnvName = lowerEnvironmentType()

  return (
    <div className="page">
      <Title order={1} mb={20}>
        Content Port Failed
      </Title>

      <Text style={{ fontWeight: 500 }} mb={20}>
        ERROR: Content porting requires curriculum editor privileges.
      </Text>
    </div>
  )
}

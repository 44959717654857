import { Anchor, Box, Breadcrumbs, Button, Paper, SimpleGrid, Table, Text, Title } from '@mantine/core'
import { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import routes from '../../config/routes'
import PartsAPI, { Part } from '../../api/parts'
import { NavLink } from 'react-router-dom'
import useAuthEffect from '../../hooks/useAuthEffect'
import TitleWithButton from '../../components/title/TitleWithButton'
import Loader from '../../components/loader'

export default function PartsIndexPage() {
  const [parts, setParts] = useState<Part[]>([])
  const [errorFetchingPart, setErrorFetchingPart] = useState(false)
  const [doneFetching, setDoneFetching] = useState(false)

  useAuthEffect(() => {
    async function fetchParts() {
      try {
        const parts = await PartsAPI.index()
        setParts(parts)
        setDoneFetching(true)
      } catch (error) {
        setErrorFetchingPart(true)
      }
    }
    // eslint-disable-next-line
    fetchParts()
  })

  return (
    <div className="page">
      <TitleWithButton title="Parts">
        <Button className="green filled" component={Link} to={routes.app.parts.new}>
          New part
        </Button>
      </TitleWithButton>

      {parts.length > 0 && (
        <Paper mt={20} p="md">
          <Table>
            <thead>
              <tr>
                <td>Name</td>
                <td>Approximate Minutes</td>
              </tr>
            </thead>
            <tbody>
              {parts.map((part, index) => (
                <tr key={index}>
                  <td>
                    <Anchor component={NavLink} to={routes.app.parts.show(part.id)}>
                      {part.name}
                    </Anchor>
                  </td>
                  <td>{part.approximateMinutes}</td>
                </tr>
              ))}
            </tbody>
          </Table>
        </Paper>
      )}

      {!doneFetching && <Loader />}

      {doneFetching && parts.length === 0 && (
        <Text style={{ textAlign: "center" }}>
          <Paper m="lg" p="md">
            No parts have been created yet.{' '}
            <Anchor component={Link} to={routes.app.parts.new}>
              Create one
            </Anchor>{' '}
            to get started.
          </Paper>
        </Text>
      )}
    </div>
  )
}

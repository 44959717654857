import { Modal, Text, TextInput } from '@mantine/core'
import HumanCoachesAPI, { HumanCoach } from '../../../api/humanCoaches'
import { useEffect, useState } from 'react'
import HumanCoachCard from '../../card/human-coach-card'
import Loader from '../../loader'

export default function SelectHumanCoachModal({
  opened,
  onCancel,
  onSelectHumanCoach,
  title = 'Select an onboarding question type',
}: {
  opened: boolean
  onSelectHumanCoach: (humanCoach: HumanCoach) => void
  onCancel: () => void
  title?: string
  confirmText?: string
  cancelText?: string
}) {
  const [query, setQuery] = useState('')
  const [humanCoaches, setHumanCoaches] = useState<HumanCoach[] | null>(null)
  const [errorFetchingHumanCoaches, setErrorFetchingHumanCoaches] = useState(false)

  async function fetchHumanCoaches() {
    try {
      const humanCoaches = await HumanCoachesAPI.index({ query })
      setHumanCoaches(humanCoaches)
    } catch (error) {
      setErrorFetchingHumanCoaches(true)
    }
  }

  useEffect(() => {
    // eslint-disable-next-line
    fetchHumanCoaches()
  }, [query])

  if (errorFetchingHumanCoaches) return <div>Something happened while fetching human coaches.</div>
  if (!humanCoaches) return <Loader />

  return (
    <Modal fullScreen opened={opened} onClose={onCancel} title={<h3>{title}</h3>}>
      <Text size={'md'} mt={20} mb={20}>
        Select a human coach
      </Text>
      <TextInput
        id="search-human-coaches-input"
        mb={20}
        value={query}
        onChange={e => setQuery(e.target.value)}
        label="Search"
      />

      {humanCoaches.map((humanCoach, index) => (
        <HumanCoachCard
          width={120}
          height={120}
          humanCoach={humanCoach}
          key={index}
          onClick={() => onSelectHumanCoach(humanCoach)}
        />
      ))}
    </Modal>
  )
}

import { Anchor, Button, Paper } from '@mantine/core'
import TitleWithButton from '../title/TitleWithButton'
import { LessonResource } from '../../api/lessonResources'
import { ReactNode, useState } from 'react'
import EmptyMessage from '../display/empty-message'
import SelectLessonResourceModal from '../modal/composite/select-lesson-resource'
import { Link, useNavigate } from 'react-router-dom'
import routes from '../../config/routes'
import LessonResourceCard from '../card/lesson-resource-card'

export default function LessonResourcesCrud({
  lessonResources,
  onSelectLessonResource,
  lessonResourceShowRouteCB,
  emptyMessage = 'No lesson resources found',
}: {
  lessonResources: LessonResource[]
  lessonResourceShowRouteCB: (lessonResource: LessonResource) => string
  onSelectLessonResource: (lessonResource: LessonResource) => void
  emptyMessage?: ReactNode
}) {
  const navigate = useNavigate()
  const [showModal, setShowModal] = useState(false)

  return (
    <>
      <Paper mt={20} p={20} className="lesson-resources-list">
        <TitleWithButton mb={40} order={2} title="Lesson Resources">
          <Button className="green filled add" onClick={() => setShowModal(true)}>
            Add lesson resource
          </Button>
        </TitleWithButton>

        {!lessonResources.length && <EmptyMessage>{emptyMessage}</EmptyMessage>}
        {lessonResources.map((lessonResource, index) => (
          <Anchor key={index} component={Link} to={lessonResourceShowRouteCB(lessonResource)}>
            <LessonResourceCard lessonResource={lessonResource} key={index} />
          </Anchor>
        ))}
      </Paper>

      <SelectLessonResourceModal
        opened={showModal}
        onCancel={() => setShowModal(false)}
        onSelectLessonResource={async lessonResource => {
          setShowModal(false)
          onSelectLessonResource(lessonResource)
        }}
      />
    </>
  )
}

import { Text } from '@mantine/core'
import { ReactNode } from 'react'

export default function FormButtonGroup({
  children,
  align = 'right',
}: {
  children?: ReactNode
  align?: AlignOption
} = {}) {
  return (
    <div style={{ marginTop: 20 }}>
      <Text style={{ marginLeft: align === 'right' ? 'auto' : undefined }}>{children}</Text>
    </div>
  )
}

export type AlignOption = 'left' | 'right' | 'center'

import { Anchor, Breadcrumbs, Title } from '@mantine/core'
import routes from '../../config/routes'
import { Link } from 'react-router-dom'
import ArticleForm from '../../components/forms/article-form'

export default function ArticlesNewPage() {
  return (
    <div className="page">
      <Breadcrumbs>
        <Anchor component={Link} to={routes.app.articles.index}>
          articles
        </Anchor>
        <Anchor component={Link} to={routes.app.articles.new}>
          new
        </Anchor>
      </Breadcrumbs>

      <Title>Create a Article</Title>

      <ArticleForm />
    </div>
  )
}

import { Paper, Title } from '@mantine/core'

export default function VideoPreview({ url }: { url: string }) {
  return (
    <Paper p={20} mt={20} style={{ textAlign: 'center' }}>
      <Title mb={20}>Video preview</Title>
      <video src={url} width="80%" controls />
    </Paper>
  )
}

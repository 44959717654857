import { Anchor, Breadcrumbs, Title } from '@mantine/core'
import { Link } from 'react-router-dom'
import routes from '../../config/routes'
import VideoForm from '../../components/forms/video-form'

export default function VideosNewPage() {
  return (
    <div className="page">
      <Breadcrumbs>
        <Anchor component={Link} to={routes.app.videos.index}>
          videos
        </Anchor>
        <Anchor component={Link} to={routes.app.videos.new}>
          new
        </Anchor>
      </Breadcrumbs>

      <Title>Create a Video</Title>
      <VideoForm />
    </div>
  )
}

import { ActivityResponse, User } from '../../../app/api/users'
import { DateString } from '../../../app/config/DateStringTypes'
import BarChart from '../graphs/bar-chart'
import datesToGraphIntervals from './helpers/datesToGraphIntervals'

export default function ActivityLogEntryTable({
  user,
  activityLogEntryData,
}: {
  user: User
  activityLogEntryData: ActivityResponse | null
}) {
  if (!activityLogEntryData) return null

  const activityTypes: string[] = Object.keys(activityLogEntryData.data)
  if (!activityTypes.length) return null

  const firstActivityTypeData = activityLogEntryData.data[activityTypes[0]]
  const dates = Object.keys(firstActivityTypeData) as DateString[]
  const [xAxisLabels, _gridXValues] = datesToGraphIntervals(dates)

  const graphableActivityData: any = []

  activityTypes.map(activityType => {
    const dateMinuteMap = activityLogEntryData.data[activityType]

    dates.forEach((date, index) => {
      const minutes = dateMinuteMap[date as DateString] as number
      graphableActivityData[index] ||= { date }
      const item = graphableActivityData[index]
      item[activityType] = minutes || 0
    })
  })

  return (
    <BarChart
      indexBy="date"
      data={graphableActivityData}
      keys={activityTypes}
      xAxisLabels={xAxisLabels}
      yAxisTitle={activityLogEntryData.units}
    />
  )
}

import { Card, Group, Text } from '@mantine/core'
import { AdminPractice } from '../../api/practices'

export default function PracticeCard({
  practice,
  onClick,
}: {
  practice: AdminPractice
  onClick?: () => void
}) {
  const card = (
    <Card onClick={onClick} mb={10} padding="sm" radius="md" withBorder>
      <Group mt="md" mb="xs">
        <Text style={{ fontWeight: 500, marginLeft: 'auto' }}>{practice.name}</Text>
      </Group>
    </Card>
  )
  return onClick ? <button onClick={onClick}>{card}</button> : card
}

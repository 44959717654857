import { useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { Anchor, Breadcrumbs, Button, Table } from '@mantine/core'
import { Link } from 'react-router-dom'
import routes from '../../../config/routes'
import TitleWithButton from '../../../components/title/TitleWithButton'
import ConfirmModal from '../../../components/modal/confirm'
import PhasesAPI, { Phase } from '../../../api/phases'
import JourneysAPI from '../../../api/journeys'
import PartCard from '../../../components/card/part-card'
import useAuthEffect from '../../../hooks/useAuthEffect'
import Loader from '../../../components/loader'
import { AdminJourney } from '../../../api/schema'

export default function JourneyPhasesShowPage() {
  const params = useParams()
  const navigate = useNavigate()
  const [journey, setJourney] = useState<AdminJourney | null>(null)
  const [errorFetchingJourney, setErrorFetchingJourney] = useState(false)
  const [phase, setPhase] = useState<Phase | null>(null)
  const [errorFetchingPhase, setErrorFetchingPhase] = useState(false)
  const [showDeleteModal, setShowDeleteModal] = useState(false)

  async function fetchPhase(id: string) {
    try {
      const phase = await PhasesAPI.show(id)
      setPhase(phase)
    } catch (error) {
      setErrorFetchingPhase(true)
    }
  }

  async function fetchJourney(id: string) {
    try {
      const journey = await JourneysAPI.show(id)
      setJourney(journey)
    } catch (error) {
      setErrorFetchingJourney(true)
    }
  }

  useAuthEffect(() => {
    // eslint-disable-next-line
    fetchPhase(params.phaseId!)
    // eslint-disable-next-line
    fetchJourney(params.id!)
  })

  if (errorFetchingPhase) return <div>There was an error fetching the phase you requested.</div>
  if (errorFetchingJourney) return <div>There was an error fetching the journey you requested.</div>
  if (!phase || !journey) return <Loader />

  return (
    <div className="page">
      <Breadcrumbs>
        <Anchor component={Link} to={routes.app.journeys.index}>
          journeys
        </Anchor>
        <Anchor component={Link} to={routes.app.journeys.show(journey.id)}>
          {journey.name}
        </Anchor>
        <Anchor component={Link} to={routes.app.phases.new}>
          {phase.name}
        </Anchor>
      </Breadcrumbs>

      <TitleWithButton title={phase.name}>
        <Button className="outlined red" onClick={() => setShowDeleteModal(true)}>
          detach from journey
        </Button>
      </TitleWithButton>

      <Table>
        <tbody>
          {[
            <tr key={1}>
              <td>number of parts:</td>
              <td style={{ textAlign: 'right' }}>{phase.phaseParts.length}</td>
            </tr>,
            <tr key={2}>
              <td>foundation:</td>
              <td style={{ textAlign: 'right' }}>{phase.foundation ? 'true' : 'false'}</td>
            </tr>,
          ]}
        </tbody>
      </Table>

      {phase.phaseParts.map((phasePart, index) => (
        <Anchor component={Link} to={routes.app.phases.parts.show(phase.id, phasePart.part.id)} key={index}>
          <PartCard
            onClick={() => navigate(routes.app.parts.show(phasePart.part.id))}
            part={phasePart.part}
          />
        </Anchor>
      ))}

      <ConfirmModal
        opened={showDeleteModal}
        onCancel={() => setShowDeleteModal(false)}
        onConfirm={async () => {
          await JourneysAPI.detachPhase(journey.id, phase.id)
          navigate(routes.app.journeys.show(journey.id))
        }}
        title="Are you sure?"
        message="Are you sure you want to detach this phase from this journey?"
        confirmText="Really detach?"
      />
    </div>
  )
}

import routes from '../config/routes'
import { api } from './common'
import { Support } from './supports'

export default class JourneySupportsAPI {
  public static async update(id: string, attrs: Partial<JourneySupportParams>) {
    return await api.patch(routes.api.journeys.supports.update(id), { journeySupport: attrs })
  }
}

export interface JourneySupport {
  id: string
  position: number
  support: Support
}

export interface JourneySupportParams {
  position: number
}

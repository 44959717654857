import { Button, Modal, Text, TextInput } from '@mantine/core'
import { ReactNode, useEffect, useState } from 'react'
import LessonResourcesAPI, { LessonResource } from '../../../api/lessonResources'
import LessonResourceCard from '../../card/lesson-resource-card'

export default function SelectLessonResourceModal({
  opened,
  onCancel,
  onSelectLessonResource,
  title = 'Select a lesson resource',
}: {
  opened: boolean
  onSelectLessonResource: (lessonResource: LessonResource) => void
  onCancel: () => void
  title?: string
  confirmText?: string
  cancelText?: string
}) {
  const [query, setQuery] = useState('')
  const [lessonResources, setLessonResources] = useState<LessonResource[]>([])

  useEffect(() => {
    async function fetchLessonResources() {
      setLessonResources(await LessonResourcesAPI.index({ query }))
    }

    // eslint-disable-next-line
    fetchLessonResources()
  }, [query])

  return (
    <Modal fullScreen opened={opened} onClose={onCancel} title={<h3>{title}</h3>}>
      <Text size={'md'} mt={20} mb={20}>
        Select a lesson resource to add to your lesson
      </Text>
      <TextInput
        id="search-lessonResources-input"
        mb={20}
        value={query}
        onChange={e => setQuery(e.target.value)}
        label="Search"
      />
      {lessonResources.map((lessonResource, index) => (
        <button
          key={index}
          onClick={async () => {
            onSelectLessonResource(lessonResource)
          }}
        >
          <LessonResourceCard lessonResource={lessonResource} />
        </button>
      ))}
    </Modal>
  )
}

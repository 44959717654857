import { Anchor, Breadcrumbs, Title } from '@mantine/core'
import routes from '../../config/routes'
import { Link } from 'react-router-dom'
import JourneyForm from '../../components/forms/journey-form'

export default function JourneysNewPage() {
  return (
    <div className="page">
      <Breadcrumbs>
        <Anchor component={Link} to={routes.app.journeys.index}>
          journeys
        </Anchor>
        <Anchor component={Link} to={routes.app.journeys.new}>
          new
        </Anchor>
      </Breadcrumbs>

      <Title>Create a Journey</Title>

      <JourneyForm />
    </div>
  )
}

import { CHROME_EXTENSION_DOMAIN } from './chromeExtensionBindings'

export default function addChromeExtensionListener(
  action: ChromeExtensionAction,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  cb: (event: MessageEvent<any>) => void | Promise<void>
) {
  window.addEventListener('message', event => {
    if (process.env.REACT_APP_PSYCHIC_ENV !== 'test' && event.origin !== CHROME_EXTENSION_DOMAIN) return
    // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
    if (event.data?.action !== action) return
    // eslint-disable-next-line @typescript-eslint/no-floating-promises
    cb(event)
  })
}

export type ChromeExtensionAction =
  | 'wellos-admin'
  | 'wellos-admin-results'
  | 'initialize-chrome-extension'
  | 'chrome-extension-initialized'
  | 'neither-conversation-nor-contact'
  | 'set-intercom-contact-id'
  | 'set-intercom-conversation-id'
  | 'user-reset-journeys-succeeded'
  | 'user-notes-updated'

import { Anchor, Button, Paper } from '@mantine/core'
import TitleWithButton from '../title/TitleWithButton'
import { PhasePart } from '../../api/phases'
import { Link, useNavigate } from 'react-router-dom'
import routes from '../../config/routes'
import PartCard from '../card/part-card'
import EmptyMessage from '../display/empty-message'
import SelectPartModal from '../modal/composite/select-part'
import { useState } from 'react'
import { Part } from '../../api/parts'

export default function PartsCrud({
  parts,
  onSelectPart,
  partShowRouteCB,
  emptyMessage = 'No phases found',
}: {
  phaseId: string
  parts: Part[]
  partShowRouteCB: (part: Part) => string
  onSelectPart: (part: Part) => void
  emptyMessage?: string
}) {
  const [showModal, setShowModal] = useState(false)

  return (
    <>
      <Paper p={20} mt={20}>
        <TitleWithButton order={2} title="Parts">
          <Button className="green filled" onClick={() => setShowModal(true)}>
            Add part
          </Button>
        </TitleWithButton>

        {!parts.length && <EmptyMessage>{emptyMessage}</EmptyMessage>}
        {parts.map((part, index) => (
          <Anchor component={Link} to={partShowRouteCB(part)} key={index}>
            <PartCard part={part} />
          </Anchor>
        ))}
      </Paper>

      <SelectPartModal
        opened={showModal}
        onCancel={() => setShowModal(false)}
        onSelectPart={async part => {
          setShowModal(false)
          onSelectPart(part)
        }}
      />
    </>
  )
}

import { Button, Card } from '@mantine/core'
import { useState } from 'react'
import PracticeTrackerQuestionsAPI from '../../api/practiceTrackerQuestions'
import { AdminPracticeTrackerQuestion } from '../../api/schema'
import ConfirmModal from '../modal/confirm'
import TitleWithButton from '../title/TitleWithButton'

type PracticeTrackerQuestionCardModalType = 'confirm_delete' | null

export default function PracticeTrackerQuestionCard({
  practiceTrackerQuestion,
  onDelete,
}: {
  practiceTrackerQuestion: AdminPracticeTrackerQuestion
  onDelete: (practiceTrackerQuestion: AdminPracticeTrackerQuestion) => void
}) {
  const [modalType, setModalType] = useState<PracticeTrackerQuestionCardModalType>(null)

  const card = (
    <Card
      className={`practice-tracker-question-card ${practiceTrackerQuestion.trackerQuestion.type}`}
      mb={10}
      padding="sm"
      radius="md"
      withBorder
    >
      <TitleWithButton mb={10} title={practiceTrackerQuestion.trackerQuestion.type} order={5}>
        <Button
          className="red outlined delete"
          onClick={async () => {
            setModalType('confirm_delete')
          }}
        >
          delete
        </Button>
      </TitleWithButton>

      <ConfirmModal
        opened={modalType === 'confirm_delete'}
        title="Really delete?"
        message={`Are you sure you want to remove ${practiceTrackerQuestion.trackerQuestion.type} from this practice?`}
        onCancel={() => setModalType(null)}
        onConfirm={async () => {
          try {
            await PracticeTrackerQuestionsAPI.destroy(practiceTrackerQuestion.id)
            onDelete(practiceTrackerQuestion)
          } catch (err) {}
          setModalType(null)
        }}
      />
    </Card>
  )
  return card
}

import routes from '../config/routes'
import { LessonResource } from './lessonResources'
import { api } from './common'

export default class SupportLessonResourcesAPI {
  public static async update(id: string, attrs: Partial<SupportLessonResourceParams>) {
    return await api.patch(routes.api.supports.lessonResources.update(id), { supportLessonResource: attrs })
  }
}

export interface SupportLessonResource {
  id: string
  position: number
  lessonResource: LessonResource
  supportId: string
}

export interface SupportLessonResourceParams {
  position?: number
}

import axios from 'axios'
import routes from '../config/routes'
import chromeExtensionBindings from './helpers/chrome-extension/chromeExtensionBindings'

export const api = axios.create({
  withCredentials: true,
  baseURL: routes.baseURL,
})

chromeExtensionBindings()

export type HttpMethod = 'get' | 'post' | 'put' | 'patch' | 'delete'

import { Table } from '@mantine/core'
import { InsightsReportDetail, InsightsReportSummary, User } from '../../../app/api/users'
import EmptyMessage from '../../../app/components/display/empty-message'
import { useDispatch } from 'react-redux'
import { useAppDispatch } from '../../../app/hooks'
import { useState } from 'react'
import { showUserInsightsReport } from '../../../app/stores/users'

export default function InsightsReportsTable({
  user,
  insightsReports,
  currentInsightsReport,
}: {
  user: User
  insightsReports: InsightsReportSummary[]
  currentInsightsReport: InsightsReportDetail | null
}) {
  const dispatch = useAppDispatch()
  const [expandedInsightsReportId, setExpandedInsightsReportId] = useState<string | null>(null)

  return (
    <Table>
      <thead>
        <tr>
          <td>name</td>
          <td style={{ textAlign: 'right' }}>meals tracked</td>
          <td style={{ textAlign: 'right' }}>dates</td>
        </tr>
      </thead>
      <tbody>
        {!insightsReports.length && <EmptyMessage>No insights reports yet</EmptyMessage>}
        {insightsReports.map(insightsReport => [
          <tr className="paper padded" key={insightsReport.id}>
            <td
              onClick={async () => {
                setExpandedInsightsReportId(insightsReport.id)
                await dispatch(showUserInsightsReport({ id: user.id, insightsReportId: insightsReport.id }))
              }}
            >
              <button>{insightsReport.title}</button>
            </td>
            <td style={{ textAlign: 'right' }}>{insightsReport.numberOfMealsTracked}</td>
            <td style={{ textAlign: 'right' }}>
              {insightsReport.startOn} - {insightsReport.endOn}
            </td>
          </tr>,
        ])}
        {currentInsightsReport && (
          <>
            <tr>
              <td style={{ width: '50%' }}>{currentInsightsReport.title}</td>
              <td style={{ width: '25%', textAlign: 'right' }}></td>
              <td style={{ width: '25%', textAlign: 'right' }}></td>
            </tr>
            {currentInsightsReport.overview.map((overview, index) => (
              <tr key={`insights-report-overview-${index}`}>
                <td style={{ width: '50%' }}>Meals tracked:</td>
                <td style={{ width: '25%', textAlign: 'right' }}></td>
                <td style={{ width: '25%', textAlign: 'right' }}>{overview.value.value}</td>
              </tr>
            ))}
            {currentInsightsReport.insights.map((insight, index) => (
              <tr key={`insights-report-insights-${index}`}>
                <td style={{ width: '50%' }}>{insight.label}</td>
                <td style={{ width: '25%', textAlign: 'right' }}>
                  {insight.value.label}: {insight.value.value}
                </td>
                <td style={{ width: '25%', textAlign: 'right' }}>
                  {insight.status.label}: {insight.status.value.value}
                </td>
              </tr>
            ))}
          </>
        )}
      </tbody>
    </Table>
  )
}

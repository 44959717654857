import routes from '../config/routes'
import { api } from './common'
import { SupportEventModifier } from './supportEventModifiers'
import { SupportEventNoun } from './supportEventNouns'
import { SupportEventVerb } from './supportEventVerbs'

export default class EventTriggersAPI {
  public static async create(attrs: EventTriggerParams) {
    return await api.post(routes.api.supports.eventTriggers.create, { eventTrigger: attrs })
  }

  public static async update(id: string, attrs: Partial<EventTriggerParams>) {
    return await api.patch(routes.api.supports.eventTriggers.update(id), { eventTrigger: attrs })
  }

  public static async delete(id: string) {
    return await api.delete(routes.api.supports.eventTriggers.delete(id))
  }

  public static async index({ query }: { query?: string } = {}) {
    const res = await api.get(routes.api.supports.eventTriggers.index, { params: { query } })
    return res.data as EventTrigger[]
  }

  public static async show(id: string) {
    const res = await api.get(routes.api.supports.eventTriggers.show(id))
    return res.data as EventTrigger
  }
}

export interface EventTrigger {
  id: string
  name: string
  verb: SupportEventVerb
  verbId: string
  noun: SupportEventNoun
  nounId: string | null
  modifier: SupportEventModifier
  modifierId: string | null
}

export interface EventTriggerParams {
  name: string
  verbId: string
  modifierId?: string | null
  nounId?: string | null
}

import routes from '../config/routes'
import { api } from './common'
import { LocalizedText, LocalizedTextParams } from './localizedTexts'

export default class ImagesAPI {
  public static async create(attrs: ImageParams) {
    const res = await api.post(routes.api.images.create, { image: attrs })
    return res.data as Image
  }

  public static async update(id: string, attrs: Partial<ImageUpdateParams>) {
    return (
      await api.patch(routes.api.images.update(id), {
        image: attrs,
        fetchNewUploadUrl: attrs.fetchNewUploadUrl,
      })
    ).data as Image
  }

  public static async delete(id: string) {
    return await api.delete(routes.api.images.delete(id))
  }

  public static async index({ query }: { query?: string } = {}) {
    const res = await api.get(routes.api.images.index, { params: { query } })
    return res.data as Image[]
  }

  public static async show(id: string) {
    const res = await api.get(routes.api.images.show(id))
    return res.data as Image
  }

  public static async createLocalizedText(imageId: string, params: LocalizedTextParams) {
    return await api.post(routes.api.images.localizedTexts.create(imageId), { localizedText: params })
  }
}

export interface Image {
  id: string
  name: string
  filename: string
  url: string
  uploadUrl: string | null
  localizedTexts: LocalizedText[]
}

export interface ImageParams {
  name: string
  filename?: string | null
  contentType?: ImageContentType | null
}

export interface ImageUpdateParams extends ImageParams {
  fetchNewUploadUrl?: boolean
}

export type ImageContentType = 'png' | 'jpg' | 'jpeg'

export const ACCEPTED_IMAGE_TYPES = ['image/jpg', 'image/jpeg', 'image/png']

import { useState } from 'react'
import FormGroup from '../../components/forms/utils/form-group'
import { Anchor, Breadcrumbs, Button, Title } from '@mantine/core'
import PhasesAPI from '../../api/phases'
import routes from '../../config/routes'
import { useNavigate } from 'react-router-dom'
import FormButtonGroup from '../../components/forms/utils/form-button-group'
import { Link } from 'react-router-dom'

export default function PhasesNewPage() {
  const navigate = useNavigate()
  const [name, setName] = useState('')
  const [nameErrors, setNameErrors] = useState<string[]>([])
  const [foundation, setFoundation] = useState(false)
  const [foundationErrors, setFoundationErrors] = useState<string[]>([])

  return (
    <div className="page">
      <Breadcrumbs>
        <Anchor component={Link} to={routes.app.phases.index}>
          phases
        </Anchor>
        <Anchor component={Link} to={routes.app.phases.new}>
          new
        </Anchor>
      </Breadcrumbs>

      <Title>Create a Phase</Title>

      <form
        id="new-phase-form"
        onSubmit={async event => {
          event.preventDefault()
          try {
            const res = await PhasesAPI.create({ name, foundation })
            const phaseId = res.data
            navigate(routes.app.phases.show(phaseId))
          } catch (err: any) {
            if (Array.isArray(err.name)) setNameErrors(err.name)
            if (Array.isArray(err.foundation)) setFoundationErrors(err.foundation)
          }
        }}
      >
        <FormGroup
          required
          id="name"
          label="name"
          name="name"
          value={name}
          errors={nameErrors}
          onChange={val => {
            setName(val)
            setNameErrors([])
          }}
        />
        <FormGroup
          id="foundation"
          inputType="switch"
          label="Foundation"
          name="foundation"
          value={foundation}
          errors={foundationErrors}
          onChange={val => {
            setFoundation(val)
            setFoundationErrors([])
          }}
        />

        <FormButtonGroup>
          <Button className="green filled" type="submit">
            Create
          </Button>
        </FormButtonGroup>
      </form>
    </div>
  )
}

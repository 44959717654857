import routes from '../config/routes'
import { api } from './common'
import { Image } from './images'
import { LocalizedText, LocalizedTextParams } from './localizedTexts'
import { PracticeOnboardingQuestion, PracticeOnboardingQuestionType } from './practiceOnboardingQuestions'
import {
  AdminGoalAmountSupportLoggingPractice,
  AdminGoalWellnessTagAmountSupportLoggingPractice,
  AdminLimitAmountSupportLoggingPractice,
  AdminLimitWellnessTagAmountSupportLoggingPractice,
  AdminMealPlannerSupportPlanningPractice,
  AdminSimpleSupportLoggingPractice,
  AdminSimpleWellnessTagSupportLoggingPractice,
  AdminWellnessTagSupportLoggingPractice,
} from './schema'

export default class PracticesAPI {
  public static async create(attrs: PracticeParams) {
    return await api.post(routes.api.supports.practices.create, { practice: attrs })
  }

  public static async update(id: string, attrs: Partial<PracticeParams>) {
    return await api.patch(routes.api.supports.practices.update(id), { practice: attrs })
  }

  public static async delete(id: string) {
    return await api.delete(routes.api.supports.practices.delete(id))
  }

  public static async index({ query }: { query?: string } = {}) {
    const res = await api.get(routes.api.supports.practices.index, { params: { query } })
    return res.data as AdminPractice[]
  }

  public static async show(id: string) {
    const res = await api.get(routes.api.supports.practices.show(id))
    return res.data as AdminPractice
  }

  public static async createOnboardingQuestion(
    practiceId: string,
    { screen }: { screen: PracticeOnboardingQuestionType }
  ) {
    return await api.post(routes.api.supports.practices.onboardingQuestions.create(practiceId), {
      practiceOnboardingQuestion: { screen },
    })
  }

  public static async createPracticeTrackerQuestion(
    practiceId: string,
    { trackerQuestionId }: { trackerQuestionId: string }
  ) {
    return await api.post(routes.api.supports.practices.practiceTrackerQuestions.create(practiceId), {
      trackerQuestionId,
    })
  }

  public static async deleteOnboardingQuestion(practiceId: string, practiceOnboardingQuestionId: string) {
    return await api.delete(
      routes.api.supports.practices.onboardingQuestions.delete(practiceId, practiceOnboardingQuestionId)
    )
  }

  public static async createLocalizedText(practiceId: string, params: LocalizedTextParams) {
    return await api.post(routes.api.supports.practices.localizedTexts.create(practiceId), {
      localizedText: params,
    })
  }
}

export type AdminPractice =
  | AdminGoalAmountSupportLoggingPractice
  | AdminLimitAmountSupportLoggingPractice
  | AdminGoalWellnessTagAmountSupportLoggingPractice
  | AdminLimitWellnessTagAmountSupportLoggingPractice
  | AdminMealPlannerSupportPlanningPractice
  | AdminSimpleSupportLoggingPractice
  | AdminSimpleWellnessTagSupportLoggingPractice
  | AdminWellnessTagSupportLoggingPractice

export type AdminWellnessTagPractice =
  | AdminGoalWellnessTagAmountSupportLoggingPractice
  | AdminLimitWellnessTagAmountSupportLoggingPractice
  | AdminSimpleWellnessTagSupportLoggingPractice

export interface Practice {
  id: string
  name: string
  type: SupportPracticeTypesEnum
  imageId: string | null
  image: Image | null
  practiceWellnessTag: PracticeWellnessTagsEnum
  fixedCommitmentCountPerDay: number
  minCommitmentDays: number
  occasionTypes: PracticeOccasionTypesEnum[] | null
  onboardingQuestions: PracticeOnboardingQuestion[]
  localizedTexts: LocalizedText[]
}

export interface PracticeParams {
  name?: string
  imageId?: string | null
  practiceWellnessTag?: PracticeWellnessTagsEnum
  fixedCommitmentCountPerDay?: number
  minCommitmentDays?: number
  occasionTypes?: PracticeOccasionTypesEnum[]
}

// BEGIN: copied from schema
export type SupportPracticeTypesEnum =
  | 'GoalAmountSupportLoggingPractice'
  | 'LimitAmountSupportLoggingPractice'
  | 'LimitWellnessTagAmountSupportLoggingPractice'
  | 'MealPlannerSupportPlanningPractice'
  | 'SimpleSupportLoggingPractice'
  | 'SimpleWellnessTagSupportLoggingPractice'
  | 'WellnessTagSupportLoggingPractice'

export type PracticeWellnessTagsEnum =
  | 'excellent_calcium_source'
  | 'excellent_iron_source'
  | 'excellent_potassium_source'
  | 'excellent_vitamin_d_source'
  | 'fiber_rich'
  | 'fruit'
  | 'good_calcium_source'
  | 'good_iron_source'
  | 'good_potassium_source'
  | 'good_vitamin_d_source'
  | 'healthy_fat'
  | 'high_added_sugar'
  | 'low_carb'
  | 'low_fat'
  | 'low_saturated_fat'
  | 'low_sodium'
  | 'no_added_sugar'
  | 'protein_packed'
  | 'sweetened_beverage'
  | 'vegetable'
  | 'whole_grain'

export type PracticeConfidenceOptionsEnum = 'confident' | 'not_confident' | 'somewhat_confident'

export type PracticeOccasionTypesEnum = 'breakfast' | 'dinner' | 'lunch' | 'snack'

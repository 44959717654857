import { Title, Text } from '@mantine/core'
import lowerEnvironmentType from '../../../helpers/lowerEnvironmentType'

export default function SystemPortContentInitiatedPage() {
  const targetEnvName = lowerEnvironmentType()

  return (
    <div className="page">
      <Title order={1} mb={20}>
        Content Port
      </Title>

      <Text style={{ fontWeight: 500 }} mb={20}>
        Content porting initiated. Allow 10 minutes for the new content to appear on {targetEnvName}.
      </Text>
    </div>
  )
}

import { Button, Paper } from '@mantine/core'
import { SupportEventModifier } from '../api/supportEventModifiers'
import SupportEventModifierCard from './card/support-event-modifier-card'
import SelectSupportEventModifierModal from './modal/composite/select-support-event-modifier'
import { useState } from 'react'
import { SupportEventVerb } from '../api/supportEventVerbs'

export default function AddOrEditSupportEventModifier({
  supportEventModifier,
  onRemoveClick,
  onSelectSupportEventModifier,
  verb,
}: {
  supportEventModifier: SupportEventModifier | null
  onSelectSupportEventModifier?: (SupportEventModifier: SupportEventModifier) => void
  onRemoveClick?: () => Promise<void>
  verb: SupportEventVerb | null
}) {
  const [showModal, setShowModal] = useState(false)

  const addOrEditSupportEventModifierButton = (
    <Button
      className="outlined blue"
      style={{ marginRight: 10 }}
      fullWidth
      onClick={async () => {
        setShowModal(true)
      }}
    >
      {supportEventModifier ? 'Edit' : 'Add'} modifier
    </Button>
  )

  return (
    <div style={{ marginTop: 20 }}>
      {supportEventModifier ? (
        <div style={{ textAlign: 'center' }}>
          <SupportEventModifierCard supportEventModifier={supportEventModifier}>
            <>
              {addOrEditSupportEventModifierButton}
              <Button
                className="red outlined"
                fullWidth
                mt={10}
                onClick={async () => {
                  await onRemoveClick?.()
                }}
              >
                remove modifier
              </Button>
            </>
          </SupportEventModifierCard>
        </div>
      ) : (
        <Paper pt={100} pb={100} pl={150} pr={150} style={{ textAlign: 'center' }}>
          <p>No modifier set</p>
          {addOrEditSupportEventModifierButton}
        </Paper>
      )}

      <SelectSupportEventModifierModal
        verb={verb}
        opened={showModal}
        onCancel={() => setShowModal(false)}
        onSelectSupportEventModifier={async supportEventModifier => {
          setShowModal(false)
          onSelectSupportEventModifier?.(supportEventModifier)
        }}
      />
    </div>
  )
}

import { useState } from 'react'
import ChecklistTriggersAPI, { ChecklistTrigger } from '../../api/supportChecklistTriggers'
import { useNavigate, useParams } from 'react-router-dom'
import { Anchor, Breadcrumbs, Button, Paper, Table } from '@mantine/core'
import { Link } from 'react-router-dom'
import routes from '../../config/routes'
import TitleWithButton from '../../components/title/TitleWithButton'
import ConfirmModal from '../../components/modal/confirm'
import useAuthEffect from '../../hooks/useAuthEffect'
import ChecklistTriggerEventTriggersCrud from '../../components/crud/checklist-trigger-event-triggers-crud'
import Loader from '../../components/loader'

type ChecklistTriggersShowModalType = 'delete' | 'add_noun' | null

export default function ChecklistTriggersShowPage({
  checklistTriggerId,
}: { checklistTriggerId?: string } = {}) {
  const params = useParams()
  const navigate = useNavigate()
  const [checklistTrigger, setChecklistTrigger] = useState<ChecklistTrigger | null>(null)
  const [errorFetchingChecklistTrigger, setErrorFetchingChecklistTrigger] = useState(false)
  const [modalType, setModalType] = useState<ChecklistTriggersShowModalType>(null)
  const id = checklistTriggerId || params.id!

  async function fetchChecklistTrigger(id: string) {
    try {
      const checklistTrigger = await ChecklistTriggersAPI.show(id)
      setChecklistTrigger(checklistTrigger)
    } catch (error) {
      setErrorFetchingChecklistTrigger(true)
    }
  }

  useAuthEffect(() => {
    // eslint-disable-next-line
    fetchChecklistTrigger(id)
  })

  if (errorFetchingChecklistTrigger)
    return <div>There was an error fetching the checklistTrigger you requested.</div>
  if (!checklistTrigger) return <Loader />

  return (
    <div className="page">
      <Breadcrumbs>
        <Anchor component={Link} to={routes.app.checklistTriggers.index}>
          checklist triggers
        </Anchor>
        <Anchor component={Link} to={routes.app.checklistTriggers.new}>
          {checklistTrigger.name}
        </Anchor>
      </Breadcrumbs>

      <TitleWithButton title={checklistTrigger.name}>
        <Button
          className="outlined blue"
          style={{ marginRight: 10 }}
          component={Link}
          to={routes.app.checklistTriggers.edit(checklistTrigger.id)}
        >
          edit
        </Button>
        <Button className="outlined red" onClick={() => setModalType('delete')}>
          delete
        </Button>
      </TitleWithButton>

      <Paper p={20} mt={20}>
        <Table>
          <tbody>
            <tr>
              <td>type:</td>
              <td style={{ textAlign: 'right' }}>{checklistTrigger.type}</td>
            </tr>
          </tbody>
        </Table>
      </Paper>

      <ChecklistTriggerEventTriggersCrud
        matchEventTriggers={checklistTrigger.matchEventTriggers}
        noMatchEventTriggers={checklistTrigger.noMatchEventTriggers}
        eventTriggerShowRouteCB={checklistTriggerEventTrigger =>
          routes.app.checklistTriggers.eventTriggers.show(checklistTriggerEventTrigger.id)
        }
        onSelectEventTrigger={async (eventTrigger, desiredState) => {
          try {
            await ChecklistTriggersAPI.attachEventTrigger(checklistTrigger.id, eventTrigger.id, {
              desiredState,
            })
            await fetchChecklistTrigger(checklistTrigger.id)
          } catch (err) {
            // TODO: handle this
          }
        }}
      />

      <ConfirmModal
        opened={modalType === 'delete'}
        onCancel={() => setModalType(null)}
        onConfirm={async () => {
          await ChecklistTriggersAPI.delete(checklistTrigger.id)
          navigate(routes.app.checklistTriggers.index)
        }}
        title="Are you sure?"
        message="Are you sure you want to delete this checklist trigger?"
        confirmText="Really delete"
      />
    </div>
  )
}

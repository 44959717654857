import routes from '../config/routes'
import { api } from './common'
import { Image } from './images'
import { LocalizedText, LocalizedTextParams } from './localizedTexts'
import { Part } from './parts'

export default class PhasesAPI {
  public static async create(attrs: PhaseParams) {
    return await api.post(routes.api.phases.create, { phase: attrs })
  }

  public static async update(id: string, attrs: Partial<PhaseParams>) {
    return await api.patch(routes.api.phases.update(id), { phase: attrs })
  }

  public static async delete(id: string) {
    return await api.delete(routes.api.phases.delete(id))
  }

  public static async index({ query }: { query?: string } = {}) {
    const res = await api.get(routes.api.phases.index, { params: { query } })
    return res.data as Phase[]
  }

  public static async show(id: string) {
    const res = await api.get(routes.api.phases.show(id))
    return res.data as Phase
  }

  public static async attachPart(phaseId: string, partId: string) {
    return await api.post(routes.api.phases.parts.create(phaseId), { partId })
  }

  public static async detachPart(phaseId: string, partId: string) {
    return await api.delete(routes.api.phases.parts.delete(phaseId, partId))
  }

  public static async createLocalizedText(phaseId: string, params: LocalizedTextParams) {
    return await api.post(routes.api.phases.localizedTexts.create(phaseId), { localizedText: params })
  }
}

export interface Phase {
  id: string
  name: string
  foundation: boolean
  phaseParts: PhasePart[]
  localizedTexts: LocalizedText[]
  image: Image | null
  imageId: string | null
}

export interface PhasePart {
  position: number
  part: Part
}

export interface PhaseParams {
  name: string
  foundation: boolean
  imageId?: string | null
}

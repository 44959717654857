import { Badge, Button, Table } from '@mantine/core'
import { useEffect, useState } from 'react'
import { UserContentTreeJourney } from '../../../app/api/userJourneys'
import ConfirmModal from '../../../app/components/modal/confirm'
import { notifications } from '@mantine/notifications'
import { IconCheck, IconExclamationMark } from '@tabler/icons-react'
import UsersAPI, { User } from '../../../app/api/users'
import addChromeExtensionListener from '../../../app/api/helpers/chrome-extension/addChromeExtensionListener'
import { useAppSelector } from '../../../app/hooks'

export default function UserJourneysTable({
  user,
  userJourneys,
  onReset,
}: {
  user: User
  userJourneys: UserContentTreeJourney[]
  onReset: () => void
}) {
  const [expandedJourneyId, setExpandedJourneyId] = useState<string | null>(null)
  const [expandedPhaseId, setExpandedPhaseId] = useState<string | null>(null)
  const [modalType, setModalType] = useState<UserJourneysTableModal | null>(null)
  const adminUserRoles = useAppSelector(state => state.users.adminUserRoles)

  useEffect(() => {
    const chromeExtensionResetJourneysSucceededHandler = () => {
      notifications.show({
        id: 'user-journeys-reset-succeeded',
        title: 'Success',
        message: 'User journeys were successfully reset',
        color: 'green',
        icon: <IconCheck />,
      })
      setModalType(null)
      onReset()
    }

    addChromeExtensionListener('user-reset-journeys-succeeded', chromeExtensionResetJourneysSucceededHandler)

    return () => {
      window.removeEventListener('message', chromeExtensionResetJourneysSucceededHandler)
    }
  }, [])

  return (
    <>
      {(adminUserRoles.includes('support-staff-manager') ||
        adminUserRoles.includes('support-staff-manager-test')) && (
        <Button
          id="reset-journeys"
          onClick={() => {
            setModalType('reset_journeys_confirm')
          }}
        >
          Reset journeys
        </Button>
      )}

      <Table>
        <thead>
          <tr>
            <td>name</td>
            <td style={{ textAlign: 'right' }}>started on</td>
            <td style={{ textAlign: 'right' }}>percent complete</td>
            <td style={{ textAlign: 'right' }}>active</td>
          </tr>
        </thead>
        <tbody>
          {userJourneys.map(userJourney => [
            <tr className="paper padded" key={userJourney.id}>
              <td onClick={() => setExpandedJourneyId(userJourney.id)}>
                <button>{userJourney.name}</button>
              </td>
              <td style={{ textAlign: 'right' }}>{userJourney.startedOn}</td>
              <td style={{ textAlign: 'right' }}>{userJourney.percentComplete}</td>
              <td style={{ textAlign: 'right' }}>{userJourney.active ? 'true' : 'false'}</td>
            </tr>,
            userJourney.id === expandedJourneyId ? (
              <>
                {userJourney.children.map(userPhase => (
                  <>
                    <tr
                      className="nested"
                      key={userPhase.id}
                      onClick={() => {
                        if (expandedPhaseId === userPhase.id) setExpandedPhaseId(null)
                        else setExpandedPhaseId(userPhase.id)
                      }}
                    >
                      <td>
                        <Badge color="blue" variant="filled" mr={10}>
                          phase
                        </Badge>
                        <button>{userPhase.name}</button>
                      </td>
                      <td style={{ textAlign: 'right' }}>{userPhase.startedOn}</td>
                      <td style={{ textAlign: 'right' }}>{userPhase.percentComplete}</td>
                    </tr>
                    <>
                      {userPhase.children.map(userPart => (
                        <>
                          <tr className="nested" key={userPart.id}>
                            <td>
                              <Badge color="cyan" variant="filled" mr={10}>
                                part
                              </Badge>
                              <button>{userPart.name}</button>
                            </td>
                            <td style={{ textAlign: 'right' }}>{userPart.startedOn}</td>
                            <td style={{ textAlign: 'right' }}>{userPart.percentComplete}</td>
                          </tr>

                          <>
                            {userPart.children.map(lesson => (
                              <tr className="nested" key={lesson.id}>
                                <td>
                                  <Badge color="cyan" variant="filled" mr={10}>
                                    lesson
                                  </Badge>
                                  <button>{lesson.name}</button>
                                </td>
                                <td style={{ textAlign: 'right' }}>{lesson.startedOn}</td>
                                <td style={{ textAlign: 'right' }}>{lesson.percentComplete}</td>
                              </tr>
                            ))}
                          </>
                        </>
                      ))}
                    </>
                  </>
                ))}
              </>
            ) : null,
          ])}
        </tbody>
      </Table>

      <ConfirmModal
        opened={modalType === 'reset_journeys_confirm'}
        onCancel={() => setModalType(null)}
        onConfirm={async () => {
          try {
            await UsersAPI.resetJourneys(user.id)
          } catch (err) {}
        }}
        title="Are you sure?"
        message={`Are you sure you want to reset ${user.email}'s journeys?`}
        confirmText="Ok"
      />
    </>
  )
}

type UserJourneysTableModal = 'reset_journeys_confirm'

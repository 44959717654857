import type { PayloadAction } from '@reduxjs/toolkit'
import { createSlice } from '@reduxjs/toolkit'

export interface AppState {
  currentUser: AuthedUser | null
  currentUserNeedsPasswordReset: boolean
  authToken: string | null
  adminUserRoles: string[]
  adminWsConnectionSucceeded: boolean
}

export interface AuthedUser {
  email: string
  oid: string
}

const initialState: AppState = {
  currentUser: null,
  currentUserNeedsPasswordReset: false,
  authToken: null,
  adminUserRoles: [],
  adminWsConnectionSucceeded: false,
}

const appSlice = createSlice({
  name: 'app',
  initialState,
  reducers: {
    setAuthedUser(state, data: PayloadAction<AuthedUser>) {
      state.currentUser = data.payload
    },

    setAuthToken(state, data: PayloadAction<string>) {
      state.authToken = data.payload
    },

    setAdminWsConnectionSucceeded(state, data: PayloadAction<boolean>) {
      state.adminWsConnectionSucceeded = data.payload
    },
  },
})

export const { setAuthedUser, setAuthToken, setAdminWsConnectionSucceeded } = appSlice.actions
export default appSlice.reducer

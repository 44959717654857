import { Group, Text, Title } from '@mantine/core'
import { ReactNode } from 'react'

export default function TitleWithButton({
  title,
  order = 1,
  children,
  onTitleClick,
  mb = 20,
}: {
  title: ReactNode
  children: ReactNode | ReactNode[]
  order?: 1 | 2 | 3 | 4 | 5 | 6
  onTitleClick?: () => void
  mb?: string | number
}) {
  return (
    <Group mt="md" mb={mb}>
      <Title onClick={onTitleClick} order={order} style={{ display: 'inline-block' }}>
        {title}
      </Title>
      <Text style={{ marginLeft: 'auto' }}>{children}</Text>
    </Group>
  )
}

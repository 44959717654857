import { pca } from '../../..'
import { useAppDispatch } from '../../hooks'
import dispatchNewAuthToken from './dispatchNewAuthToken'
import refreshAccessToken from './refreshAccessToken'

export default async function fetchAuthToken(
  dispatch: ReturnType<typeof useAppDispatch>,
  { onAuthError }: { onAuthError: () => void }
) {
  if (process.env.REACT_APP_PSYCHIC_ENV === 'test') {
    fetchAndDispatchTestAuthToken(dispatch)
  } else if (atLeastOneAuthAccountExists()) {
    await fetchAndDispatchGenuineAuthToken(dispatch, onAuthError)
  } else {
    // intentionally doing nothing here, since other flows handle the error state
    // and allow msal to properly refresh the token
  }
}

function fetchAndDispatchTestAuthToken(dispatch: ReturnType<typeof useAppDispatch>) {
  // grab and set test token, if it has already created
  const token = sessionStorage.getItem('test-token')
  if (token) {
    dispatchNewAuthToken(dispatch, token)
  }
}

async function fetchAndDispatchGenuineAuthToken(
  dispatch: ReturnType<typeof useAppDispatch>,
  onAuthError: () => void
) {
  const account = activeAuthAccount()
  if (account) {
    const token = await refreshAccessToken(onAuthError)
    if (token) dispatchNewAuthToken(dispatch, token)
  } else {
    onAuthError()
  }
}

function activeAuthAccount() {
  if (!pca.getActiveAccount()) pca.setActiveAccount(pca.getAllAccounts()[0])
  return pca.getActiveAccount()
}

function atLeastOneAuthAccountExists() {
  return pca.getAllAccounts().length > 0
}

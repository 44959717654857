import routes from '../config/routes'
import { api } from './common'

export default class AdminUsersAPI {
  public static async me() {
    const res = await api.get(routes.api.me, { params: { reduxId: 'adminUsers.me' } })
    return res.data as AdminUser
  }
}

export interface AdminUser {
  roles: string[]
}

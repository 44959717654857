import { Card, Text, Image as MantineImage } from '@mantine/core'
import { Video } from '../../api/videos'
import { ReactNode } from 'react'

export default function VideoCard({
  video,
  onClick,
  width,
  height,
  children,
}: {
  video: Video
  width?: number
  height?: number
  onClick?: () => void
  children?: ReactNode
}) {
  const card = (
    <Card
      className="video-card"
      title={video.name}
      display="inline-block"
      onClick={onClick}
      mt={10}
      mb={10}
      padding="sm"
      radius="md"
      withBorder
    >
      {video.image && (
        <Card.Section>
          <MantineImage src={video.image.url} width={width} height={height} />
        </Card.Section>
      )}
      <Card.Section pl={20} pr={20} pt={20} style={{ textAlign: 'center' }}>
        <Text style={{ fontWeight: 500 }}>{video.name}</Text>
      </Card.Section>
      <Card.Section p={20}>{children}</Card.Section>
    </Card>
  )
  return onClick ? <button onClick={onClick}>{card}</button> : card
}

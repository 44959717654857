import { Anchor, Button, Paper } from '@mantine/core'
import TitleWithButton from '../title/TitleWithButton'
import { ReactNode, useState } from 'react'
import EmptyMessage from '../display/empty-message'
import { AdminPractice } from '../../api/practices'
import { Link, useNavigate } from 'react-router-dom'
import routes from '../../config/routes'
import PracticeCard from '../card/practice-card'
import SelectPracticeModal from '../modal/composite/select-practice'

export default function PracticesCrud({
  practices,
  onSelectPractice,
  practiceShowRouteCB,
  emptyMessage = 'No practices found',
}: {
  practices: AdminPractice[]
  practiceShowRouteCB: (practice: AdminPractice) => string
  onSelectPractice: (practice: AdminPractice) => void
  emptyMessage?: ReactNode
}) {
  const navigate = useNavigate()
  const [showModal, setShowModal] = useState(false)

  return (
    <>
      <Paper mt={20} p={20} className="support-practices-list">
        <TitleWithButton order={2} title="Practices">
          <Button className="green filled" onClick={() => setShowModal(true)}>
            Add practice
          </Button>
        </TitleWithButton>

        {!practices.length && <EmptyMessage>{emptyMessage}</EmptyMessage>}
        {practices.map((practice, index) => (
          <Anchor component={Link} to={practiceShowRouteCB(practice)}>
            <PracticeCard
              onClick={() => navigate(routes.app.practices.show(practice.id))}
              practice={practice}
              key={index}
            />
          </Anchor>
        ))}
      </Paper>

      <SelectPracticeModal
        opened={showModal}
        onCancel={() => setShowModal(false)}
        onSelectPractice={async practice => {
          setShowModal(false)
          onSelectPractice(practice)
        }}
      />
    </>
  )
}

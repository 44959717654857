import { useEffect, useState } from 'react'
import FormGroup from '../../components/forms/utils/form-group'
import { Anchor, Breadcrumbs, Button, Title } from '@mantine/core'
import ChecklistTriggersAPI, { ChecklistTrigger } from '../../api/supportChecklistTriggers'
import routes from '../../config/routes'
import { useNavigate, useParams } from 'react-router-dom'
import FormButtonGroup from '../../components/forms/utils/form-button-group'
import { Link } from 'react-router-dom'
import useAuthEffect from '../../hooks/useAuthEffect'
import ChecklistTriggerForm from '../../components/forms/checklist-trigger-form'
import Loader from '../../components/loader'

export default function ChecklistTriggersEditPage() {
  const params = useParams()
  const [checklistTrigger, setChecklistTrigger] = useState<ChecklistTrigger | null>(null)
  const [errorFetchingChecklistTrigger, setErrorFetchingChecklistTrigger] = useState(false)

  useAuthEffect(() => {
    async function fetchChecklistTrigger(id: string) {
      try {
        const checklistTrigger = await ChecklistTriggersAPI.show(id)
        setChecklistTrigger(checklistTrigger)
      } catch (error) {
        setErrorFetchingChecklistTrigger(true)
      }
    }

    // eslint-disable-next-line
    fetchChecklistTrigger(params.id!)
  })

  if (!checklistTrigger) return <Loader />

  return (
    <div className="page">
      <Breadcrumbs>
        <Anchor component={Link} to={routes.app.checklistTriggers.index}>
          checklist triggers
        </Anchor>
        <Anchor component={Link} to={routes.app.checklistTriggers.show(checklistTrigger.id)}>
          {checklistTrigger.name}
        </Anchor>
        <Anchor component={Link} to={routes.app.checklistTriggers.edit(checklistTrigger.id)}>
          edit
        </Anchor>
      </Breadcrumbs>

      <Title>Edit {checklistTrigger.name}</Title>

      <ChecklistTriggerForm checklistTrigger={checklistTrigger} />
    </div>
  )
}

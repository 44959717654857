import { Button, Paper } from '@mantine/core'
import TitleWithButton from '../title/TitleWithButton'
import { ReactNode, useState } from 'react'
import EmptyMessage from '../display/empty-message'
import { useNavigate } from 'react-router-dom'
import PracticeTrackerQuestionCard from '../card/practice-tracker-question-card'
import SortableItems from '../sortable-items'
import { AdminPracticeTrackerQuestion, AdminTrackerQuestion, TrackingPillarTypesEnum } from '../../api/schema'
import SelectTrackerQuestionModal from '../modal/composite/select-tracker-question'
import { capitalize } from 'lodash'

export default function PracticeTrackerQuestionsCrud({
  practiceTrackerQuestions,
  onSelectTrackerQuestion,
  onDeleteTrackerQuestion,
  onSort,
  emptyMessage = 'No tracker questions found',
}: {
  practiceTrackerQuestions: AdminPracticeTrackerQuestion[]
  onSelectTrackerQuestion: (trackerQuestion: AdminTrackerQuestion) => void
  onDeleteTrackerQuestion: (trackerQuestion: AdminPracticeTrackerQuestion) => void
  onSort: (
    practiceTrackerQuestion: AdminPracticeTrackerQuestion,
    position: number,
    newPracticeTrackerQuestions: AdminPracticeTrackerQuestion[]
  ) => void | Promise<void>
  emptyMessage?: ReactNode
}) {
  const [showModal, setShowModal] = useState(false)
  const pillarToQuestionsMap: Partial<Record<TrackingPillarTypesEnum, AdminPracticeTrackerQuestion[]>> =
    practiceTrackerQuestions.reduce(
      (acc, question) => {
        acc[question.pillar] ||= []
        acc[question.pillar]!.push(question)
        return acc
      },
      {} as Partial<Record<TrackingPillarTypesEnum, AdminPracticeTrackerQuestion[]>>
    )

  return (
    <>
      <Paper mt={20} p={20} className="tracker-questions-list">
        <TitleWithButton order={2} title="Tracker Questions">
          <Button className="green filled" onClick={() => setShowModal(true)}>
            Add tracker question
          </Button>
        </TitleWithButton>

        {!practiceTrackerQuestions.length && <EmptyMessage>{emptyMessage}</EmptyMessage>}

        {Object.keys(pillarToQuestionsMap)
          .sort()
          .map(pillar => (
            <div key={pillar}>
              <h3>{capitalize(pillar)} pillar</h3>

              <SortableItems
                itemType="PracticeTrackerQuestion"
                items={pillarToQuestionsMap[pillar as keyof typeof pillarToQuestionsMap]!}
                onSort={async (practiceTrackerQuestion, position, newPracticeTrackerQuestions) => {
                  await onSort(practiceTrackerQuestion, position, [
                    ...practiceTrackerQuestions.filter(ptq => ptq.pillar !== pillar),
                    ...newPracticeTrackerQuestions,
                  ])
                }}
                render={trackerQuestion => (
                  <PracticeTrackerQuestionCard
                    practiceTrackerQuestion={trackerQuestion}
                    onDelete={async trackerQuestion => {
                      onDeleteTrackerQuestion(trackerQuestion)
                    }}
                  />
                )}
              />
            </div>
          ))}
      </Paper>

      <SelectTrackerQuestionModal
        associationType="practice"
        opened={showModal}
        selectedTrackerQuestionIds={practiceTrackerQuestions.map(question => question.trackerQuestion.id)}
        onCancel={() => setShowModal(false)}
        onSelectTrackerQuestion={async trackerQuestion => {
          setShowModal(false)
          onSelectTrackerQuestion(trackerQuestion)
        }}
      />
    </>
  )
}

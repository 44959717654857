import { useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { Anchor, Breadcrumbs, Button, Paper, Table } from '@mantine/core'
import { Link } from 'react-router-dom'
import routes from '../../../config/routes'
import TitleWithButton from '../../../components/title/TitleWithButton'
import ConfirmModal from '../../../components/modal/confirm'
import SupportsAPI from '../../../api/supports'
import JourneysAPI from '../../../api/journeys'
import useAuthEffect from '../../../hooks/useAuthEffect'
import Loader from '../../../components/loader'
import { AdminJourney, AdminSupport } from '../../../api/schema'

export default function JourneySupportsShowPage() {
  const params = useParams()
  const navigate = useNavigate()
  const [journey, setJourney] = useState<AdminJourney | null>(null)
  const [errorFetchingJourney, setErrorFetchingJourney] = useState(false)
  const [support, setSupport] = useState<AdminSupport | null>(null)
  const [errorFetchingSupport, setErrorFetchingSupport] = useState(false)
  const [showDeleteModal, setShowDeleteModal] = useState(false)

  async function fetchSupport(id: string) {
    try {
      const support = await SupportsAPI.show(id)
      setSupport(support)
    } catch (error) {
      setErrorFetchingSupport(true)
    }
  }

  async function fetchJourney(id: string) {
    try {
      const journey = await JourneysAPI.show(id)
      setJourney(journey)
    } catch (error) {
      setErrorFetchingJourney(true)
    }
  }

  useAuthEffect(() => {
    // eslint-disable-next-line
    fetchSupport(params.supportId!)
    // eslint-disable-next-line
    fetchJourney(params.id!)
  })

  if (errorFetchingSupport) return <div>There was an error fetching the support you requested.</div>
  if (errorFetchingJourney) return <div>There was an error fetching the journey you requested.</div>
  if (!support || !journey) return <Loader />

  return (
    <div className="page">
      <Breadcrumbs>
        <Anchor component={Link} to={routes.app.journeys.index}>
          journeys
        </Anchor>
        <Anchor component={Link} to={routes.app.journeys.show(journey.id)}>
          {journey.name}
        </Anchor>
        <Anchor component={Link} to={routes.app.supports.new}>
          {support.name}
        </Anchor>
      </Breadcrumbs>

      <TitleWithButton title={support.name}>
        <Button className="outlined red" onClick={() => setShowDeleteModal(true)}>
          detach from journey
        </Button>
      </TitleWithButton>

      <Paper p={20} mt={20}>
        <Table>
          <tbody>
            {[
              <tr key={1}>
                <td>meal planner modifier:</td>
                <td style={{ textAlign: 'right' }}>{support.mealPlannerModifier}</td>
              </tr>,
              <tr key={2}>
                <td>focus nutrient:</td>
                <td style={{ textAlign: 'right' }}>{support.supportFocusNutrient}</td>
              </tr>,
            ]}
          </tbody>
        </Table>
      </Paper>

      <ConfirmModal
        opened={showDeleteModal}
        onCancel={() => setShowDeleteModal(false)}
        onConfirm={async () => {
          await JourneysAPI.detachSupport(journey.id, support.id)
          navigate(routes.app.journeys.show(journey.id))
        }}
        title="Are you sure?"
        message="Are you sure you want to detach this support from this journey?"
        confirmText="Really detach?"
      />
    </div>
  )
}

import { Anchor, Breadcrumbs, Title } from '@mantine/core'
import routes from '../../config/routes'
import { Link } from 'react-router-dom'
import SupportForm from '../../components/forms/support-form'

export default function SupportsNewPage() {
  return (
    <div className="page">
      <Breadcrumbs>
        <Anchor component={Link} to={routes.app.supports.index}>
          supports
        </Anchor>
        <Anchor component={Link} to={routes.app.supports.new}>
          new
        </Anchor>
      </Breadcrumbs>

      <Title>Create a Support</Title>

      <SupportForm />
    </div>
  )
}

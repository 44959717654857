import { useEffect, useState } from 'react'
import FormGroup from '../../components/forms/utils/form-group'
import { Anchor, Breadcrumbs, Button, Title } from '@mantine/core'
import ArticlesAPI, { Article } from '../../api/articles'
import routes from '../../config/routes'
import { useNavigate, useParams } from 'react-router-dom'
import FormButtonGroup from '../../components/forms/utils/form-button-group'
import { Link } from 'react-router-dom'
import useAuthEffect from '../../hooks/useAuthEffect'
import ArticleForm from '../../components/forms/article-form'
import Loader from '../../components/loader'

export default function ArticlesEditPage() {
  const params = useParams()
  const navigate = useNavigate()
  const [name, setName] = useState('')
  const [nameErrors, setNameErrors] = useState<string[]>([])
  const [url, setUrl] = useState('')
  const [urlErrors, setUrlErrors] = useState<string[]>([])
  const [article, setArticle] = useState<Article | null>(null)
  const [errorFetchingArticle, setErrorFetchingArticle] = useState(false)

  const hasChanges = article && (name !== article.name || url !== article.url)

  useAuthEffect(() => {
    async function fetchArticle(id: string) {
      try {
        const article = await ArticlesAPI.show(id)
        setArticle(article)
        setName(article.name)
        setUrl(article.url)
      } catch (error) {
        setErrorFetchingArticle(true)
      }
    }

    // eslint-disable-next-line
    fetchArticle(params.id!)
  })

  if (!article) return <Loader />

  return (
    <div className="page">
      <Breadcrumbs>
        <Anchor component={Link} to={routes.app.articles.index}>
          articles
        </Anchor>
        <Anchor component={Link} to={routes.app.articles.show(article.id)}>
          {article.name}
        </Anchor>
        <Anchor component={Link} to={routes.app.articles.edit(article.id)}>
          edit
        </Anchor>
      </Breadcrumbs>

      <Title>Edit {article.name}</Title>
      <ArticleForm article={article} />
    </div>
  )
}

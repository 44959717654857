import { Anchor, Breadcrumbs, Title } from '@mantine/core'
import { useEffect, useState } from 'react'
import { Link, useParams } from 'react-router-dom'
import VideosAPI, { Video } from '../../api/videos'
import routes from '../../config/routes'
import VideoForm from '../../components/forms/video-form'
import useAuthEffect from '../../hooks/useAuthEffect'
import Loader from '../../components/loader'

export default function VideosEditPage() {
  const params = useParams()
  const [video, setVideo] = useState<Video | null>(null)
  const [errorFetchingVideo, setErrorFetchingVideo] = useState(false)

  useAuthEffect(() => {
    async function fetchVideo(id: string) {
      try {
        const video = await VideosAPI.show(id)
        setVideo(video)
      } catch (error) {
        setErrorFetchingVideo(true)
      }
    }

    // eslint-disable-next-line
    fetchVideo(params.id!)
  })

  if (errorFetchingVideo) return <div>Error fetching video</div>
  if (!video) return <Loader />

  return (
    <div className="page">
      <Breadcrumbs>
        <Anchor component={Link} to={routes.app.videos.index}>
          videos
        </Anchor>
        <Anchor component={Link} to={routes.app.videos.show(video.id)}>
          {video.name}
        </Anchor>
        <Anchor component={Link} to={routes.app.videos.edit(video.id)}>
          edit
        </Anchor>
      </Breadcrumbs>

      <Title>Edit {video.name}</Title>
      <VideoForm video={video} />
    </div>
  )
}

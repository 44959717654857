import { NumberInput, PasswordInput, Select, Switch, TextInput, Textarea } from '@mantine/core'
import React from 'react'

export default function FormGroup<
  InputType extends FormGroupInputType,
  ValueType = InputType extends 'text'
    ? string
    : InputType extends 'textarea'
    ? string
    : InputType extends 'password'
    ? string
    : InputType extends 'number'
    ? number
    : InputType extends 'switch'
    ? boolean
    : never,
>({
  id,
  value,
  onChange,
  label,
  errors = [],
  required = false,
  inputType = 'text' as InputType,
  name = undefined,
  step = undefined,
  options = undefined,
}: {
  id: string
  onChange: (value: ValueType) => void
  label?: string
  errors?: string[]
  required?: boolean
  inputType?: InputType
  value: ValueType
  name?: string
  step?: number
  options?: (string | { label: string; value: any })[]
}) {
  return (
    <React.Fragment>
      <div className="form-group">
        <label htmlFor={id}>{label || name || id}</label>
        <FormGroupInput
          id={id}
          type={inputType}
          value={value as string | number}
          name={name || label || id}
          onChange={value => onChange(value as ValueType)}
          required={required}
          step={step}
          options={options}
        />
      </div>
      <div className="errors">
        {errors.map((err, index) => (
          <div className="error" key={index}>
            {err}
          </div>
        ))}
      </div>
    </React.Fragment>
  )
}

function FormGroupInput({
  id,
  type,
  value,
  name,
  onChange,
  required,
  step,
  options,
}: {
  id: string
  type: FormGroupInputType
  value: string | number | boolean | null
  name: string
  onChange: (v: typeof value) => void | Promise<void>
  required: boolean
  step?: number
  options?: (string | { label: string; value: any })[]
}) {
  switch (type) {
    case 'text':
      return (
        <TextInput
          id={id}
          value={value as string}
          name={name}
          onChange={e => onChange(e.target.value)}
          required={required}
        />
      )

    case 'textarea':
      return (
        <Textarea
          id={id}
          autosize
          value={value as string}
          name={name}
          onChange={e => onChange(e.target.value)}
          required={required}
        />
      )

    case 'password':
      return (
        <PasswordInput
          id={id}
          value={value as number}
          name={name}
          onChange={e => onChange(e.target.value)}
          required={required}
        />
      )

    case 'number':
      return (
        <NumberInput
          id={id}
          value={value as number}
          name={name}
          step={step}
          onChange={v => onChange(v)}
          required={required}
        />
      )

    case 'switch':
      return (
        <Switch
          id={id}
          checked={value as boolean}
          name={name}
          onChange={e => onChange(e.currentTarget.checked)}
          required={required}
          size="lg"
          mt={10}
        />
      )

    case 'select':
      if (!options) console.error('options are required for select input types')
      return (
        <Select
          id={id}
          name={name}
          data={options!}
          value={value as string | null}
          onChange={async val => {
            await onChange(val)
          }}
        />
      )

    default:
      throw `Unrecognized type passed to FormGroupInput: "${type}`
  }
}

export type FormGroupInputType = 'text' | 'textarea' | 'password' | 'number' | 'switch' | 'select'

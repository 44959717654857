import routes from '../config/routes'
import { api } from './common'
import { AdminTrackerQuestion, TrackerQuestionAssociationTypesEnum } from './schema'

export default class TrackerQuestionsAPI {
  public static async index({
    associationType,
  }: { associationType?: TrackerQuestionAssociationTypesEnum } = {}) {
    const res = await api.get(routes.api.trackerQuestions.index, { params: { associationType } })
    return res.data as AdminTrackerQuestion[]
  }
}

import { Button, Paper } from '@mantine/core'
import TitleWithButton from '../title/TitleWithButton'
import { ReactNode, useState } from 'react'
import EmptyMessage from '../display/empty-message'
import { useNavigate } from 'react-router-dom'
import JourneyOnboardingQuestionCard from '../card/journey-onboarding-question-card'
import SelectJourneyOnboardingScreenModal from '../modal/composite/select-journey-onboarding-screen-modal'
import {
  JourneyOnboardingQuestion,
  JourneyOnboardingQuestionType,
} from '../../api/journeyOnboardingQuestions'
import SortableItems from '../sortable-items'

export default function JourneyOnboardingQuestionsCrud({
  journeyId,
  journeyOnboardingQuestions,
  onSelectOnboardingQuestion,
  onDeleteOnboardingQuestion,
  onSort,
  emptyMessage = 'No onboarding questions found',
}: {
  journeyId: string
  journeyOnboardingQuestions: JourneyOnboardingQuestion[]
  onSelectOnboardingQuestion: (onboardingQuestionType: JourneyOnboardingQuestionType) => void
  onDeleteOnboardingQuestion: (OnboardingQuestion: JourneyOnboardingQuestion) => void
  onSort: (
    journeyOnboardingQuestion: JourneyOnboardingQuestion,
    position: number,
    newJourneyOnboardingQuestions: JourneyOnboardingQuestion[]
  ) => void | Promise<void>
  emptyMessage?: ReactNode
}) {
  const navigate = useNavigate()
  const [showModal, setShowModal] = useState(false)

  return (
    <>
      <Paper mt={20} p={20} className="onboarding-questions-list">
        <TitleWithButton order={2} title="Onboarding Questions">
          <Button className="green filled" onClick={() => setShowModal(true)}>
            Add question
          </Button>
        </TitleWithButton>

        {!journeyOnboardingQuestions.length && <EmptyMessage>{emptyMessage}</EmptyMessage>}

        <SortableItems
          itemType="JourneyOnboardingQuestion"
          items={journeyOnboardingQuestions}
          onSort={async (journeyOnboardingQuestion, position, newJourneyOnboardingQuestions) => {
            await onSort(journeyOnboardingQuestion, position, newJourneyOnboardingQuestions)
          }}
          render={onboardingQuestion => (
            <JourneyOnboardingQuestionCard
              journeyId={journeyId}
              journeyOnboardingQuestion={onboardingQuestion}
              onDelete={async onboardingQuestion => {
                onDeleteOnboardingQuestion(onboardingQuestion)
              }}
            />
          )}
        />
      </Paper>

      <SelectJourneyOnboardingScreenModal
        opened={showModal}
        onCancel={() => setShowModal(false)}
        onSelectOnboardingQuestionType={async journeyOnboardingQuestionType => {
          setShowModal(false)
          onSelectOnboardingQuestion(journeyOnboardingQuestionType)
        }}
      />
    </>
  )
}

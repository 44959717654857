import { Button, Modal, Text } from '@mantine/core'
import { ReactNode } from 'react'

export default function ConfirmModal({
  opened,
  title,
  message,
  onCancel,
  onConfirm,
  confirmText = 'Confirm',
  cancelText = 'Cancel',
}: {
  opened: boolean
  title: string
  message: ReactNode | ReactNode[]
  onConfirm: () => void
  onCancel: () => void
  confirmText?: string
  cancelText?: string
}) {
  return (
    <Modal opened={opened} onClose={onCancel} title={title}>
      <Text style={{ marginBottom: 20 }}>{message}</Text>

      <Text style={{ marginLeft: 'auto', textAlign: 'right' }}>
        <Button className="outlined gray" onClick={onCancel} style={{ marginRight: 10 }}>
          {cancelText}
        </Button>
        <Button className="outlined red" onClick={onConfirm}>
          {confirmText}
        </Button>
      </Text>
    </Modal>
  )
}

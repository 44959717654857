import { Modal, Text, TextInput } from '@mantine/core'
import VideosAPI, { Video } from '../../../api/videos'
import { useEffect, useState } from 'react'
import VideoCard from '../../card/video-card'
import Loader from '../../loader'

export default function SelectVideoModal({
  opened,
  onCancel,
  onSelectVideo,
  title = 'Select an onboarding question type',
}: {
  opened: boolean
  onSelectVideo: (video: Video) => void
  onCancel: () => void
  title?: string
  confirmText?: string
  cancelText?: string
}) {
  const [query, setQuery] = useState('')
  const [videos, setVideos] = useState<Video[] | null>(null)
  const [errorFetchingVideos, setErrorFetchingVideos] = useState(false)

  async function fetchVideos() {
    try {
      const videos = await VideosAPI.index({ query })
      setVideos(videos)
    } catch (error) {
      setErrorFetchingVideos(true)
    }
  }

  useEffect(() => {
    // eslint-disable-next-line
    fetchVideos()
  }, [query])

  if (errorFetchingVideos) return <div>Something happened while fetching videos.</div>
  if (!videos) return <Loader />

  return (
    <Modal opened={opened} onClose={onCancel} title={title} fullScreen={true}>
      <Text mb={20}>Select a video</Text>
      <TextInput
        id="search-videos-input"
        mb={20}
        value={query}
        onChange={e => setQuery(e.target.value)}
        label="Search"
      />

      {videos.map((video, index) => (
        <VideoCard width={120} height={120} video={video} key={index} onClick={() => onSelectVideo(video)} />
      ))}
    </Modal>
  )
}

import { ResponsiveLine, Serie, DatumValue } from '@nivo/line'

export default function LineChart({
  data,
  xAxisLabels,
  gridXValues,
  yAxisTitle,
  minYValue,
  maxYValue,
}: {
  data: Serie[]
  xAxisLabels: DatumValue[]
  gridXValues: DatumValue[]
  yAxisTitle: string
  minYValue: number
  maxYValue: number
}) {
  if (!data) return null

  const colors = ['#425C80', '#B83313', '#456E5A', '#974D75']

  return (
    <div style={{ height: 300, width: '100%' }}>
      <ResponsiveLine
        data={data}
        margin={{ top: 50, right: 5, bottom: 60, left: 45 }}
        xScale={{ type: 'point' }}
        yScale={{
          type: 'linear',
          min: minYValue,
          max: maxYValue,
          stacked: false,
          reverse: false,
        }}
        // yFormat=" >-.2f"
        axisTop={null}
        axisRight={null}
        axisBottom={{
          tickSize: 5,
          tickPadding: 5,
          tickRotation: -60,
          legend: null,
          legendOffset: 32,
          legendPosition: 'middle',
          tickValues: xAxisLabels,
        }}
        axisLeft={{
          tickSize: 5,
          tickPadding: 5,
          tickRotation: 0,
          legend: yAxisTitle,
          legendOffset: -40,
          legendPosition: 'middle',
        }}
        enableGridX={true}
        gridXValues={gridXValues}
        enableGridY={true}
        colors={colors}
        pointSize={10}
        pointColor={{ from: 'color', modifiers: [] }}
        pointBorderWidth={0}
        // pointBorderColor={{ from: 'serieColor' }}
        pointLabelYOffset={-12}
        useMesh={true}
        legends={[
          {
            anchor: 'top',
            direction: 'row',
            justify: false,
            translateX: 0,
            translateY: -30,
            itemsSpacing: 0,
            itemDirection: 'left-to-right',
            itemWidth: 80,
            itemHeight: 20,
            itemOpacity: 0.75,
            symbolSize: 12,
            symbolShape: 'circle',
            symbolBorderColor: 'rgba(0, 0, 0, .5)',
            effects: [
              {
                on: 'hover',
                style: {
                  itemBackground: 'rgba(0, 0, 0, .03)',
                  itemOpacity: 1,
                },
              },
            ],
          },
        ]}
      />
    </div>
  )
}

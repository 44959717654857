import { ResponsiveBar, BarDatum } from '@nivo/bar'

export default function BarChart({
  data,
  keys,
  indexBy,
  xAxisLabels,
  yAxisTitle,
}: {
  data: any
  keys: string[]
  indexBy: string
  xAxisLabels: string[]
  yAxisTitle: string
}) {
  // if (!data) return null

  const colors = ['#425C80', '#B83313', '#456E5A', '#974D75']

  return (
    <div style={{ height: 300, width: '100%' }}>
      <ResponsiveBar
        data={data}
        keys={keys}
        indexBy={indexBy}
        margin={{ top: 50, right: 5, bottom: 60, left: 45 }}
        padding={0}
        valueScale={{ type: 'linear' }}
        indexScale={{ type: 'band', round: false }}
        colors={colors}
        minValue={0}
        defs={[
          {
            id: 'dots',
            type: 'patternDots',
            background: 'inherit',
            color: '#38bcb2',
            size: 4,
            padding: 1,
            stagger: true,
          },
          {
            id: 'lines',
            type: 'patternLines',
            background: 'inherit',
            color: '#eed312',
            rotation: -45,
            lineWidth: 6,
            spacing: 10,
          },
        ]}
        borderColor={{
          from: 'color',
          modifiers: [['darker', 1.6]],
        }}
        axisTop={null}
        axisRight={null}
        axisBottom={{
          tickSize: 5,
          tickPadding: 5,
          tickRotation: -60,
          legend: null,
          legendOffset: 32,
          legendPosition: 'middle',
          tickValues: xAxisLabels,
        }}
        axisLeft={{
          tickSize: 5,
          tickPadding: 5,
          tickRotation: 0,
          legend: yAxisTitle,
          legendOffset: -40,
          legendPosition: 'middle',
        }}
        labelSkipWidth={12}
        labelSkipHeight={12}
        labelTextColor={{
          from: 'color',
          modifiers: [['darker', 1.6]],
        }}
        legends={[
          {
            dataFrom: 'keys',
            anchor: 'top',
            direction: 'row',
            justify: false,
            translateX: 0,
            translateY: -20,
            itemsSpacing: 2,
            itemWidth: 100,
            itemHeight: 20,
            itemDirection: 'left-to-right',
            itemOpacity: 0.85,
            symbolSize: 20,
            effects: [
              {
                on: 'hover',
                style: {
                  itemOpacity: 1,
                },
              },
            ],
          },
        ]}
        role="application"
        barAriaLabel={e => e.id + ': ' + e.formattedValue + ' in country: ' + e.indexValue}
      />
    </div>
  )
}

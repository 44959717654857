/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-unsafe-argument */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import { AxiosRequestConfig } from 'axios'

export default function proxyAxiosRequestToChromeExtension(
  config: AxiosRequestConfig<any>,
  event: MessageEvent
) {
  const reduxId = config.data?.reduxId || config.params?.reduxId
  delete config.data?.reduxId
  delete config.params?.reduxId

  event.source!.postMessage(
    {
      action: 'wellos-admin',
      payload: {
        method: config.method,
        url: config.url,
        data: config.data,
        params: config.params,
      },
      reduxId,
    },
    event.origin as any
  )

  const controller = new AbortController()
  config.signal = controller.signal
  controller.abort()
}

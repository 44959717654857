import { Button, Paper } from '@mantine/core'
import TitleWithButton from '../title/TitleWithButton'
import { ReactNode, useState } from 'react'
import EmptyMessage from '../display/empty-message'
import SelectSupportModal from '../modal/composite/select-support'
import { Support } from '../../api/supports'
import SupportCard from '../card/support-card'
import SortableItems from '../sortable-items'
import { AdminJourneySupport, AdminSupport } from '../../api/schema'

export default function JourneySupportsCrud({
  journeySupports,
  onSelectSupport,
  onSort,
  supportShowRouteCB,
  emptyMessage = 'No supports found',
}: {
  journeySupports: AdminJourneySupport[]
  onSelectSupport: (support: AdminSupport) => void
  supportShowRouteCB: (support: AdminSupport) => string
  onSort: (
    journeySupport: AdminJourneySupport,
    position: number,
    newJourneySupports: AdminJourneySupport[]
  ) => void | Promise<void>
  emptyMessage?: ReactNode
}) {
  const [showModal, setShowModal] = useState(false)

  return (
    <>
      <Paper mt={20} p={20} className="journey-supports-crud">
        <TitleWithButton order={2} title="Supports">
          <Button className="green filled" onClick={() => setShowModal(true)}>
            Add support
          </Button>
        </TitleWithButton>

        {!journeySupports.length && <EmptyMessage>{emptyMessage}</EmptyMessage>}

        <SortableItems
          itemType="JourneySupport"
          items={journeySupports}
          onSort={async (journeySupport, position, newJourneySupports) => {
            await onSort(journeySupport, position, newJourneySupports)
          }}
          render={journeySupport => (
            <SupportCard support={journeySupport.support} href={supportShowRouteCB(journeySupport.support)} />
          )}
        />
      </Paper>

      <SelectSupportModal
        opened={showModal}
        onCancel={() => setShowModal(false)}
        onSelectSupport={async support => {
          setShowModal(false)
          onSelectSupport(support)
        }}
      />
    </>
  )
}

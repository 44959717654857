import routes from '../config/routes'
import { api } from './common'

export default class PracticeOnboardingQuestionsAPI {
  public static async update(id: string, attrs: Partial<PracticeOnboardingQuestionParams>) {
    return await api.patch(routes.api.supports.practices.onboardingQuestions.update(id), {
      practiceOnboardingQuestion: attrs,
    })
  }
}

export interface PracticeOnboardingQuestion {
  id: string
  position: number
  screen: PracticeOnboardingQuestionType
}

export interface PracticeOnboardingQuestionParams {
  position: number
}

export type PracticeOnboardingQuestionType =
  | 'commitment_days'
  | 'confidence'
  | 'occasion_types'
  | 'start_date'

export const PracticeOnboardingQuestionTypes = [
  'commitment_days',
  'confidence',
  'occasion_types',
  'start_date',
]

import { useEffect, useState } from 'react'
import FormGroup from '../../../components/forms/utils/form-group'
import { Anchor, Breadcrumbs, Button, Title } from '@mantine/core'
import routes from '../../../config/routes'
import { useNavigate, useParams } from 'react-router-dom'
import FormButtonGroup from '../../../components/forms/utils/form-button-group'
import { Link } from 'react-router-dom'
import ChecklistTriggerEventTriggersAPI, {
  ChecklistTriggerDesiredStatesEnum,
  ChecklistTriggerEventTrigger,
} from '../../../api/supportChecklistTriggerEventTriggers'
import axios from 'axios'
import useAuthEffect from '../../../hooks/useAuthEffect'
import Loader from '../../../components/loader'

export default function ChecklistTriggerEventTriggersEditPage() {
  const params = useParams()
  const navigate = useNavigate()
  const [desiredState, setDesiredState] = useState<ChecklistTriggerDesiredStatesEnum | null>(null)
  const [desiredStateErrors, setDesiredStateErrors] = useState<string[]>([])
  const [matchCount, setMatchCount] = useState<number>(0)
  const [matchCountErrors, setMatchCountErrors] = useState<string[]>([])
  const [errorFetching, setErrorFetching] = useState(false)
  const [checklistTriggerEventTrigger, setChecklistTriggerEventTrigger] =
    useState<ChecklistTriggerEventTrigger | null>(null)

  useAuthEffect(() => {
    async function fetch(id: string) {
      try {
        const checklistTriggerEventTrigger = await ChecklistTriggerEventTriggersAPI.show(id)
        setChecklistTriggerEventTrigger(checklistTriggerEventTrigger)
        setDesiredState(checklistTriggerEventTrigger?.desiredState || '')
        setMatchCount(checklistTriggerEventTrigger?.matchCount || 0)
      } catch (error) {
        setErrorFetching(true)
      }
    }

    // eslint-disable-next-line
    fetch(params.id!)
  })

  if (errorFetching) return <div>Failed to find the checklistTriggerEventTrigger you're looking for.</div>
  if (!checklistTriggerEventTrigger) return <Loader />
  if (!checklistTriggerEventTrigger)
    return <div>The coach tip you are looking for was not found on this checklistTriggerEventTrigger.</div>

  return (
    <div className="page">
      <Breadcrumbs>
        <Anchor component={Link} to={routes.app.checklistTriggers.index}>
          checklist Triggers
        </Anchor>
        <Anchor
          component={Link}
          to={routes.app.checklistTriggers.show(checklistTriggerEventTrigger.checklistTrigger.id)}
        >
          {checklistTriggerEventTrigger.checklistTrigger.name}
        </Anchor>
        <Anchor
          component={Link}
          to={routes.app.checklistTriggers.eventTriggers.show(checklistTriggerEventTrigger.id)}
        >
          {checklistTriggerEventTrigger.eventTrigger.name}
        </Anchor>
        <Anchor
          component={Link}
          to={routes.app.checklistTriggers.eventTriggers.edit(checklistTriggerEventTrigger.id)}
        >
          edit
        </Anchor>
      </Breadcrumbs>

      <Title>Edit checklist trigger {checklistTriggerEventTrigger.id}</Title>
      <form
        id="edit-checklist-trigger-event-trigger-form"
        onSubmit={async event => {
          event.preventDefault()
          try {
            await ChecklistTriggerEventTriggersAPI.update(checklistTriggerEventTrigger.id, {
              matchCount,
              desiredState: desiredState!,
            })
            navigate(routes.app.checklistTriggers.eventTriggers.show(checklistTriggerEventTrigger.id))
          } catch (err: any) {
            if (Array.isArray(err.desiredState)) setDesiredStateErrors(err.desiredState)
          }
        }}
      >
        <FormGroup
          required
          inputType="select"
          options={['match', 'no-match']}
          id="desiredState"
          label="desired state"
          name="desiredState"
          value={desiredState}
          errors={desiredStateErrors}
          onChange={val => {
            setDesiredState(val)
            setDesiredStateErrors([])
          }}
        />

        {desiredState === 'match' && (
          <FormGroup
            required
            inputType="number"
            id="matchCount"
            label="match count"
            name="matchCount"
            value={matchCount}
            errors={matchCountErrors}
            onChange={val => {
              setMatchCount(val)
              setMatchCountErrors([])
            }}
          />
        )}
        <FormButtonGroup>
          <Button type="submit">Save</Button>
        </FormButtonGroup>
      </form>
    </div>
  )
}

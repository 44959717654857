import { Anchor, Box, Breadcrumbs, Button, Paper, SimpleGrid, Table, Text, Title } from '@mantine/core'
import { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import routes from '../../config/routes'
import PhasesAPI, { Phase } from '../../api/phases'
import { NavLink } from 'react-router-dom'
import useAuthEffect from '../../hooks/useAuthEffect'
import TitleWithButton from '../../components/title/TitleWithButton'
import Loader from '../../components/loader'

export default function PhasesIndexPage() {
  const [phases, setPhases] = useState<Phase[]>([])
  const [errorFetchingPhase, setErrorFetchingPhase] = useState(false)
  const [doneFetching, setDoneFetching] = useState(false)

  useAuthEffect(() => {
    async function fetchPhases() {
      try {
        const phases = await PhasesAPI.index()
        setPhases(phases)
        setDoneFetching(true)
      } catch (error) {
        setErrorFetchingPhase(true)
      }
    }

    // eslint-disable-next-line
    fetchPhases()
  })

  return (
    <div className="page">
      <TitleWithButton title="Phases">
        <Button className="filled green" component={Link} to={routes.app.phases.new}>
          New phase
        </Button>
      </TitleWithButton>

      {phases.length > 0 && (
        <Paper mt={20} p="md">
          <Table>
            <thead>
              <tr>
                <td>Name</td>
                <td>Number of parts</td>
                <td>Foundation</td>
              </tr>
            </thead>
            <tbody>
              {phases.map((phase, index) => (
                <tr key={index}>
                  <td>
                    <Anchor component={NavLink} to={routes.app.phases.show(phase.id)}>
                      {phase.name}
                    </Anchor>
                  </td>
                  <td>{phase.phaseParts.length}</td>
                  <td>{phase.foundation ? 'true' : 'false'}</td>
                </tr>
              ))}
            </tbody>
          </Table>
        </Paper>
      )}

      {!doneFetching && <Loader />}

      {phases.length === 0 && doneFetching && (
        <Text style={{ textAlign: "center" }}>
          <Paper m="lg" p="md">
            No phases have been created yet.{' '}
            <Anchor component={Link} to={routes.app.phases.new}>
              Create one
            </Anchor>{' '}
            to get started.
          </Paper>
        </Text>
      )}
    </div>
  )
}

import { Badge, Paper, Table, Text } from '@mantine/core'
import { UserSupport } from '../../../app/api/userSupports'

export default function UserSupportTable({
  userSupports,
  title,
}: {
  userSupports: (UserSupport | null)[]
  title: string
}) {
  return (
    <Paper p={20} mt={10} mb={10}>
      <Text h={3} mb={30}>
        {title}
      </Text>
      <Table>
        <thead>
          <tr>
            <td style={{ width: '50%' }}>name</td>
            <td style={{ textAlign: 'right', width: '25%' }}>activated</td>
            <td style={{ textAlign: 'right', width: '25%' }}>status</td>
          </tr>
        </thead>
        <tbody>
          {userSupports.map(userSupport => (
            <tr>
              <td style={{ width: '50%' }}>
                {userSupport ? (
                  <>
                    {userSupport.status === 'active' && (
                      <Badge color="green" variant="filled" mr={10}>
                        active
                      </Badge>
                    )}
                    {userSupport.name}
                  </>
                ) : (
                  'N/A'
                )}
              </td>
              <td style={{ textAlign: 'right', width: '25%' }}>{userSupport?.activatedOn || 'N/A'}</td>
              <td style={{ textAlign: 'right', width: '25%' }}>{userSupport?.status || 'N/A'}</td>
            </tr>
          ))}
        </tbody>
      </Table>
    </Paper>
  )
}

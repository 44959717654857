import { useEffect } from 'react'
import { useAppDispatch, useAppSelector } from './app/hooks'
import { Route, Routes } from 'react-router-dom'
import { setChromeExtensionActive } from './app/stores/chromeExtension'
import routes from './app/config/routes'
import TestIntercomIdPage from './app/pages/test-only/test-intercom-id'
import ChromeExtensionUsersShowPage from './chrome-extension/pages/users/show'
import TestIntercomConversationIdPage from './app/pages/test-only/test-intercom-conversation-id'

export default function ChromeExtension() {
  const dispatch = useAppDispatch()

  useEffect(() => {
    // eslint-disable-next-line
    dispatch(setChromeExtensionActive(true))
  })

  return (
    <div id="wellos-central-chrome-extension">
      <Routes>
        <Route path="/" element={<ChromeExtensionUsersShowPage />} />

        {process.env.REACT_APP_PSYCHIC_ENV === 'test' && (
          <>
            <Route
              path={routes.app.testOnly.testIntercomId(':intercomId')}
              element={<TestIntercomIdPage />}
            />
            <Route
              path={routes.app.testOnly.testIntercomConversationId(':intercomConversationId')}
              element={<TestIntercomConversationIdPage />}
            />
          </>
        )}
      </Routes>
    </div>
  )
}

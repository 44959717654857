import routes from '../config/routes'
import { api } from './common'

export default class SupportEventNounsAPI {
  public static async index({ query }: { query?: string } = {}) {
    const res = await api.get(routes.api.supports.eventNouns.index, { params: { query } })
    return res.data as SupportEventNoun[]
  }
}

export interface SupportEventNoun {
  id: string
  noun: string
}

import { Button, Modal, Text, TextInput } from '@mantine/core'
import { ReactNode, useEffect, useState } from 'react'
import SupportEventVerbsAPI, { SupportEventVerb } from '../../../api/supportEventVerbs'
import SupportEventVerbCard from '../../card/support-event-verb-card'

export default function SelectSupportEventVerbModal({
  opened,
  onCancel,
  onSelectSupportEventVerb,
  title = 'Select a verb',
}: {
  opened: boolean
  onSelectSupportEventVerb: (supportEventVerb: SupportEventVerb) => void
  onCancel: () => void
  title?: string
  confirmText?: string
  cancelText?: string
}) {
  const [query, setQuery] = useState('')
  const [supportEventVerbs, setSupportEventVerbs] = useState<SupportEventVerb[]>([])

  useEffect(() => {
    async function fetchSupportEventVerbs() {
      setSupportEventVerbs(await SupportEventVerbsAPI.index({ query }))
    }

    if (opened) {
      // eslint-disable-next-line
      fetchSupportEventVerbs()
    }
  }, [query, opened])

  return (
    <Modal fullScreen opened={opened} onClose={onCancel} title={title}>
      <TextInput
        id="search-support-event-verbs-input"
        mb={20}
        value={query}
        onChange={e => setQuery(e.target.value)}
        label="Search"
      />
      {supportEventVerbs.map((supportEventVerb, index) => (
        <button
          key={index}
          onClick={async () => {
            onSelectSupportEventVerb(supportEventVerb)
          }}
        >
          <SupportEventVerbCard supportEventVerb={supportEventVerb} />
        </button>
      ))}
    </Modal>
  )
}

import { AppShell, NavLink as MantineNavLink, Image as MantineImage } from '@mantine/core'
import {
  IconHome2,
  IconAnchor,
  IconAffiliate,
  IconApiApp,
  IconLadder,
  IconVocabulary,
  IconPhoto,
  IconPyramid,
  IconStethoscope,
  IconBooks,
  IconNews,
  IconVideo,
  IconTimelineEvent,
  IconChecklist,
  IconSettings,
} from '@tabler/icons-react'
import routes from '../../config/routes'
import { NavLink } from 'react-router-dom'

export default function Navbar({ opened }: { opened: boolean }) {
  return (
    <AppShell.Navbar
      p="md"
      hidden={!opened}
      style={{ background: 'transparent', borderRight: 'transparent' }}
    >
      <MantineImage
        ml={0}
        mt={20}
        mb={20}
        src="/img/logo.svg"
        height={30}
        width={121}
        fit="contain"
        style={{ textAlign: 'left' }}
      />
      <MantineNavLink
        component={NavLink}
        to={routes.app.home}
        label="Home"
        leftSection={<IconHome2 stroke={1.5} />}
      />
      <MantineNavLink
        component={NavLink}
        to={routes.app.journeys.index}
        label="Journeys"
        leftSection={<IconAnchor stroke={1.5} />}
      />
      <MantineNavLink
        component={NavLink}
        to={routes.app.supports.index}
        label="Supports"
        leftSection={<IconPyramid stroke={1.5} />}
      />
      <MantineNavLink
        component={NavLink}
        to={routes.app.phases.index}
        label="Phases"
        leftSection={<IconAffiliate stroke={1.5} />}
      />
      <MantineNavLink
        component={NavLink}
        to={routes.app.parts.index}
        label="Parts"
        leftSection={<IconApiApp stroke={1.5} />}
      />
      <MantineNavLink
        component={NavLink}
        to={routes.app.practices.index}
        label="Practices"
        leftSection={<IconLadder stroke={1.5} />}
      />
      <MantineNavLink
        component={NavLink}
        to={routes.app.humanCoaches.index}
        label="Human Coaches"
        leftSection={<IconStethoscope stroke={1.5} />}
      />
      <MantineNavLink
        component={NavLink}
        to={routes.app.lessons.index}
        label="Lessons"
        leftSection={<IconVocabulary stroke={1.5} />}
      />
      <MantineNavLink
        component={NavLink}
        to={routes.app.lessonResources.index}
        label="Lesson Resources"
        leftSection={<IconBooks stroke={1.5} />}
      />
      <MantineNavLink
        component={NavLink}
        to={routes.app.articles.index}
        label="Articles"
        leftSection={<IconNews stroke={1.5} />}
      />
      <MantineNavLink
        component={NavLink}
        to={routes.app.images.index}
        label="Images"
        leftSection={<IconPhoto stroke={1.5} />}
      />
      <MantineNavLink
        component={NavLink}
        to={routes.app.videos.index}
        label="Videos"
        leftSection={<IconVideo stroke={1.5} />}
      />
      <MantineNavLink
        component={NavLink}
        to={routes.app.checklistTriggers.index}
        label="Checklist Triggers"
        leftSection={<IconChecklist stroke={1.5} />}
      />
      <MantineNavLink
        component={NavLink}
        to={routes.app.eventTriggers.index}
        label="Event Triggers"
        leftSection={<IconTimelineEvent stroke={1.5} />}
      />
      <MantineNavLink
        component={NavLink}
        to={routes.app.system.index}
        label="System"
        leftSection={<IconSettings stroke={1.5} />}
      />
    </AppShell.Navbar>
  )
}

/* eslint-disable @typescript-eslint/no-unsafe-return */
/* eslint-disable @typescript-eslint/no-unsafe-argument */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { EventType } from '@azure/msal-browser'
import axios, { AxiosError } from 'axios'
import { pca } from '../../..'
import { api } from '../../api/common'
import { useAppDispatch } from '../../hooks'
import dispatchNewAuthToken from './dispatchNewAuthToken'
import fetchAuthToken from './fetchAuthToken'
import refreshAccessToken from './refreshAccessToken'

export default function appAuthBindings(
  dispatch: ReturnType<typeof useAppDispatch>,
  { onAuthError }: { onAuthError: () => void }
) {
  pca.addEventCallback(pcaEventHandler)

  api.interceptors.request.use(async config => {
    const token = await refreshAccessToken(onAuthError)

    if (token) dispatchNewAuthToken(dispatch, token)
    else onAuthError()

    return config
  })

  api.interceptors.response.use(
    response => response,
    async error => msalAxiosErrorBindings(dispatch, error, onAuthError)
  )

  // eslint-disable-next-line
  fetchAuthToken(dispatch, { onAuthError })
}

async function msalAxiosErrorBindings(
  dispatch: ReturnType<typeof useAppDispatch>,
  error: AxiosError,
  onAuthError: () => void
) {
  // handle error
  const requestConfig = error.config
  if (
    (error.response?.status === 403 || error.response?.status === 401) &&
    !requestHasRetried(requestConfig)
  ) {
    setRetryForRequest(requestConfig)
    const token = await refreshAccessToken(onAuthError)
    if (token) dispatchNewAuthToken(dispatch, token, requestConfig)
    else onAuthError()

    return axios(requestConfig!)
  }
  return Promise.reject(error)
}

function setRetryForRequest(originalRequest: any) {
  originalRequest._retry = true
}

function requestHasRetried(originalRequest: any) {
  return originalRequest._retry
}

function pcaEventHandler(event: any) {
  if (event.eventType === EventType.LOGIN_SUCCESS && event.payload?.account) {
    const account = event.payload?.account
    pca.setActiveAccount(account)
  }
}

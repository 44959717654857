import routes from '../config/routes'
import { api } from './common'
import { Image } from './images'
import { LocalizedText, LocalizedTextParams } from './localizedTexts'

export default class VideosAPI {
  public static async create(attrs: VideoParams) {
    const res = await api.post(routes.api.videos.create, { video: attrs })
    return res.data as Video
  }

  public static async update(id: string, attrs: Partial<VideoUpdateParams>) {
    return (
      await api.patch(routes.api.videos.update(id), {
        video: attrs,
        fetchNewUploadUrl: attrs.fetchNewUploadUrl,
      })
    ).data as Video
  }

  public static async delete(id: string) {
    return await api.delete(routes.api.videos.delete(id))
  }

  public static async index({ query }: { query?: string } = {}) {
    const res = await api.get(routes.api.videos.index, { params: { query } })
    return res.data as Video[]
  }

  public static async show(id: string) {
    const res = await api.get(routes.api.videos.show(id))
    return res.data as Video
  }

  public static async createLocalizedText(videoId: string, params: LocalizedTextParams) {
    return await api.post(routes.api.videos.localizedTexts.create(videoId), { localizedText: params })
  }
}

export interface Video {
  id: string
  name: string
  url: string
  filename: string
  uploadUrl: string | null
  image?: Image
  landscapeImage?: Image
  localizedTexts: LocalizedText[]
  duration: DurationString
}

export interface VideoParams {
  name: string
  imageId?: string | null
  landscapeImageId?: string | null
  filename?: string | null
  contentType?: VideoContentType | null
  duration?: string | number
}

export interface VideoUpdateParams extends VideoParams {
  fetchNewUploadUrl?: boolean
}

export type VideoContentType = 'mp4' | 'm4v'

export const ACCEPTED_VIDEO_TYPES = ['video/mp4', 'video/m4v', 'video/x-m4v']

export type DurationString = `${number}:${number}`

import routes from '../config/routes'
import { api } from './common'
import { ChecklistTrigger } from './supportChecklistTriggers'
import { Support } from './supports'

export default class TriggerableItemChecklistTriggersAPI {
  // public static async create(attrs: TriggerableItemChecklistTriggerParams) {
  //   return await api.post(routes.api.supports.eventTriggers.create, { eventTrigger: attrs })
  // }
  // public static async update(id: string, attrs: Partial<TriggerableItemChecklistTriggerParams>) {
  //   return await api.patch(routes.api.supports.eventTriggers.update(id), { eventTrigger: attrs })
  // }
  // public static async delete(id: string) {
  //   return await api.delete(routes.api.supports.eventTriggers.delete(id))
  // }
  // public static async index({ query }: { query?: string } = {}) {
  //   const res = await api.get(routes.api.supports.eventTriggers.index, { params: { query } })
  //   return res.data as TriggerableItemChecklistTrigger[]
  // }

  public static async show(id: string) {
    const res = await api.get(routes.api.supports.triggerableItemChecklistTriggers.show(id))
    return res.data as TriggerableItemChecklistTrigger
  }
}

export interface TriggerableItemChecklistTrigger {
  id: string
  checklistTrigger: ChecklistTrigger

  // triggerableItem is only set on show page of triggerable item checklist trigger
  triggerableItem?: Support
}

export interface TriggerableItemChecklistTriggerParams {
  supportChecklistTriggerId: string
}

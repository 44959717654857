import { Button, Modal, Text, TextInput } from '@mantine/core'
import { ReactNode, useEffect, useState } from 'react'
import SupportEventNounsAPI, { SupportEventNoun } from '../../../api/supportEventNouns'
import SupportEventNounCard from '../../card/support-event-noun-card'

export default function SelectSupportEventNounModal({
  opened,
  onCancel,
  onSelectSupportEventNoun,
  title = 'Select a noun',
}: {
  opened: boolean
  onSelectSupportEventNoun: (supportEventNoun: SupportEventNoun) => void
  onCancel: () => void
  title?: string
  confirmText?: string
  cancelText?: string
}) {
  const [query, setQuery] = useState('')
  const [supportEventNouns, setSupportEventNouns] = useState<SupportEventNoun[]>([])

  useEffect(() => {
    async function fetchSupportEventNouns() {
      setSupportEventNouns(await SupportEventNounsAPI.index({ query }))
    }
    if (opened) {
      // eslint-disable-next-line
      fetchSupportEventNouns()
    }
  }, [query, opened])

  return (
    <Modal fullScreen opened={opened} onClose={onCancel} title={title}>
      <TextInput
        id="search-support-event-nouns-input"
        mb={20}
        value={query}
        onChange={e => setQuery(e.target.value)}
        label="Search"
      />
      {supportEventNouns.map((supportEventNoun, index) => (
        <button
          key={index}
          onClick={async () => {
            onSelectSupportEventNoun(supportEventNoun)
          }}
        >
          <SupportEventNounCard supportEventNoun={supportEventNoun} />
        </button>
      ))}
    </Modal>
  )
}

import routes from '../config/routes'
import { api } from './common'
import { LocalizedText, LocalizedTextParams } from './localizedTexts'

export default class SupportTrackerTipsAPI {
  public static async create(supportId: string, attrs: SupportTrackerTipParams) {
    return await api.post(routes.api.supports.trackerTips.create(supportId), { supportTrackerTip: attrs })
  }

  public static async update(id: string, attrs: Partial<SupportTrackerTipParams>) {
    return await api.patch(routes.api.supports.trackerTips.update(id), { supportTrackerTip: attrs })
  }

  // public static async index({ query }: { query?: string } = {}) {
  //   const res = await api.get(routes.api.supports.trackerTips.index, { params: { query } })
  //   return res.data as SupportTrackerTip[]
  // }

  public static async show(supportId: string, id: string) {
    const res = await api.get(routes.api.supports.trackerTips.show(supportId, id))
    return res.data as SupportTrackerTip
  }

  public static async delete(id: string) {
    return await api.delete(routes.api.supports.trackerTips.delete(id))
  }

  public static async createLocalizedText(id: string, params: LocalizedTextParams) {
    return await api.post(routes.api.supports.trackerTips.localizedTexts.create(id), {
      localizedText: params,
    })
  }
}

export interface SupportTrackerTip {
  id: string
  name: string
  localizedTexts: LocalizedText[]
  position: number
}

export interface SupportTrackerTipParams {
  name?: string
  position?: number
}

import { useState } from 'react'
import SupportsAPI from '../../api/supports'
import { useParams } from 'react-router-dom'
import { Anchor, Breadcrumbs, Button, Paper, Table } from '@mantine/core'
import { Link } from 'react-router-dom'
import routes from '../../config/routes'
import TitleWithButton from '../../components/title/TitleWithButton'
import FormLocalizationSection from '../../components/forms/form-localization-section'
import AddOrEditImage from '../../components/add-or-edit-image'
import SelectImageModal from '../../components/modal/composite/select-image'
import useAuthEffect from '../../hooks/useAuthEffect'
import SupportCoachTipsCrud from '../../components/crud/support-coach-tips-crud'
import SupportTrackerTipsCrud from '../../components/crud/support-tracker-tips-crud'
import PracticesCrud from '../../components/crud/practices-crud'
import ArticlesCrud from '../../components/crud/articles-crud'
import SupportCoachTipsAPI, { SupportCoachTip } from '../../api/supportCoachTips'
import { sortBy } from 'lodash'
import SupportTrackerTipsAPI, { SupportTrackerTip } from '../../api/supportTrackerTips'
import SupportLessonResourcesCrud from '../../components/crud/support-lesson-resources-crud'
import SupportLessonResourcesAPI, { SupportLessonResource } from '../../api/supportLessonResources'
import TriggerableItemChecklistTriggersCrud from '../../components/crud/triggerable-item-checklist-triggers-crud'
import Loader from '../../components/loader'
import { AdminSupport, AdminSupportLessonResource, AdminSupportTrackerTip } from '../../api/schema'

export type SupportShowModalType = 'add_image' | 'add_article' | 'add_practice' | null

export default function SupportsShowPage({ supportId }: { supportId?: string } = {}) {
  const params = useParams()
  const [support, setSupport] = useState<AdminSupport | null>(null)
  const [errorFetchingSupport, setErrorFetchingSupport] = useState(false)
  const [modalType, setModalType] = useState<SupportShowModalType | null>(null)
  const id = supportId || params.supportId!

  async function resortCoachTips(
    coachTip: SupportCoachTip,
    position: number,
    newCoachTips: SupportCoachTip[]
  ) {
    setSupport({
      ...support!,
      coachTips: newCoachTips,
    })
    await SupportCoachTipsAPI.update(coachTip.id, { position })
  }

  async function resortTrackerTips(
    trackerTip: AdminSupportTrackerTip,
    position: number,
    newTrackerTips: AdminSupportTrackerTip[]
  ) {
    setSupport({
      ...support!,
      trackerTips: newTrackerTips,
    })
    await SupportTrackerTipsAPI.update(trackerTip.id, { position })
  }

  async function resortLessonResources(
    supportLessonResource: SupportLessonResource,
    position: number,
    newSupportLessonResources: AdminSupportLessonResource[]
  ) {
    setSupport({
      ...support!,
      supportLessonResources: newSupportLessonResources,
    })
    await SupportLessonResourcesAPI.update(supportLessonResource.id, { position })
  }

  // hack until we can get proper drag and drop tests working
  if (process.env.REACT_APP_PSYCHIC_ENV === 'test') {
    // @ts-ignore
    window.__testOnlySimulateCoachTipDragAndDrop = resortCoachTips
    // @ts-ignore
    window.__testOnlySimulateTrackerTipDragAndDrop = resortTrackerTips
    // @ts-ignore
    window.__testOnlySimulateLessonResourceDragAndDrop = resortLessonResources
  }

  async function fetchSupport(id: string) {
    try {
      const support = await SupportsAPI.show(id)
      setSupport(support)
    } catch (error) {
      setErrorFetchingSupport(true)
    }
  }

  useAuthEffect(() => {
    // eslint-disable-next-line
    fetchSupport(id)
  })

  if (errorFetchingSupport) return <div>There was an error fetching the support you requested.</div>
  if (!support) return <Loader />

  const coachTips = sortBy(support.coachTips, 'position')
  const trackerTips = sortBy(support.trackerTips, 'position')
  const supportLessonResources = sortBy(support.supportLessonResources, 'position')

  return (
    <div className="page">
      <Breadcrumbs>
        <Anchor component={Link} to={routes.app.supports.index}>
          supports
        </Anchor>
        <Anchor component={Link} to={routes.app.supports.show(support.id)}>
          {support.name}
        </Anchor>
      </Breadcrumbs>

      <TitleWithButton title={support.name}>
        <Button
          className="outlined blue"
          style={{ marginRight: 10 }}
          component={Link}
          to={routes.app.supports.edit(support.id)}
        >
          edit
        </Button>
      </TitleWithButton>

      <AddOrEditImage
        image={support.image}
        onAddOrEditClick={async () => {
          setModalType('add_image')
        }}
        onRemoveClick={async () => {
          try {
            await SupportsAPI.update(support.id, { imageId: null })
            await fetchSupport(support.id)
          } catch (err) {
            // TODO: handle this
          }
        }}
      />

      <Paper mt={20} p="md">
        <Table>
          <tbody>
            <tr>
              <td>support focus nutrient:</td>
              <td>{support?.supportFocusNutrient}</td>
            </tr>
            <tr>
              <td>meal planner modifier:</td>
              <td>{support?.mealPlannerModifier}</td>
            </tr>
            <tr>
              <td>default support:</td>
              <td>{support?.defaultSupport ? 'true' : 'false'}</td>
            </tr>
          </tbody>
        </Table>
      </Paper>

      <FormLocalizationSection
        apiModule={SupportsAPI}
        existingLocalizedTexts={support.localizedTexts}
        localizableId={support.id}
        onChange={async newLocale => {
          await fetchSupport(support.id)
        }}
      />

      <SupportCoachTipsCrud coachTips={coachTips} supportId={support.id} onSort={resortCoachTips} />

      <SupportTrackerTipsCrud trackerTips={trackerTips} supportId={support.id} onSort={resortTrackerTips} />

      <ArticlesCrud
        articles={support.articles}
        articleShowRouteCB={article => routes.app.supports.articles.show(support.id, article.id)}
        onSelectArticle={async article => {
          try {
            await SupportsAPI.attachArticle(support.id, article.id)
            await fetchSupport(support.id)
          } catch (err) {
            // TODO: handle this
          }
        }}
      />

      <SupportLessonResourcesCrud
        supportLessonResources={supportLessonResources}
        onSelectLessonResource={async lessonResource => {
          try {
            await SupportsAPI.attachLessonResource(support.id, lessonResource.id)
            await fetchSupport(support.id)
          } catch (err) {
            // TODO: handle this
          }
        }}
        onSort={resortLessonResources}
      />

      <PracticesCrud
        practices={support.practices}
        practiceShowRouteCB={practice => routes.app.supports.practices.show(support.id, practice.id)}
        onSelectPractice={async practice => {
          try {
            await SupportsAPI.attachPractice(support.id, practice.id)
            await fetchSupport(support.id)
          } catch (err) {
            // TODO: handle this
          }
        }}
      />

      <TriggerableItemChecklistTriggersCrud
        triggerableItemChecklistTriggers={support.triggerableItemChecklistTriggers}
        triggerableItemChecklistTriggerShowRoute={triggerableItemChecklistTrigger =>
          routes.app.supports.triggerableItemChecklistTriggers.show(triggerableItemChecklistTrigger.id)
        }
        onSelectChecklistTrigger={async checklistTrigger => {
          try {
            await SupportsAPI.attachChecklistTrigger(support.id, checklistTrigger.id)
            await fetchSupport(support.id)
          } catch (err) {
            // TODO: handle this
          }
        }}
      />

      <SelectImageModal
        opened={modalType === 'add_image'}
        onCancel={() => setModalType(null)}
        onSelectImage={async image => {
          setModalType(null)
          try {
            await SupportsAPI.update(support.id, { imageId: image.id })
            await fetchSupport(support.id)
          } catch (err) {
            // TODO: handle this
          }
        }}
      />
    </div>
  )
}

import routes from '../config/routes'
import { api } from './common'

export default class SupportEventVerbsAPI {
  public static async index({ query }: { query?: string } = {}) {
    const res = await api.get(routes.api.supports.eventModifiers.index, { params: { query } })
    return res.data as SupportEventModifier[]
  }
}

export interface SupportEventModifier {
  id: string
  modifier: SupportEventModifiersEnum
}

export type SupportEventModifiersEnum = 'moderately' | 'greatly' | 'breakfast' | 'lunch' | 'dinner' | 'snack'

import routes from '../config/routes'
import { Article } from './articles'
import { api } from './common'
import { LessonResource } from './lessonResources'
import { LocalizedText, LocalizedTextParams } from './localizedTexts'
import { Video } from './videos'

export default class LessonsAPI {
  public static async create(attrs: LessonParams) {
    return await api.post(routes.api.lessons.create, { lesson: attrs })
  }

  public static async update(id: string, attrs: Partial<LessonParams>) {
    return await api.patch(routes.api.lessons.update(id), { lesson: attrs })
  }

  public static async delete(id: string) {
    return await api.delete(routes.api.lessons.delete(id))
  }

  public static async index({ query }: { query?: string } = {}) {
    const res = await api.get(routes.api.lessons.index, { params: { query } })
    return res.data as Lesson[]
  }

  public static async show(id: string) {
    const res = await api.get(routes.api.lessons.show(id))
    return res.data as Lesson
  }

  public static async createLocalizedText(lessonId: string, params: LocalizedTextParams) {
    return await api.post(routes.api.lessons.localizedTexts.create(lessonId), { localizedText: params })
  }

  public static async attachLessonResource(lessonId: string, lessonResourceId: string) {
    return await api.post(routes.api.lessons.lessonResources.create(lessonId), { lessonResourceId })
  }

  public static async detachLessonResource(lessonId: string, articleId: string) {
    return await api.delete(routes.api.lessons.lessonResources.delete(lessonId, articleId))
  }

  public static async attachArticle(lessonId: string, articleId: string) {
    return await api.post(routes.api.lessons.articles.create(lessonId), { articleId })
  }

  public static async detachArticle(lessonId: string, articleId: string) {
    return await api.delete(routes.api.lessons.articles.delete(lessonId, articleId))
  }
}

export interface Lesson {
  id: string
  name: string
  localizedTexts: LocalizedText[]
  lessonResources: LessonResource[]
  articles: Article[]
  videoId: string | null
  video: Video | null
}

export interface LessonParams {
  name: string
  videoId?: string | null
}

import { Anchor, Paper, SimpleGrid, Table, Text, Title } from '@mantine/core'
import { useState } from 'react'
import routes from '../../config/routes'
import PracticesAPI, { AdminPractice } from '../../api/practices'
import { NavLink } from 'react-router-dom'
import { sortBy } from 'lodash'
import useAuthEffect from '../../hooks/useAuthEffect'
import Loader from '../../components/loader'

export default function PracticesIndexPage() {
  const [practices, setPractices] = useState<AdminPractice[]>([])
  const [errorFetchingPractice, setErrorFetchingPractice] = useState(false)
  const [doneFetching, setDoneFetching] = useState(false)

  useAuthEffect(() => {
    async function fetchPractices() {
      try {
        const practices = await PracticesAPI.index()
        setPractices(sortBy(practices, 'name'))
        setDoneFetching(true)
      } catch (error) {
        setErrorFetchingPractice(true)
      }
    }

    // eslint-disable-next-line
    fetchPractices()
  })

  return (
    <div className="page">
      <SimpleGrid cols={2}>
        <Title style={{ display: 'inline-block' }}>Practices</Title>
      </SimpleGrid>

      {practices.length > 0 && (
        <Paper mt={20} p="md">
          <Table>
            <thead>
              <tr>
                <td>Name</td>
              </tr>
            </thead>
            <tbody>
              {practices.map((practice, index) => (
                <tr key={index}>
                  <td>
                    <Anchor component={NavLink} to={routes.app.practices.show(practice.id)}>
                      {practice.name}
                    </Anchor>
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
        </Paper>
      )}

      {!doneFetching && <Loader />}

      {doneFetching && practices.length === 0 && (
        <Text style={{ textAlign: 'center' }}>
          <Paper m="lg" p="md">
            No practices have been created yet.
          </Paper>
        </Text>
      )}
    </div>
  )
}

import React, { useEffect, useState } from 'react'
import { io } from 'socket.io-client'
import routes from '../config/routes'
import OpsApi from '../api/ops'
import { useAppSelector } from '../hooks'
import { Button } from '@mantine/core'

export default function OpsPage() {
  const authToken = useAppSelector(state => state.app.authToken)
  const wsConnectionSucceeded = useAppSelector(state => state.app.adminWsConnectionSucceeded)
  const [receivedOpsPing, setReceivedOpsPing] = useState(false)
  const [receivedTrackerOpsPing, setReceivedTrackerOpsPing] = useState(false)

  useEffect(() => {
    if (!authToken) return
    const socket = io(`${routes.baseWsUrl}/admin`, {
      auth: {
        token: authToken,
      },
      withCredentials: true,
      transports: ['websocket'],
    })

    socket.on('/ops/ping', () => {
      setReceivedOpsPing(true)
    })

    socket.on('/ops/tracker/ping', () => {
      setReceivedTrackerOpsPing(true)
    })

    socket.on('connect', async () => {
      await OpsApi.status()
      console.log('fetched! awaiting socket responses...')
    })
  }, [authToken])

  return (
    <div className="page" id="ops">
      <div>
        <label>Central admin ws channel</label>
        <input type="checkbox" checked={wsConnectionSucceeded} />
      </div>

      <div>
        <label>Central background jobs working</label>
        <input type="checkbox" checked={receivedOpsPing} />
      </div>
      <div>
        <label>Central websockets working</label>
        <input type="checkbox" checked={receivedOpsPing} />
      </div>

      <div>
        <label>Tracker background jobs working</label>
        <input type="checkbox" checked={receivedTrackerOpsPing} />
      </div>

      <Button
        onClick={async () => {
          await OpsApi.triggerBackgroundError()
        }}
      >
        trigger background error
      </Button>
    </div>
  )
}

import { Button, Paper } from '@mantine/core'
import { HumanCoach } from '../api/humanCoaches'
import HumanCoachCard from './card/human-coach-card'

export default function AddOrEditHumanCoach({
  humanCoach,
  onAddOrEditClick,
  onRemoveClick,
}: {
  humanCoach: HumanCoach | null
  onAddOrEditClick?: () => Promise<void>
  onRemoveClick?: () => Promise<void>
}) {
  const addOrEditHumanCoachButton = (
    <Button
      style={{ marginRight: 10 }}
      variant="outline"
      fullWidth
      onClick={async () => {
        await onAddOrEditClick?.()
      }}
    >
      {humanCoach ? 'Edit' : 'Add'} human coach
    </Button>
  )

  return humanCoach ? (
    <div style={{ textAlign: 'center' }}>
      <HumanCoachCard humanCoach={humanCoach} width={300} height={300}>
        <>
          {addOrEditHumanCoachButton}
          <Button
            fullWidth
            mt={10}
            color="red"
            variant="outline"
            onClick={async () => {
              await onRemoveClick?.()
            }}
          >
            remove human coach
          </Button>
        </>
      </HumanCoachCard>
    </div>
  ) : (
    <Paper pt={100} pb={100} pl={150} pr={150} style={{ textAlign: 'center' }}>
      <p>No human coach set</p>
      {addOrEditHumanCoachButton}
    </Paper>
  )
}

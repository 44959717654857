import routes from '../config/routes'
import { Article } from './articles'
import { api } from './common'
import { Image } from './images'
import { LocalizedText, LocalizedTextParams } from './localizedTexts'
import { AdminPractice } from './practices'
import { AdminSupport } from './schema'
import { SupportCoachTip } from './supportCoachTips'
import { SupportLessonResource } from './supportLessonResources'
import { SupportTrackerTip } from './supportTrackerTips'
import { TriggerableItemChecklistTrigger } from './supportTriggerableItemChecklistTriggers'

export default class SupportsAPI {
  public static async create(attrs: SupportParams) {
    return await api.post(routes.api.supports.create, { support: attrs })
  }

  public static async update(id: string, attrs: Partial<SupportParams>) {
    return await api.patch(routes.api.supports.update(id), { support: attrs })
  }

  public static async index({ query }: { query?: string } = {}) {
    const res = await api.get(routes.api.supports.index, { params: { query } })
    return res.data as AdminSupport[]
  }

  public static async show(id: string) {
    const res = await api.get(routes.api.supports.show(id))
    return res.data as AdminSupport
  }

  public static async createLocalizedText(supportId: string, params: LocalizedTextParams) {
    return await api.post(routes.api.supports.localizedTexts.create(supportId), { localizedText: params })
  }

  public static async attachArticle(supportId: string, articleId: string) {
    return await api.post(routes.api.supports.articles.create(supportId), { articleId })
  }

  public static async detachArticle(supportId: string, articleId: string) {
    return await api.delete(routes.api.supports.articles.delete(supportId, articleId))
  }

  public static async attachPractice(supportId: string, practiceId: string) {
    return await api.post(routes.api.supports.supportPractices.create(supportId, practiceId))
  }

  public static async detachPractice(supportId: string, practiceId: string) {
    return await api.delete(routes.api.supports.supportPractices.delete(supportId, practiceId))
  }

  public static async attachLessonResource(supportId: string, lessonResourceId: string) {
    return await api.post(routes.api.supports.lessonResources.create(supportId), { lessonResourceId })
  }

  public static async detachLessonResource(supportId: string, articleId: string) {
    return await api.delete(routes.api.supports.lessonResources.delete(supportId, articleId))
  }

  public static async attachChecklistTrigger(supportId: string, checklistTriggerId: string) {
    return await api.post(routes.api.supports.triggerableItemChecklistTriggers.create(supportId), {
      supportChecklistTriggerId: checklistTriggerId,
    })
  }

  public static async detachChecklistTrigger(triggerableItemChecklistTriggerId: string) {
    return await api.delete(
      routes.api.supports.triggerableItemChecklistTriggers.delete(triggerableItemChecklistTriggerId)
    )
  }
}

export interface Support {
  id: string
  name: string
  defaultSupport: boolean
  localizedTexts: LocalizedText[]
  coachTips: SupportCoachTip[]
  trackerTips: SupportTrackerTip[]
  mealPlannerModifier: MealPlannerModifiersEnum | null
  supportFocusNutrient: SupportFocusNutrientsEnum | null
  image: Image | null
  imageId: string | null
  articles: Article[]
  practices: AdminPractice[]
  supportLessonResources: SupportLessonResource[]
  triggerableItemChecklistTriggers: TriggerableItemChecklistTrigger[]
}

export interface SupportParams {
  name: string
  mealPlannerModifier?: MealPlannerModifiersEnum | null
  supportFocusNutrient?: SupportFocusNutrientsEnum | null
  imageId?: string | null
  defaultSupport?: boolean
}

export const SUPPORT_FOCUS_NUTRIENTS = [
  'added_sugar',
  'calcium',
  'calories',
  'carbs',
  'fat',
  'fiber',
  'iron',
  'protein',
  'saturated_fat',
  'sodium',
  'sugar',
]

export type SupportFocusNutrientsEnum =
  | 'added_sugar'
  | 'calcium'
  | 'calories'
  | 'carbs'
  | 'fat'
  | 'fiber'
  | 'iron'
  | 'potassium'
  | 'protein'
  | 'saturated_fat'
  | 'sodium'
  | 'sugar'
  | 'vitamin_d'

export const MEAL_PLANNER_MODIFIERS = [
  'high_protein',
  'include_fruit',
  'include_vegetable',
  'low_saturated_fat',
  'low_sodium',
  'low_sugar',
  'no_added_sugar',
  'whole_grain',
]

export type MealPlannerModifiersEnum =
  | 'high_protein'
  | 'include_fruit'
  | 'include_vegetable'
  | 'low_saturated_fat'
  | 'low_sodium'
  | 'low_sugar'
  | 'no_added_sugar'
  | 'whole_grain'

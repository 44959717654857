import React, { useEffect, useState } from 'react'
import { io } from 'socket.io-client'
import { InteractionType } from '@azure/msal-browser'
import { AuthenticatedTemplate, MsalAuthenticationTemplate } from '@azure/msal-react'
import './app/styles/app.scss'
import { Navigate, Route, Routes } from 'react-router-dom'
import routes from './app/config/routes'
import { useAppDispatch, useAppSelector } from './app/hooks'
import AdminDashboardPage from './app/pages/dash'
import AppShell from './app/components/layout/app-shell'
import JourneysIndexPage from './app/pages/journeys'
import JourneysNewPage from './app/pages/journeys/new'
import JourneysShowPage from './app/pages/journeys/show'
import JourneysEditPage from './app/pages/journeys/edit'
import SupportsIndexPage from './app/pages/supports'
import SupportsNewPage from './app/pages/supports/new'
import SupportsShowPage from './app/pages/supports/show'
import SupportsEditPage from './app/pages/supports/edit'
import PhasesIndexPage from './app/pages/phases'
import PhasesNewPage from './app/pages/phases/new'
import PhasesShowPage from './app/pages/phases/show'
import PhasesEditPage from './app/pages/phases/edit'
import PartsIndexPage from './app/pages/parts'
import PartsNewPage from './app/pages/parts/new'
import PartsShowPage from './app/pages/parts/show'
import PartsEditPage from './app/pages/parts/edit'
import PracticesIndexPage from './app/pages/practices'
import PracticesShowPage from './app/pages/practices/show'
import PracticesEditPage from './app/pages/practices/edit'
import LessonsIndexPage from './app/pages/lessons'
import LessonsNewPage from './app/pages/lessons/new'
import LessonsShowPage from './app/pages/lessons/show'
import LessonsEditPage from './app/pages/lessons/edit'
import LessonResourcesIndexPage from './app/pages/lesson-resources'
import LessonResourcesNewPage from './app/pages/lesson-resources/new'
import LessonResourcesShowPage from './app/pages/lesson-resources/show'
import LessonResourcesEditPage from './app/pages/lesson-resources/edit'
import LessonArticlesShowPage from './app/pages/lessons/articles/show'
import ImagesIndexPage from './app/pages/images'
import ImagesNewPage from './app/pages/images/new'
import ImagesShowPage from './app/pages/images/show'
import ImagesEditPage from './app/pages/images/edit'
import VideosIndexPage from './app/pages/videos'
import VideosNewPage from './app/pages/videos/new'
import VideosShowPage from './app/pages/videos/show'
import VideosEditPage from './app/pages/videos/edit'
import JourneyPhasesShowPage from './app/pages/journeys/phases/show'
import PhasePartsShowPage from './app/pages/phases/parts/show'
import PartLessonsShowPage from './app/pages/parts/lessons/show'
import DummyAuth from './app/pages/dummy-auth'
import OpsPage from './app/pages/ops'
import SupportsCoachTipsNewPage from './app/pages/supports/coach-tips/new'
import SupportCoachTipsShowPage from './app/pages/supports/coach-tips/show'
import SupportCoachTipsEditPage from './app/pages/supports/coach-tips/edit'
import SupportsTrackerTipsNewPage from './app/pages/supports/tracker-tips/new'
import SupportTrackerTipsShowPage from './app/pages/supports/tracker-tips/show'
import SupportTrackerTipsEditPage from './app/pages/supports/tracker-tips/edit'
import SupportArticlesShowPage from './app/pages/supports/articles/show'
import HumanCoachesIndexPage from './app/pages/human-coaches'
import HumanCoachesShowPage from './app/pages/human-coaches/show'
import HumanCoachesEditPage from './app/pages/human-coaches/edit'
import HumanCoachesNewPage from './app/pages/human-coaches/new'
import LessonLessonResourcesShowPage from './app/pages/lessons/lesson-resources/show'
import ArticlesIndexPage from './app/pages/articles'
import ArticlesNewPage from './app/pages/articles/new'
import ArticlesShowPage from './app/pages/articles/show'
import ArticlesEditPage from './app/pages/articles/edit'
import appAuthBindings from './app/helpers/auth/appAuthBindings'
import SupportPracticesShowPage from './app/pages/supports/practices/show'
import SupportLessonResourcesShowPage from './app/pages/supports/lesson-resources/show'
import JourneySupportsShowPage from './app/pages/journeys/supports/show'
import EventTriggersIndexPage from './app/pages/event-triggers'
import EventTriggersNewPage from './app/pages/event-triggers/new'
import EventTriggersShowPage from './app/pages/event-triggers/show'
import EventTriggersEditPage from './app/pages/event-triggers/edit'
import ChecklistTriggersIndexPage from './app/pages/checklist-triggers'
import ChecklistTriggersNewPage from './app/pages/checklist-triggers/new'
import ChecklistTriggersShowPage from './app/pages/checklist-triggers/show'
import ChecklistTriggersEditPage from './app/pages/checklist-triggers/edit'
import ChromeExtension from './chrome-extension'
import useQuery from './app/hooks/useQuery'
import SystemIndexPage from './app/pages/system'
import SystemPortContentPage from './app/pages/system/port-content'
import SystemPortContentInitiatedPage from './app/pages/system/port-content/initiated'
import SystemPortContentInsufficientPermissionPage from './app/pages/system/port-content/insufficient-permission'
import ChecklistTriggerEventTriggersShowPage from './app/pages/checklist-triggers/event-triggers/show'
import ChecklistTriggerEventTriggersEditPage from './app/pages/checklist-triggers/event-triggers/edit'
import SupportTriggerableItemChecklistTriggersShowPage from './app/pages/supports/triggerable-item-checklist-triggers/show'
import { setAdminWsConnectionSucceeded } from './app/stores/app'

function App() {
  const query = useQuery()
  const dispatch = useAppDispatch()
  const authToken = useAppSelector(state => state.app.authToken)
  const chromeExtensionActive = useAppSelector(state => state.chromeExtension.active)
  const [authError, setAuthError] = useState(false)

  if (chromeExtensionActive || query.get('chromeExtension')) return <ChromeExtension />

  useEffect(() => {
    appAuthBindings(dispatch, {
      onAuthError: () => {
        setAuthError(true)
      },
    })
  }, [])

  useEffect(() => {
    if (!authToken) return
    const socket = io(`${routes.baseWsUrl}/admin`, {
      auth: {
        token: authToken,
      },
      withCredentials: true,
      transports: ['websocket'],
    })

    socket.on('/ops/connection-succeeded', () => {
      dispatch(setAdminWsConnectionSucceeded(true))
    })
  }, [authToken])

  if (authError) return <div>An unknown auth error occured.</div>

  return (
    <div id="app">
      <CentralAuthentication>
        {process.env.REACT_APP_PSYCHIC_ENV === 'test' && !authToken ? <UnauthedApp /> : <AuthedApp />}
      </CentralAuthentication>
    </div>
  )
}

function CentralAuthentication({ children }: { children: React.ReactNode }) {
  return process.env.REACT_APP_PSYCHIC_ENV === 'test' ? (
    <>{children}</>
  ) : (
    <MsalAuthenticationTemplate
      authenticationRequest={{ scopes: ['User.Read'] }}
      interactionType={InteractionType.Redirect}
    >
      <AuthenticatedTemplate>{children}</AuthenticatedTemplate>
    </MsalAuthenticationTemplate>
  )
}

function AuthedApp() {
  return (
    <AppShell>
      <Routes>
        <Route path="/ops" element={<OpsPage />} />
        <Route path={routes.app.dummyAuth} element={<Navigate to={routes.app.home} />} />
        <Route path={routes.app.home} element={<AdminDashboardPage />} />

        <Route path={routes.app.journeys.index} element={<JourneysIndexPage />} />
        <Route path={routes.app.journeys.new} element={<JourneysNewPage />} />
        <Route path={routes.app.journeys.show(':id')} element={<JourneysShowPage />} />
        <Route path={routes.app.journeys.edit(':id')} element={<JourneysEditPage />} />
        <Route
          path={routes.app.journeys.phases.show(':id', ':phaseId')}
          element={<JourneyPhasesShowPage />}
        />
        <Route
          path={routes.app.journeys.supports.show(':id', ':supportId')}
          element={<JourneySupportsShowPage />}
        />

        <Route
          path={routes.app.supports.coachTips.new(':supportId')}
          element={<SupportsCoachTipsNewPage />}
        />
        <Route
          path={routes.app.supports.coachTips.edit(':supportId', ':id')}
          element={<SupportCoachTipsEditPage />}
        />
        <Route
          path={routes.app.supports.coachTips.show(':supportId', ':id')}
          element={<SupportCoachTipsShowPage />}
        />
        <Route
          path={routes.app.supports.trackerTips.new(':supportId')}
          element={<SupportsTrackerTipsNewPage />}
        />
        <Route
          path={routes.app.supports.trackerTips.edit(':supportId', ':id')}
          element={<SupportTrackerTipsEditPage />}
        />
        <Route
          path={routes.app.supports.trackerTips.show(':supportId', ':id')}
          element={<SupportTrackerTipsShowPage />}
        />
        <Route path={routes.app.supports.index} element={<SupportsIndexPage />} />
        <Route path={routes.app.supports.new} element={<SupportsNewPage />} />
        <Route path={routes.app.supports.show(':supportId')} element={<SupportsShowPage />} />
        <Route path={routes.app.supports.edit(':supportId')} element={<SupportsEditPage />} />
        <Route
          path={routes.app.supports.articles.show(':id', ':articleId')}
          element={<SupportArticlesShowPage />}
        />
        <Route
          path={routes.app.supports.practices.show(':id', ':practiceId')}
          element={<SupportPracticesShowPage />}
        />
        <Route
          path={routes.app.supports.lessonResources.show(':id', ':lessonResourceId')}
          element={<SupportLessonResourcesShowPage />}
        />

        <Route path={routes.app.phases.index} element={<PhasesIndexPage />} />
        <Route path={routes.app.phases.new} element={<PhasesNewPage />} />
        <Route path={routes.app.phases.show(':id')} element={<PhasesShowPage />} />
        <Route path={routes.app.phases.edit(':id')} element={<PhasesEditPage />} />
        <Route path={routes.app.phases.parts.show(':id', ':partId')} element={<PhasePartsShowPage />} />

        <Route path={routes.app.parts.index} element={<PartsIndexPage />} />
        <Route path={routes.app.parts.new} element={<PartsNewPage />} />
        <Route path={routes.app.parts.show(':id')} element={<PartsShowPage />} />
        <Route path={routes.app.parts.edit(':id')} element={<PartsEditPage />} />
        <Route path={routes.app.parts.lessons.show(':id', ':lessonId')} element={<PartLessonsShowPage />} />

        <Route path={routes.app.humanCoaches.index} element={<HumanCoachesIndexPage />} />
        <Route path={routes.app.humanCoaches.new} element={<HumanCoachesNewPage />} />
        <Route path={routes.app.humanCoaches.show(':id')} element={<HumanCoachesShowPage />} />
        <Route path={routes.app.humanCoaches.edit(':id')} element={<HumanCoachesEditPage />} />

        <Route path={routes.app.practices.index} element={<PracticesIndexPage />} />
        <Route path={routes.app.practices.show(':id')} element={<PracticesShowPage />} />
        <Route path={routes.app.practices.edit(':id')} element={<PracticesEditPage />} />

        <Route path={routes.app.lessons.index} element={<LessonsIndexPage />} />
        <Route path={routes.app.lessons.new} element={<LessonsNewPage />} />
        <Route path={routes.app.lessons.show(':id')} element={<LessonsShowPage />} />
        <Route path={routes.app.lessons.edit(':id')} element={<LessonsEditPage />} />
        <Route
          path={routes.app.lessons.lessonResources.show(':id', ':lessonResourceId')}
          element={<LessonLessonResourcesShowPage />}
        />
        <Route
          path={routes.app.lessons.articles.show(':id', ':articleId')}
          element={<LessonArticlesShowPage />}
        />

        <Route path={routes.app.lessonResources.index} element={<LessonResourcesIndexPage />} />
        <Route path={routes.app.lessonResources.new} element={<LessonResourcesNewPage />} />
        <Route path={routes.app.lessonResources.show(':id')} element={<LessonResourcesShowPage />} />
        <Route path={routes.app.lessonResources.edit(':id')} element={<LessonResourcesEditPage />} />

        <Route path={routes.app.articles.index} element={<ArticlesIndexPage />} />
        <Route path={routes.app.articles.new} element={<ArticlesNewPage />} />
        <Route path={routes.app.articles.show(':id')} element={<ArticlesShowPage />} />
        <Route path={routes.app.articles.edit(':id')} element={<ArticlesEditPage />} />

        <Route path={routes.app.eventTriggers.index} element={<EventTriggersIndexPage />} />
        <Route path={routes.app.eventTriggers.new} element={<EventTriggersNewPage />} />
        <Route path={routes.app.eventTriggers.show(':id')} element={<EventTriggersShowPage />} />
        <Route path={routes.app.eventTriggers.edit(':id')} element={<EventTriggersEditPage />} />

        <Route
          path={routes.app.checklistTriggers.eventTriggers.show(':id')}
          element={<ChecklistTriggerEventTriggersShowPage />}
        />
        <Route
          path={routes.app.checklistTriggers.eventTriggers.edit(':id')}
          element={<ChecklistTriggerEventTriggersEditPage />}
        />
        <Route path={routes.app.checklistTriggers.index} element={<ChecklistTriggersIndexPage />} />
        <Route path={routes.app.checklistTriggers.new} element={<ChecklistTriggersNewPage />} />
        <Route path={routes.app.checklistTriggers.show(':id')} element={<ChecklistTriggersShowPage />} />
        <Route path={routes.app.checklistTriggers.edit(':id')} element={<ChecklistTriggersEditPage />} />

        <Route
          path={routes.app.supports.triggerableItemChecklistTriggers.show(':id')}
          element={<SupportTriggerableItemChecklistTriggersShowPage />}
        />

        <Route path={routes.app.images.index} element={<ImagesIndexPage />} />
        <Route path={routes.app.images.new} element={<ImagesNewPage />} />
        <Route path={routes.app.images.show(':id')} element={<ImagesShowPage />} />
        <Route path={routes.app.images.edit(':id')} element={<ImagesEditPage />} />

        <Route path={routes.app.videos.index} element={<VideosIndexPage />} />
        <Route path={routes.app.videos.new} element={<VideosNewPage />} />
        <Route path={routes.app.videos.show(':id')} element={<VideosShowPage />} />
        <Route path={routes.app.videos.edit(':id')} element={<VideosEditPage />} />

        <Route path={routes.app.system.index} element={<SystemIndexPage />} />
        <Route path={routes.app.system.portContent} element={<SystemPortContentPage />} />
        <Route path={routes.app.system.portContentInitiated} element={<SystemPortContentInitiatedPage />} />
        <Route
          path={routes.app.system.portContentInsufficientPermission}
          element={<SystemPortContentInsufficientPermissionPage />}
        />
      </Routes>
    </AppShell>
  )
}

function UnauthedApp() {
  return (
    <Routes>
      <Route path={routes.app.home} element={<Navigate to={routes.app.dummyAuth} />} />
      {process.env.REACT_APP_PSYCHIC_ENV === 'test' && (
        <Route path={routes.app.dummyAuth} element={<DummyAuth />} />
      )}
    </Routes>
  )
}

export default App

const baseUrl = () => {
  if (process.env.REACT_APP_API_HOST) return process.env.REACT_APP_API_HOST
  switch (process.env.REACT_APP_PSYCHIC_ENV) {
    case 'production':
      return 'https://admin.central-dev.wellos.com'
    case 'test':
      return 'http://localhost:7778'
    default:
      return 'http://localhost:7777'
  }
}

const baseWsUrl = () => {
  return baseUrl()
    .replace(/^http:/, 'ws:')
    .replace(/^https:/, 'wss:')
}

const routes = {
  baseURL: baseUrl(),
  baseWsUrl: baseWsUrl(),

  app: {
    home: '/',
    login: '/login',
    logout: '/logout',
    dummyAuth: '/dummy-auth',
    journeys: {
      index: '/journeys',
      new: '/journeys/new',
      show: (id: string) => `/journeys/${id}`,
      edit: (id: string) => `/journeys/${id}/edit`,
      phases: {
        show: (journeyId: string, phaseId: string) => `/journeys/${journeyId}/phases/${phaseId}`,
      },
      supports: {
        show: (journeyId: string, supportId: string) => `/journeys/${journeyId}/supports/${supportId}`,
      },
    },
    supports: {
      index: '/supports',
      new: '/supports/new',
      show: (id: string) => `/supports/${id}`,
      edit: (id: string) => `/supports/${id}/edit`,
      coachTips: {
        new: (id: string) => `/supports/${id}/coach-tips/new`,
        show: (supportId: string, id: string) => `/supports/${supportId}/coach-tips/${id}/show`,
        edit: (supportId: string, id: string) => `/supports/${supportId}/coach-tips/${id}/edit`,
      },
      practices: {
        new: (id: string) => `/supports/${id}/practices/new`,
        show: (supportId: string, id: string) => `/supports/${supportId}/practices/${id}/show`,
        edit: (supportId: string, id: string) => `/supports/${supportId}/practices/${id}/edit`,
      },
      trackerTips: {
        new: (id: string) => `/supports/${id}/tracker-tips/new`,
        show: (supportId: string, id: string) => `/supports/${supportId}/tracker-tips/${id}/show`,
        edit: (supportId: string, id: string) => `/supports/${supportId}/tracker-tips/${id}/edit`,
      },
      articles: {
        show: (supportId: string, articleId: string) => `/supports/${supportId}/articles/${articleId}`,
      },
      lessonResources: {
        show: (supportId: string, lessonResourceId: string) =>
          `/supports/${supportId}/lesson-resources/${lessonResourceId}`,
      },
      triggerableItemChecklistTriggers: {
        show: (triggerableItemChecklistTriggerId: string) =>
          `/supports/checklist-triggers/${triggerableItemChecklistTriggerId}`,
      },
    },
    phases: {
      index: '/phases',
      new: '/phases/new',
      show: (id: string) => `/phases/${id}`,
      edit: (id: string) => `/phases/${id}/edit`,
      parts: {
        show: (phaseId: string, partId: string) => `/phases/${phaseId}/parts/${partId}`,
      },
    },
    parts: {
      index: '/parts',
      new: '/parts/new',
      show: (id: string) => `/parts/${id}`,
      edit: (id: string) => `/parts/${id}/edit`,
      practices: {
        show: (partId: string, practiceId: string) => `/parts/${partId}/practices/${practiceId}`,
      },
      lessons: {
        show: (partId: string, lessonId: string) => `/parts/${partId}/lessons/${lessonId}`,
      },
    },
    lessonResources: {
      index: '/lesson-resources',
      new: '/lesson-resources/new',
      show: (id: string) => `/lesson-resources/${id}`,
      edit: (id: string) => `/lesson-resources/${id}/edit`,
      practices: {
        show: (partId: string, practiceId: string) => `/lesson-resources/${partId}/practices/${practiceId}`,
      },
      lessons: {
        show: (partId: string, lessonId: string) => `/lesson-resources/${partId}/lessons/${lessonId}`,
      },
    },
    articles: {
      index: '/articles',
      new: '/articles/new',
      show: (id: string) => `/articles/${id}`,
      edit: (id: string) => `/articles/${id}/edit`,
    },
    eventTriggers: {
      index: '/event-triggers',
      new: '/event-triggers/new',
      show: (id: string) => `/event-triggers/${id}`,
      edit: (id: string) => `/event-triggers/${id}/edit`,
    },
    checklistTriggers: {
      index: '/checklist-triggers',
      new: '/checklist-triggers/new',
      show: (id: string) => `/checklist-triggers/${id}`,
      edit: (id: string) => `/checklist-triggers/${id}/edit`,
      eventTriggers: {
        show: (checklistTriggerEventTriggerId: string) =>
          `/checklist-triggers/event-triggers/${checklistTriggerEventTriggerId}`,
        edit: (checklistTriggerEventTriggerId: string) =>
          `/checklist-triggers/event-triggers/${checklistTriggerEventTriggerId}/edit`,
      },
    },
    humanCoaches: {
      index: '/human-coaches',
      new: '/human-coaches/new',
      show: (id: string) => `/human-coaches/${id}`,
      edit: (id: string) => `/human-coaches/${id}/edit`,
    },
    practices: {
      index: '/practices',
      new: '/practices/new',
      show: (id: string) => `/practices/${id}`,
      edit: (id: string) => `/practices/${id}/edit`,
    },
    lessons: {
      index: '/lessons',
      new: '/lessons/new',
      show: (id: string) => `/lessons/${id}`,
      edit: (id: string) => `/lessons/${id}/edit`,
      lessonResources: {
        show: (lessonId: string, lessonResourceId: string) =>
          `/lessons/${lessonId}/lesson-resources/${lessonResourceId}`,
      },
      articles: {
        show: (lessonId: string, articleId: string) => `/lessons/${lessonId}/articles/${articleId}`,
      },
    },
    images: {
      index: '/images',
      new: '/images/new',
      show: (id: string) => `/images/${id}`,
      edit: (id: string) => `/images/${id}/edit`,
    },
    videos: {
      index: '/videos',
      new: '/videos/new',
      show: (id: string) => `/videos/${id}`,
      edit: (id: string) => `/videos/${id}/edit`,
    },
    system: {
      index: '/system',
      portContent: '/system/port-content',
      portContentInitiated: '/system/port-content-initiated',
      portContentInsufficientPermission: '/system/port-content-insufficient-permission',
    },
    testOnly: {
      testIntercomId: (intercomId: string) => `/test/intercom-id/${intercomId}`,
      testIntercomConversationId: (intercomConversationId: string) =>
        `/test/intercom-conversation-id/${intercomConversationId}`,
    },
  },

  api: {
    me: '/admin/me',
    logout: '/admin/logout',
    journeys: {
      create: '/admin/journeys',
      index: '/admin/journeys',
      show: (id: string) => `/admin/journeys/${id}`,
      update: (id: string) => `/admin/journeys/${id}`,
      delete: (id: string) => `/admin/journeys/${id}`,
      journeyTrackerQuestions: {
        create: (journeyId: string) => `/admin/journeys/${journeyId}/journey-tracker-questions`,
        update: (id: string) => `/admin/journeys/journey-tracker-questions/${id}`,
        delete: (id: string) => `/admin/journeys/journey-tracker-questions/${id}`,
      },
      phases: {
        create: (journeyId: string) => `/admin/journeys/${journeyId}/phases`,
        delete: (journeyId: string, phaseId: string) => `/admin/journeys/${journeyId}/phases/${phaseId}`,
      },
      supports: {
        create: (journeyId: string) => `/admin/journeys/${journeyId}/supports`,
        update: (journeySupportId: string) => `/admin/journeys/journey-supports/${journeySupportId}`,
        delete: (journeyId: string, supportId: string) =>
          `/admin/journeys/${journeyId}/supports/${supportId}`,
      },
      localizedTexts: {
        create: (journeyId: string) => `/admin/journeys/${journeyId}/localized-texts`,
        delete: (journeyId: string, localizedTextId: string) =>
          `/admin/journeys/${journeyId}/localized-texts/${localizedTextId}`,
        update: (journeyId: string, localizedTextId: string) =>
          `/admin/journeys/${journeyId}/localized-texts/${localizedTextId}`,
      },
      onboardingQuestions: {
        create: (journeyId: string) => `/admin/journeys/${journeyId}/onboarding-questions`,
        delete: (journeyId: string, journeyOnboardingQuestionId: string) =>
          `/admin/journeys/${journeyId}/onboarding-questions/${journeyOnboardingQuestionId}`,
        update: (journeyOnboardingQuestionId: string) =>
          `/admin/journeys/onboarding-questions/${journeyOnboardingQuestionId}`,
      },
    },
    supports: {
      create: '/admin/supports',
      index: '/admin/supports',
      show: (id: string) => `/admin/supports/${id}`,
      update: (id: string) => `/admin/supports/${id}`,
      delete: (id: string) => `/admin/supports/${id}`,
      localizedTexts: {
        create: (supportId: string) => `/admin/supports/${supportId}/localized-texts`,
        delete: (supportId: string, localizedTextId: string) =>
          `/admin/supports/${supportId}/localized-texts/${localizedTextId}`,
        update: (supportId: string, localizedTextId: string) =>
          `/admin/supports/${supportId}/localized-texts/${localizedTextId}`,
      },
      coachTips: {
        create: (id: string) => `/admin/supports/${id}/coach-tips`,
        show: (supportId: string, id: string) => `/admin/supports/${supportId}/coach-tips/${id}`,
        update: (id: string) => `/admin/supports/coach-tips/${id}`,
        delete: (id: string) => `/admin/supports/coach-tips/${id}`,
        localizedTexts: {
          create: (id: string) => `/admin/supports/coach-tips/${id}/localized-texts`,
        },
      },
      trackerTips: {
        create: (id: string) => `/admin/supports/${id}/tracker-tips`,
        show: (supportId: string, id: string) => `/admin/supports/${supportId}/tracker-tips/${id}`,
        update: (id: string) => `/admin/supports/tracker-tips/${id}`,
        delete: (id: string) => `/admin/supports/tracker-tips/${id}`,
        localizedTexts: {
          create: (id: string) => `/admin/supports/tracker-tips/${id}/localized-texts`,
        },
      },
      supportPractices: {
        create: (supportId: string, id: string) => `/admin/supports/${supportId}/practices/${id}`,
        delete: (supportId: string, id: string) => `/admin/supports/${supportId}/practices/${id}`,
      },
      practices: {
        create: '/admin/supports/practices',
        index: '/admin/supports/practices',
        show: (id: string) => `/admin/supports/practices/${id}`,
        update: (id: string) => `/admin/supports/practices/${id}`,
        delete: (id: string) => `/admin/supports/practices/${id}`,
        onboardingQuestions: {
          create: (practiceId: string) => `/admin/supports/practices/${practiceId}/onboarding-questions`,
          delete: (practiceId: string, practiceOnboardingQuestionId: string) =>
            `/admin/supports/practices/onboarding-questions/${practiceOnboardingQuestionId}`,
          update: (practiceOnboardingQuestionId: string) =>
            `/admin/supports/practices/onboarding-questions/${practiceOnboardingQuestionId}`,
        },
        practiceTrackerQuestions: {
          create: (practiceId: string) =>
            `/admin/supports/practices/${practiceId}/practice-tracker-questions`,
          update: (id: string) => `/admin/supports/practices/practice-tracker-questions/${id}`,
          delete: (id: string) => `/admin/supports/practices/practice-tracker-questions/${id}`,
        },
        localizedTexts: {
          create: (id: string) => `/admin/supports/practices/${id}/localized-texts`,
        },
      },
      articles: {
        create: (supportId: string) => `/admin/supports/${supportId}/articles`,
        delete: (supportId: string, articleId: string) =>
          `/admin/supports/${supportId}/articles/${articleId}`,
      },
      lessonResources: {
        create: (supportId: string) => `/admin/supports/${supportId}/lesson-resources`,
        delete: (supportId: string, lessonResourceId: string) =>
          `/admin/supports/${supportId}/lesson-resources/${lessonResourceId}`,
        update: (supportLessonResourceId: string) =>
          `/admin/supports/lesson-resources/${supportLessonResourceId}`,
      },
      eventTriggers: {
        create: '/admin/supports/event-triggers',
        index: '/admin/supports/event-triggers',
        show: (id: string) => `/admin/supports/event-triggers/${id}`,
        update: (id: string) => `/admin/supports/event-triggers/${id}`,
        delete: (id: string) => `/admin/supports/event-triggers/${id}`,
      },
      triggerableItemChecklistTriggers: {
        create: (supportId: string) => `/admin/supports/${supportId}/triggerable-item-checklist-triggers`,
        show: (triggerableItemChecklistTriggerId: string) =>
          `/admin/supports/triggerable-item-checklist-triggers/${triggerableItemChecklistTriggerId}`,
        delete: (triggerableItemChecklistTriggerId: string) =>
          `/admin/supports/triggerable-item-checklist-triggers/${triggerableItemChecklistTriggerId}`,
      },
      checklistTriggers: {
        create: '/admin/supports/checklist-triggers',
        index: '/admin/supports/checklist-triggers',
        show: (id: string) => `/admin/supports/checklist-triggers/${id}`,
        update: (id: string) => `/admin/supports/checklist-triggers/${id}`,
        delete: (id: string) => `/admin/supports/checklist-triggers/${id}`,
        eventTriggers: {
          create: (checklistTriggerId: string) =>
            `/admin/supports/checklist-triggers/${checklistTriggerId}/event-triggers`,
          show: (checklistTriggerEventTriggerId: string) =>
            `/admin/supports/checklist-triggers/event-triggers/${checklistTriggerEventTriggerId}`,
          update: (checklistTriggerEventTriggerId: string) =>
            `/admin/supports/checklist-triggers/event-triggers/${checklistTriggerEventTriggerId}`,
          delete: (checklistTriggerEventTriggerId: string) =>
            `/admin/supports/checklist-triggers/event-triggers/${checklistTriggerEventTriggerId}`,
        },
      },
      eventNouns: {
        index: `/admin/supports/event-nouns`,
      },
      eventVerbs: {
        index: `/admin/supports/event-verbs`,
      },
      eventModifiers: {
        index: `/admin/supports/event-modifiers`,
      },
    },
    phases: {
      create: '/admin/phases',
      index: '/admin/phases',
      show: (id: string) => `/admin/phases/${id}`,
      update: (id: string) => `/admin/phases/${id}`,
      delete: (id: string) => `/admin/phases/${id}`,
      parts: {
        create: (phaseId: string) => `/admin/phases/${phaseId}/parts`,
        delete: (phaseId: string, partId: string) => `/admin/phases/${phaseId}/parts/${partId}`,
      },
      localizedTexts: {
        create: (phaseId: string) => `/admin/phases/${phaseId}/localized-texts`,
        delete: (phaseId: string, localizedTextId: string) =>
          `/admin/phases/${phaseId}/localized-texts/${localizedTextId}`,
        update: (phaseId: string, localizedTextId: string) =>
          `/admin/phases/${phaseId}/localized-texts/${localizedTextId}`,
      },
    },
    parts: {
      create: '/admin/parts',
      index: '/admin/parts',
      show: (id: string) => `/admin/parts/${id}`,
      update: (id: string) => `/admin/parts/${id}`,
      delete: (id: string) => `/admin/parts/${id}`,
      practices: {
        create: (partId: string) => `/admin/parts/${partId}/practices`,
        delete: (partId: string, practiceId: string) => `/admin/parts/${partId}/practices/${practiceId}`,
      },
      lessons: {
        create: (partId: string) => `/admin/parts/${partId}/lessons`,
        delete: (partId: string, lessonId: string) => `/admin/parts/${partId}/lessons/${lessonId}`,
      },
      localizedTexts: {
        create: (id: string) => `/admin/parts/${id}/localized-texts`,
      },
    },
    lessons: {
      create: '/admin/lessons',
      index: '/admin/lessons',
      show: (id: string) => `/admin/lessons/${id}`,
      update: (id: string) => `/admin/lessons/${id}`,
      delete: (id: string) => `/admin/lessons/${id}`,
      localizedTexts: {
        create: (lessonId: string) => `/admin/lessons/${lessonId}/localized-texts`,
        delete: (lessonId: string, localizedTextId: string) =>
          `/admin/lessons/${lessonId}/localized-texts/${localizedTextId}`,
        update: (lessonId: string, localizedTextId: string) =>
          `/admin/lessons/${lessonId}/localized-texts/${localizedTextId}`,
      },
      lessonResources: {
        create: (lessonId: string) => `/admin/lessons/${lessonId}/lesson-resources`,
        delete: (lessonId: string, lessonResourceId: string) =>
          `/admin/lessons/${lessonId}/lesson-resources/${lessonResourceId}`,
      },
      articles: {
        create: (lessonId: string) => `/admin/lessons/${lessonId}/articles`,
        delete: (lessonId: string, articleId: string) => `/admin/lessons/${lessonId}/articles/${articleId}`,
      },
    },
    lessonResources: {
      create: '/admin/lesson-resources',
      index: '/admin/lesson-resources',
      show: (id: string) => `/admin/lesson-resources/${id}`,
      update: (id: string) => `/admin/lesson-resources/${id}`,
      delete: (id: string) => `/admin/lesson-resources/${id}`,
      localizedTexts: {
        create: (lessonId: string) => `/admin/lesson-resources/${lessonId}/localized-texts`,
        delete: (lessonId: string, localizedTextId: string) =>
          `/admin/lesson-resources/${lessonId}/localized-texts/${localizedTextId}`,
        update: (lessonId: string, localizedTextId: string) =>
          `/admin/lesson-resources/${lessonId}/localized-texts/${localizedTextId}`,
      },
    },
    articles: {
      create: '/admin/articles',
      index: '/admin/articles',
      show: (id: string) => `/admin/articles/${id}`,
      update: (id: string) => `/admin/articles/${id}`,
      delete: (id: string) => `/admin/articles/${id}`,
      localizedTexts: {
        create: (id: string) => `/admin/articles/${id}/localized-texts`,
      },
    },
    humanCoaches: {
      create: '/admin/human-coaches',
      index: '/admin/human-coaches',
      show: (id: string) => `/admin/human-coaches/${id}`,
      update: (id: string) => `/admin/human-coaches/${id}`,
      delete: (id: string) => `/admin/human-coaches/${id}`,
    },
    localizedTexts: {
      create: '/admin/localized-texts',
      index: '/admin/localized-texts',
      show: (id: string) => `/admin/localized-texts/${id}`,
      update: (id: string) => `/admin/localized-texts/${id}`,
      delete: (id: string) => `/admin/localized-texts/${id}`,
    },
    images: {
      create: '/admin/images',
      index: '/admin/images',
      show: (id: string) => `/admin/images/${id}`,
      update: (id: string) => `/admin/images/${id}`,
      delete: (id: string) => `/admin/images/${id}`,
      localizedTexts: {
        create: (id: string) => `/admin/images/${id}/localized-texts`,
      },
    },
    videos: {
      create: '/admin/videos',
      index: '/admin/videos',
      show: (id: string) => `/admin/videos/${id}`,
      update: (id: string) => `/admin/videos/${id}`,
      delete: (id: string) => `/admin/videos/${id}`,
      localizedTexts: {
        create: (id: string) => `/admin/videos/${id}/localized-texts`,
      },
    },
    users: {
      index: '/admin/users',
      show: (id: string) => `/admin/users/${id}`,
      lookup: () => `/admin/users/lookup`,
      resetJourneys: (id: string) => `/admin/users/${id}/reset-journeys`,

      journeys: {
        index: (id: string) => `/admin/users/${id}/journeys`,
      },

      supports: {
        index: (id: string) => `/admin/users/${id}/supports`,
      },

      activityLogEntries: {
        index: (id: string) => `/admin/users/${id}/activity-log-entries`,
      },

      weightLogEntries: {
        index: (id: string) => `/admin/users/${id}/weight-log-entries`,
      },

      insightsReports: {
        index: (id: string) => `/admin/users/${id}/insights-reports`,
        show: (id: string, insightsReportId: string) =>
          `/admin/users/${id}/insights-reports/${insightsReportId}`,
      },

      notes: {
        update: (id: string) => `/admin/users/${id}/notes`,
      },
    },
    trackerQuestions: {
      index: '/admin/tracker-questions',
    },
    system: {
      portContent: '/admin/port-content',
      centralBullboard: '/admin/central-bull-login',
    },
  },
}

export default routes

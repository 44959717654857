import routes from '../config/routes'
import { api } from './common'

export default class SystemApi {
  static portContent() {
    return api.post(routes.api.system.portContent)
  }

  static async signIntoCentralBullboard() {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-return, @typescript-eslint/no-unsafe-member-access
    return (await api.post(routes.api.system.centralBullboard)).data.url
  }
}

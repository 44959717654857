import { Button, Modal, Text, TextInput, Image as MantineImage } from '@mantine/core'
import ImagesAPI, { Image } from '../../../api/images'
import { useEffect, useState } from 'react'
import JourneysAPI from '../../../api/journeys'
import ImageCard from '../../card/image-card'
import Loader from '../../loader'

export default function SelectImageModal({
  opened,
  onCancel,
  onSelectImage,
  title = 'Select an onboarding question type',
}: {
  opened: boolean
  onSelectImage: (image: Image) => void
  onCancel: () => void
  title?: string
  confirmText?: string
  cancelText?: string
}) {
  const [query, setQuery] = useState('')
  const [images, setImages] = useState<Image[] | null>(null)
  const [errorFetchingImages, setErrorFetchingImages] = useState(false)

  async function fetchImages() {
    try {
      const images = await ImagesAPI.index({ query })
      setImages(images)
    } catch (error) {
      setErrorFetchingImages(true)
    }
  }

  useEffect(() => {
    // eslint-disable-next-line
    fetchImages()
  }, [query])

  if (errorFetchingImages) return <div>Something happened while fetching images.</div>
  if (!images) return <Loader />

  return (
    <Modal opened={opened} onClose={onCancel} title={title} fullScreen={true}>
      <Text mb={20}>Select an image</Text>
      <TextInput
        id="search-images-input"
        mb={20}
        value={query}
        onChange={e => setQuery(e.target.value)}
        label="Search"
      />

      {images.map((image, index) => (
        <ImageCard width={120} height={120} image={image} key={index} onClick={() => onSelectImage(image)} />
      ))}
    </Modal>
  )
}

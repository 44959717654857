import { useState } from 'react'
import ChecklistTriggersAPI, {
  CHECKLIST_TRIGGER_TYPES,
  ChecklistTrigger,
  ChecklistTriggerType,
} from '../../api/supportChecklistTriggers'
import routes from '../../config/routes'
import { useNavigate } from 'react-router-dom'
import FormGroup from './utils/form-group'
import FormButtonGroup from './utils/form-button-group'
import { Button } from '@mantine/core'

export default function ChecklistTriggerForm({ checklistTrigger }: { checklistTrigger?: ChecklistTrigger }) {
  const navigate = useNavigate()
  const [name, setName] = useState(checklistTrigger?.name || '')
  const [nameErrors, setNameErrors] = useState<string[]>([])
  const [type, setType] = useState<ChecklistTriggerType | null>(
    checklistTrigger?.type || 'DayChecklistTrigger'
  )
  const [typeErrors, setTypeErrors] = useState<string[]>([])
  const formId = checklistTrigger ? 'edit-checklist-trigger-form' : 'new-checklist-trigger-form'

  const hasChanges = checklistTrigger
    ? name !== checklistTrigger.name || type !== checklistTrigger.type
    : true

  return (
    <form
      id={formId}
      onSubmit={async event => {
        event.preventDefault()

        try {
          if (checklistTrigger) {
            await ChecklistTriggersAPI.update(checklistTrigger.id, {
              name,
              type: type!,
            })
            navigate(routes.app.checklistTriggers.show(checklistTrigger.id))
          } else {
            const res = await ChecklistTriggersAPI.create({
              name,
              type: type!,
            })
            navigate(routes.app.checklistTriggers.show(res.data))
          }
        } catch (err: any) {
          if (Array.isArray(err.name)) setNameErrors(err.name)
          if (Array.isArray(err.type)) setTypeErrors(err.type)
        }
      }}
    >
      <FormGroup
        required
        id="name"
        label="name"
        name="name"
        value={name}
        errors={nameErrors}
        onChange={val => {
          setName(val)
          setNameErrors([])
        }}
      />

      {!checklistTrigger && (
        <FormGroup
          required
          id="type"
          label="type"
          name="type"
          value={type}
          errors={typeErrors}
          inputType="select"
          options={CHECKLIST_TRIGGER_TYPES.map(l => ({ label: l, value: l }))}
          onChange={val => {
            setType(val)
            setTypeErrors([])
          }}
        />
      )}

      <FormButtonGroup>
        <Button className="filled green" disabled={!hasChanges} type="submit">
          {checklistTrigger ? 'Save' : 'Create'}
        </Button>
      </FormButtonGroup>
    </form>
  )
}

import { Table } from '@mantine/core'
import { User } from '../../../app/api/users'
import React from 'react'

export default function UserProfileTable({ user }: { user: User }) {
  return (
    <Table>
      <tbody>
        {Object.keys(user).map(key =>
          ['id', 'notes'].includes(key) ? null : (
            <tr key={key}>
              <td>{key}:</td>
              <td style={{ textAlign: 'right' }}>{formattedSettingValue((user as any)[key], key)}</td>
            </tr>
          )
        )}
      </tbody>
    </Table>
  )
}

function formattedSettingValue(value: any, key: string) {
  switch (key) {
    default:
      return value
  }
}

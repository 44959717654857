import { Button, Modal, Text } from '@mantine/core'
import {
  JourneyOnboardingQuestionType,
  JourneyOnboardingQuestionTypes,
} from '../../../api/journeyOnboardingQuestions'

export default function SelectJourneyOnboardingScreenModal({
  opened,
  onCancel,
  onSelectOnboardingQuestionType,
  title = 'Select an onboarding question type',
}: {
  opened: boolean
  onSelectOnboardingQuestionType: (type: JourneyOnboardingQuestionType) => void
  onCancel: () => void
  title?: string
  confirmText?: string
  cancelText?: string
}) {
  return (
    <Modal
      fullScreen
      opened={opened}
      onClose={onCancel}
      title={<h3>{title}</h3>}
      style={{ textAlign: 'center' }}
    >
      <Text size={'md'} mt={200} mb={20} style={{ textAlign: 'center' }}>
        Select an onboarding question type for the new question you want to add to this journey
      </Text>
      {JourneyOnboardingQuestionTypes.map((type, index) => (
        <Button
          variant="outline"
          key={index}
          m={5}
          onClick={() => onSelectOnboardingQuestionType(type as JourneyOnboardingQuestionType)}
        >
          {type.replace(/_/g, ' ')}
        </Button>
      ))}
    </Modal>
  )
}

import { useEffect, useState } from 'react'
import FormGroup from '../../components/forms/utils/form-group'
import { Anchor, Breadcrumbs, Button, Title } from '@mantine/core'
import LessonResourcesAPI, { LessonResource } from '../../api/lessonResources'
import routes from '../../config/routes'
import { useNavigate, useParams } from 'react-router-dom'
import FormButtonGroup from '../../components/forms/utils/form-button-group'
import { Link } from 'react-router-dom'
import useAuthEffect from '../../hooks/useAuthEffect'
import Loader from '../../components/loader'

export default function LessonResourcesEditPage() {
  const params = useParams()
  const navigate = useNavigate()
  const [name, setName] = useState('')
  const [nameErrors, setNameErrors] = useState<string[]>([])
  const [approximateMinutes, setApproximateMinutes] = useState(1)
  const [approximateMinuteErrors, setApproximateMinuteErrors] = useState<string[]>([])
  const [lessonResource, setLessonResource] = useState<LessonResource | null>(null)
  const [errorFetchingLessonResource, setErrorFetchingLessonResource] = useState(false)
  const [externalId, setExternalId] = useState<string | null>(null)
  const [externalIdErrors, setExternalIdErrors] = useState<string[]>([])

  const hasChanges =
    lessonResource &&
    (name !== lessonResource.name ||
      approximateMinutes !== lessonResource.approximateMinutes ||
      externalId !== lessonResource.externalId)

  useAuthEffect(() => {
    async function fetchLessonResource(id: string) {
      try {
        const lessonResource = await LessonResourcesAPI.show(id)
        setLessonResource(lessonResource)
        setName(lessonResource.name)
        setApproximateMinutes(lessonResource.approximateMinutes)
        setExternalId(lessonResource.externalId)
      } catch (error) {
        setErrorFetchingLessonResource(true)
      }
    }

    // eslint-disable-next-line
    fetchLessonResource(params.id!)
  })

  if (errorFetchingLessonResource)
    return <div>We were unable to locate the Lesson Resource you're looking for.</div>
  if (!lessonResource) return <Loader />

  return (
    <div className="page">
      <Breadcrumbs>
        <Anchor component={Link} to={routes.app.lessonResources.index}>
          lesson resources
        </Anchor>
        <Anchor component={Link} to={routes.app.lessonResources.show(lessonResource.id)}>
          {lessonResource.name}
        </Anchor>
        <Anchor component={Link} to={routes.app.lessonResources.edit(lessonResource.id)}>
          edit
        </Anchor>
      </Breadcrumbs>

      <Title>Edit {lessonResource.name}</Title>
      <form
        id="edit-lesson-resource-form"
        onSubmit={async event => {
          event.preventDefault()
          try {
            await LessonResourcesAPI.update(lessonResource.id, {
              name,
              approximateMinutes,
              externalId,
            })
            navigate(routes.app.lessonResources.show(lessonResource.id))
          } catch (err: any) {
            if (Array.isArray(err.name)) setNameErrors(err.name)
            if (Array.isArray(err.approximate_minutes)) setApproximateMinuteErrors(err.approximate_minutes)
          }
        }}
      >
        <FormGroup
          required
          id="name"
          label="name"
          name="name"
          value={name}
          errors={nameErrors}
          onChange={val => {
            setName(val)
            setNameErrors([])
          }}
        />
        <FormGroup
          required
          id="approximate-minutes"
          inputType="number"
          label="Approximate minutes"
          name="approximateMinutes"
          value={approximateMinutes}
          errors={approximateMinuteErrors}
          onChange={val => {
            setApproximateMinutes(val)
            setApproximateMinuteErrors([])
          }}
        />
        <FormGroup
          required
          id="external-id"
          inputType="text"
          label="External Id"
          name="externalId"
          value={externalId}
          errors={externalIdErrors}
          onChange={val => {
            setExternalId(val)
            setExternalIdErrors([])
          }}
        />

        <FormButtonGroup>
          <Button className="filled green" disabled={!hasChanges} type="submit">
            Save
          </Button>
        </FormButtonGroup>
      </form>
    </div>
  )
}

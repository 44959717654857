import { useState, useEffect } from 'react'
import { redirect, useNavigate, useParams } from 'react-router-dom'
import {
  Anchor,
  Breadcrumbs,
  Button,
  Group,
  Modal,
  SimpleGrid,
  Space,
  Table,
  Text,
  Title,
} from '@mantine/core'
import { Link } from 'react-router-dom'
import routes from '../../../config/routes'
import TitleWithButton from '../../../components/title/TitleWithButton'
import ConfirmModal from '../../../components/modal/confirm'
import LessonResourcesAPI, { LessonResource } from '../../../api/lessonResources'
import LessonResourcesShowPage from '../../lesson-resources/show'
import LessonsAPI, { Lesson } from '../../../api/lessons'
import PartCard from '../../../components/card/part-card'
import useAuthEffect from '../../../hooks/useAuthEffect'
import Loader from '../../../components/loader'

export default function LessonLessonResourcesShowPage() {
  const params = useParams()
  const navigate = useNavigate()
  const [lesson, setLesson] = useState<Lesson | null>(null)
  const [errorFetchingLesson, setErrorFetchingLesson] = useState(false)
  const [lessonResource, setLessonResource] = useState<LessonResource | null>(null)
  const [errorFetchingLessonResource, setErrorFetchingLessonResource] = useState(false)
  const [showDeleteModal, setShowDeleteModal] = useState(false)

  async function fetchLessonResource(id: string) {
    try {
      const lessonResource = await LessonResourcesAPI.show(id)
      setLessonResource(lessonResource)
    } catch (error) {
      setErrorFetchingLessonResource(true)
    }
  }

  async function fetchLesson(id: string) {
    try {
      const lesson = await LessonsAPI.show(id)
      setLesson(lesson)
    } catch (error) {
      setErrorFetchingLesson(true)
    }
  }

  useAuthEffect(() => {
    // eslint-disable-next-line
    fetchLessonResource(params.lessonResourceId!)
    // eslint-disable-next-line
    fetchLesson(params.id!)
  })

  if (errorFetchingLessonResource)
    return <div>There was an error fetching the lessonResource you requested.</div>
  if (errorFetchingLesson) return <div>There was an error fetching the lesson you requested.</div>
  if (!lessonResource || !lesson) return <Loader />

  return (
    <div className="page">
      <Breadcrumbs>
        <Anchor component={Link} to={routes.app.lessons.index}>
          lessons
        </Anchor>
        <Anchor component={Link} to={routes.app.lessons.show(lesson.id)}>
          {lesson.name}
        </Anchor>
        <Anchor component={Link} to={routes.app.lessonResources.new}>
          {lessonResource.name}
        </Anchor>
      </Breadcrumbs>

      <TitleWithButton title={lessonResource.name}>
        <Button onClick={() => setShowDeleteModal(true)} color="red" variant="outline">
          detach from lesson
        </Button>
      </TitleWithButton>

      <ConfirmModal
        opened={showDeleteModal}
        onCancel={() => setShowDeleteModal(false)}
        onConfirm={async () => {
          await LessonsAPI.detachLessonResource(lesson.id, lessonResource.id)
          navigate(routes.app.lessons.show(lesson.id))
        }}
        title="Are you sure?"
        message="Are you sure you want to detach this lesson resource from this lesson?"
        confirmText="Really detach?"
      />
    </div>
  )
}

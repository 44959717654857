import routes from '../config/routes'
import { api } from './common'
import { HumanCoach } from './humanCoaches'
import { LocalizedText, LocalizedTextParams } from './localizedTexts'
import { Video } from './videos'

export default class SupportCoachTipsAPI {
  public static async create(supportId: string, attrs: SupportCoachTipParams) {
    return await api.post(routes.api.supports.coachTips.create(supportId), { supportCoachTip: attrs })
  }

  public static async update(id: string, attrs: Partial<SupportCoachTipParams>) {
    return await api.patch(routes.api.supports.coachTips.update(id), { supportCoachTip: attrs })
  }

  // public static async index({ query }: { query?: string } = {}) {
  //   const res = await api.get(routes.api.supports.coachTips.index, { params: { query } })
  //   return res.data as SupportCoachTip[]
  // }

  public static async show(supportId: string, id: string) {
    const res = await api.get(routes.api.supports.coachTips.show(supportId, id))
    return res.data as SupportCoachTip
  }

  public static async delete(id: string) {
    return await api.delete(routes.api.supports.coachTips.delete(id))
  }

  public static async createLocalizedText(id: string, params: LocalizedTextParams) {
    return await api.post(routes.api.supports.coachTips.localizedTexts.create(id), {
      localizedText: params,
    })
  }
}

export interface SupportCoachTip {
  id: string
  name: string
  position: number
  type: SupportCoachTipType
  localizedTexts: LocalizedText[]
  videoId: string | null
  video: Video | null
  humanCoachId: string | null
  humanCoach: HumanCoach | null
}

export type SupportCoachTipType = 'SupportTextCoachTip' | 'SupportVideoCoachTip'

export const SUPPORT_COACH_TIP_TYPES = {
  Text: 'SupportTextCoachTip',
  Video: 'SupportVideoCoachTip',
}

export interface SupportCoachTipParams {
  name: string
  type: SupportCoachTipType
  humanCoachId?: string | null
  videoId?: string | null
  position?: number
}

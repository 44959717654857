import { Button, Paper } from '@mantine/core'
import TitleWithButton from '../title/TitleWithButton'
import LessonListItem from '../list-item/lesson-list-item'
import { Lesson } from '../../api/lessons'
import { ReactNode, useState } from 'react'
import EmptyMessage from '../display/empty-message'
import SelectLessonModal from '../modal/composite/select-lesson'

export default function LessonsCrud({
  lessons,
  onSelectLesson,
  onLessonClick,
  onLessonDelete,
  emptyMessage = 'No lessons found',
}: {
  lessons: Lesson[]
  onSelectLesson: (lesson: Lesson) => void
  onLessonClick: (lesson: Lesson) => void
  onLessonDelete: (lesson: Lesson) => void
  emptyMessage?: ReactNode
}) {
  const [showModal, setShowModal] = useState(false)

  return (
    <>
      <Paper mt={20} p={20} className="lessons-list">
        <TitleWithButton mb={40} order={2} title="Lessons">
          <Button className="green filled add" onClick={() => setShowModal(true)}>
            Add lesson
          </Button>
        </TitleWithButton>

        {!lessons.length && <EmptyMessage>{emptyMessage}</EmptyMessage>}
        {lessons.map((lesson, index) => (
          <LessonListItem
            key={index}
            lesson={lesson}
            onClick={() => {
              onLessonClick(lesson)
            }}
            onDelete={async lesson => {
              onLessonDelete(lesson)
            }}
          />
        ))}
      </Paper>

      <SelectLessonModal
        opened={showModal}
        onCancel={() => setShowModal(false)}
        onSelectLesson={async lesson => {
          setShowModal(false)
          onSelectLesson(lesson)
        }}
      />
    </>
  )
}

import { Anchor, Button, Paper, Table, Text } from '@mantine/core'
import { useState } from 'react'
import { Link } from 'react-router-dom'
import routes from '../../config/routes'
import JourneysAPI from '../../api/journeys'
import { NavLink } from 'react-router-dom'
import useAuthEffect from '../../hooks/useAuthEffect'
import TitleWithButton from '../../components/title/TitleWithButton'
import Loader from '../../components/loader'
import { AdminJourney } from '../../api/schema'

export default function JourneysIndexPage() {
  const [journeys, setJourneys] = useState<AdminJourney[]>([])
  const [errorFetchingJourney, setErrorFetchingJourney] = useState(false)
  const [doneFetching, setDoneFetching] = useState(false)

  useAuthEffect(() => {
    async function fetchJourneys() {
      try {
        const journeys = await JourneysAPI.index()
        setJourneys(journeys)
        setDoneFetching(true)
      } catch (error) {
        setErrorFetchingJourney(true)
      }
    }

    // eslint-disable-next-line
    fetchJourneys()
  })

  return (
    <div className="page">
      <TitleWithButton title="Journeys">
        <Button className="filled green" component={Link} to={routes.app.journeys.new}>
          New journey
        </Button>
      </TitleWithButton>

      {journeys.length > 0 && (
        <Paper mt={20} p="md">
          <Table>
            <thead>
              <tr>
                <td>Name</td>
                <td>Published</td>
              </tr>
            </thead>
            <tbody>
              {journeys.map((journey, index) => (
                <tr key={index}>
                  <td>
                    <Anchor component={NavLink} to={routes.app.journeys.show(journey.id)}>
                      {journey.name}
                    </Anchor>
                  </td>
                  <td>{journey.published ? 'true' : 'false'}</td>
                </tr>
              ))}
            </tbody>
          </Table>
        </Paper>
      )}

      {!doneFetching && <Loader />}

      {journeys.length === 0 && doneFetching && (
        <Text style={{ textAlign: 'center' }}>
          <Paper m="lg" p="md">
            No journeys have been created yet.{' '}
            <Anchor component={Link} to={routes.app.journeys.new}>
              Create one
            </Anchor>{' '}
            to get started.
          </Paper>
        </Text>
      )}
    </div>
  )
}

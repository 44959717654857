import { useState } from 'react'
import EventTriggersAPI, { EventTrigger } from '../../api/supportEventTriggers'
import { useNavigate, useParams } from 'react-router-dom'
import { Anchor, Breadcrumbs, Button } from '@mantine/core'
import { Link } from 'react-router-dom'
import routes from '../../config/routes'
import TitleWithButton from '../../components/title/TitleWithButton'
import ConfirmModal from '../../components/modal/confirm'
import useAuthEffect from '../../hooks/useAuthEffect'
import AddOrEditSupportEventNoun from '../../components/add-or-edit-noun'
import AddOrEditSupportEventVerb from '../../components/add-or-edit-verb'
import AddOrEditSupportEventModifier from '../../components/add-or-edit-modifier'
import Loader from '../../components/loader'

type EventTriggersShowModalType = 'delete' | 'add_noun' | null

export default function EventTriggersShowPage({ eventTriggerId }: { eventTriggerId?: string } = {}) {
  const params = useParams()
  const navigate = useNavigate()
  const [eventTrigger, setEventTrigger] = useState<EventTrigger | null>(null)
  const [errorFetchingEventTrigger, setErrorFetchingEventTrigger] = useState(false)
  const [modalType, setModalType] = useState<EventTriggersShowModalType>(null)
  const id = eventTriggerId || params.id!

  async function fetchEventTrigger(id: string) {
    try {
      const eventTrigger = await EventTriggersAPI.show(id)
      setEventTrigger(eventTrigger)
    } catch (error) {
      setErrorFetchingEventTrigger(true)
    }
  }

  useAuthEffect(() => {
    // eslint-disable-next-line
    fetchEventTrigger(id)
  })

  if (errorFetchingEventTrigger) return <div>There was an error fetching the eventTrigger you requested.</div>
  if (!eventTrigger) return <Loader />

  return (
    <div className="page">
      <Breadcrumbs>
        <Anchor component={Link} to={routes.app.eventTriggers.index}>
          event triggers
        </Anchor>
        <Anchor component={Link} to={routes.app.eventTriggers.new}>
          {eventTrigger.name}
        </Anchor>
      </Breadcrumbs>

      <TitleWithButton title={eventTrigger.name}>
        <Button
          className="outlined blue"
          style={{ marginRight: 10 }}
          component={Link}
          to={routes.app.eventTriggers.edit(eventTrigger.id)}
        >
          edit
        </Button>
        <Button className="outlined red" onClick={() => setModalType('delete')}>
          delete
        </Button>
      </TitleWithButton>

      <AddOrEditSupportEventVerb
        supportEventVerb={eventTrigger.verb}
        onSelectSupportEventVerb={async verb => {
          try {
            await EventTriggersAPI.update(eventTrigger.id, { verbId: verb.id })
            await fetchEventTrigger(eventTrigger.id)
          } catch (err) {
            // TODO: handle this
          }
        }}
      />

      <AddOrEditSupportEventNoun
        supportEventNoun={eventTrigger.noun}
        onSelectSupportEventNoun={async noun => {
          try {
            await EventTriggersAPI.update(eventTrigger.id, { nounId: noun.id })
            await fetchEventTrigger(eventTrigger.id)
          } catch (err) {
            // TODO: handle this
          }
        }}
        onRemoveClick={async () => {
          try {
            await EventTriggersAPI.update(eventTrigger.id, { nounId: null })
            await fetchEventTrigger(eventTrigger.id)
          } catch (err) {
            // TODO: handle this
          }
        }}
      />

      <AddOrEditSupportEventModifier
        verb={eventTrigger.verb}
        supportEventModifier={eventTrigger.modifier}
        onSelectSupportEventModifier={async modifier => {
          try {
            await EventTriggersAPI.update(eventTrigger.id, { modifierId: modifier.id })
            await fetchEventTrigger(eventTrigger.id)
          } catch (err) {
            // TODO: handle this
          }
        }}
        onRemoveClick={async () => {
          try {
            await EventTriggersAPI.update(eventTrigger.id, { modifierId: null })
            await fetchEventTrigger(eventTrigger.id)
          } catch (err) {
            // TODO: handle this
          }
        }}
      />

      <ConfirmModal
        opened={modalType === 'delete'}
        onCancel={() => setModalType(null)}
        onConfirm={async () => {
          await EventTriggersAPI.delete(eventTrigger.id)
          navigate(routes.app.eventTriggers.index)
        }}
        title="Are you sure?"
        message="Are you sure you want to delete this event trigger?"
        confirmText="Really delete"
      />
    </div>
  )
}

import { useState } from 'react'
import { ActionIcon, Badge, Button, Flex, Paper, Select, TextInput, Textarea, rem } from '@mantine/core'
import TitleWithButton from '../title/TitleWithButton'
import LocalizedTextsAPI, { LOCALES, LocalesEnum, LocalizedText } from '../../api/localizedTexts'
import { IconX } from '@tabler/icons-react'
import { sortBy } from 'lodash'
import { showNotification } from '@mantine/notifications'
import JourneysAPI from '../../api/journeys'
import PhasesAPI from '../../api/phases'
import LessonsAPI from '../../api/lessons'
import SupportsAPI from '../../api/supports'
import SupportCoachTipsAPI from '../../api/supportCoachTips'
import SupportTrackerTipsAPI from '../../api/supportTrackerTips'
import LessonResourcesAPI from '../../api/lessonResources'
import ImagesAPI from '../../api/images'
import ArticlesAPI from '../../api/articles'
import PracticesAPI from '../../api/practices'
import VideosAPI from '../../api/videos'
import PartsAPI from '../../api/parts'

export default function FormLocalizationSection({
  localizableId,
  existingLocalizedTexts,
  onChange,
  apiModule,
  extraArgs,
  extraFields = [],
  excludedFields = [],
}: {
  localizableId: string
  existingLocalizedTexts: LocalizedText[]
  onChange: (locale: LocalesEnum) => void | Promise<void>
  apiModule:
    | typeof JourneysAPI
    | typeof PhasesAPI
    | typeof LessonsAPI
    | typeof SupportsAPI
    | typeof SupportCoachTipsAPI
    | typeof SupportTrackerTipsAPI
    | typeof LessonResourcesAPI
    | typeof ImagesAPI
    | typeof ArticlesAPI
    | typeof PracticesAPI
    | typeof VideosAPI
    | typeof PartsAPI
  extraArgs?: any
  extraFields?: AdditionalLocalizedTextField[]
  excludedFields?: LocalizationField[]
}) {
  const [selectedLocale, setSelectedLocale] = useState<LocalesEnum>('en-US')
  const selectedLocalizedText = existingLocalizedTexts.find(locale => locale.locale === selectedLocale)

  const [selectedLocaleName, setSelectedLocaleName] = useState(selectedLocalizedText?.name || '')
  const [selectedLocaleTitle, setSelectedLocaleTitle] = useState(selectedLocalizedText?.title || '')
  const [selectedLocaleSubtitle, setSelectedLocaleSubtitle] = useState(selectedLocalizedText?.subtitle || '')
  const [selectedLocaleBody, setSelectedLocaleBody] = useState(selectedLocalizedText?.body || '')
  const [selectedLocaleAlt, setSelectedLocaleAlt] = useState(selectedLocalizedText?.alt || '')
  const [selectedLocaleCTA, setSelectedLocaleCTA] = useState(selectedLocalizedText?.cta || '')
  const [selectedLocaleCallout, setSelectedLocaleCallout] = useState(selectedLocalizedText?.callout || '')
  const [selectedLocaleDescription, setSelectedLocaleDescription] = useState(
    selectedLocalizedText?.description || ''
  )
  const [selectedLocaleSummaryDescription, setSelectedLocaleSummaryDescription] = useState(
    selectedLocalizedText?.summaryDescription || ''
  )
  const [selectedLocaleActiveSummaryDescription, setSelectedLocaleActiveSummaryDescription] = useState(
    selectedLocalizedText?.activeSummaryDescription || ''
  )
  const [selectedLocaleTranscription, setSelectedLocaleTranscription] = useState(
    selectedLocalizedText?.transcription || ''
  )

  function setSelectedLocaleAndResetSelectedValues(locale: LocalesEnum) {
    setSelectedLocale(locale)
    const selectedLocalizedText = existingLocalizedTexts.find(l => l.locale === locale)

    setSelectedLocaleName(selectedLocalizedText?.name || '')
    setSelectedLocaleTitle(selectedLocalizedText?.title || '')
    setSelectedLocaleSubtitle(selectedLocalizedText?.subtitle || '')
    setSelectedLocaleBody(selectedLocalizedText?.body || '')
    setSelectedLocaleAlt(selectedLocalizedText?.alt || '')
    setSelectedLocaleCTA(selectedLocalizedText?.cta || '')
    setSelectedLocaleCallout(selectedLocalizedText?.callout || '')
    setSelectedLocaleDescription(selectedLocalizedText?.description || '')
    setSelectedLocaleSummaryDescription(selectedLocalizedText?.summaryDescription || '')
    setSelectedLocaleActiveSummaryDescription(selectedLocalizedText?.activeSummaryDescription || '')
    setSelectedLocaleTranscription(selectedLocalizedText?.transcription || '')
  }

  function shouldShowField(field: LocalizationField) {
    return (
      selectedLocalizedText &&
      fieldExistsForLocalizedTextType(field, selectedLocalizedText) &&
      !excludedFields.includes(field)
    )
  }

  return (
    <Paper mt={20} p={20}>
      <TitleWithButton order={2} title="Locales">
        <Select
          id="titles-locale-select"
          data={LOCALES.map(l => ({ label: l, value: l }))}
          value={selectedLocalizedText?.locale}
          onChange={async val => {
            setSelectedLocale(val as LocalesEnum)
            const existingLocale = existingLocalizedTexts.find(locale => locale.locale === val)
            if (!existingLocale) {
              await apiModule.createLocalizedText(localizableId, {
                title: '',
                description: '',
                summaryDescription: '',
                locale: val as LocalesEnum,
                extraArgs,
              })
              await onChange(val as LocalesEnum)
              setSelectedLocaleAndResetSelectedValues(val as LocalesEnum)
            }
          }}
        />
      </TitleWithButton>

      <div style={{ marginTop: '20px', marginBottom: '20px' }}>
        {sortBy(existingLocalizedTexts, 'locale').map((localizedText, index) => (
          <Badge
            className={`${localizedText.locale} ${localizedText === selectedLocalizedText ? 'active' : ''}`}
            key={index}
            variant="gradient"
            gradient={
              localizedText === selectedLocalizedText
                ? { from: 'green', to: 'green' }
                : { from: 'gray', to: 'gray' }
            }
            pr={3}
            mr={10}
            rightSection={
              localizedText.locale === 'en-US' ? (
                <div style={{ paddingRight: 5 }}></div>
              ) : (
                <ActionIcon
                  className={`delete-locale ${localizedText.locale}`}
                  size="xs"
                  color="blue"
                  radius="xl"
                  variant="transparent"
                  onClick={async () => {
                    await LocalizedTextsAPI.delete(localizedText!.id)
                    showNotification({
                      title: 'success',
                      message: `Successfully removed content for ${localizedText.locale}`,
                      color: 'green',
                    })

                    if (selectedLocale === localizedText.locale) {
                      const newLocale = existingLocalizedTexts.filter(l => l !== localizedText)[0].locale
                      setSelectedLocaleAndResetSelectedValues(newLocale)
                      await onChange(newLocale)
                    } else {
                      await onChange(localizedText.locale)
                    }
                  }}
                >
                  <IconX size={rem(10)} color="white" />
                </ActionIcon>
              )
            }
            style={{ cursor: 'pointer' }}
            onClick={() => {
              setSelectedLocaleAndResetSelectedValues(localizedText.locale)
            }}
          >
            {localizedText.locale}
          </Badge>
        ))}
      </div>

      {shouldShowField('name') && (
        <TextInput
          mb={20}
          id="locale-name-input"
          placeholder={`Add a name for ${selectedLocale}`}
          label={`${selectedLocale} name`}
          withAsterisk
          value={selectedLocaleName}
          onChange={e => {
            setSelectedLocaleName(e.target.value)
          }}
        />
      )}

      {shouldShowField('title') && (
        <TextInput
          mb={20}
          id="locale-title-input"
          placeholder={`Add a title for ${selectedLocale}`}
          label={`${selectedLocale} title`}
          withAsterisk
          value={selectedLocaleTitle}
          onChange={e => {
            setSelectedLocaleTitle(e.target.value)
          }}
        />
      )}

      {shouldShowField('subtitle') && (
        <TextInput
          mb={20}
          id="locale-subtitle-input"
          placeholder={`Add a subtitle for ${selectedLocale}`}
          label={`${selectedLocale} subtitle`}
          withAsterisk
          value={selectedLocaleSubtitle}
          onChange={e => {
            setSelectedLocaleSubtitle(e.target.value)
          }}
        />
      )}

      {shouldShowField('description') && (
        <Textarea
          mb={20}
          id="locale-description-input"
          placeholder={`Add a description for ${selectedLocale}`}
          label={`${selectedLocale} description`}
          withAsterisk
          value={selectedLocaleDescription}
          onChange={e => {
            setSelectedLocaleDescription(e.target.value)
          }}
        />
      )}

      {shouldShowField('alt') && (
        <TextInput
          mb={20}
          id="locale-alt-input"
          placeholder={`Add a alt for ${selectedLocale}`}
          label={`${selectedLocale} alt`}
          withAsterisk
          value={selectedLocaleAlt}
          onChange={e => {
            setSelectedLocaleAlt(e.target.value)
          }}
        />
      )}

      {shouldShowField('summaryDescription') && (
        <Textarea
          id="locale-summary-description-input"
          placeholder={`Add a description summary for ${selectedLocale}`}
          label={`${selectedLocale} description summary`}
          withAsterisk
          value={selectedLocaleSummaryDescription}
          onChange={e => {
            setSelectedLocaleSummaryDescription(e.target.value)
          }}
        />
      )}

      {shouldShowField('activeSummaryDescription') && (
        <Textarea
          id={`locale-active-summary-description-input`}
          placeholder="add an active summary description"
          label={`${selectedLocale} active summary description`}
          withAsterisk
          value={selectedLocaleActiveSummaryDescription}
          onChange={e => {
            setSelectedLocaleActiveSummaryDescription(e.target.value)
          }}
        />
      )}

      {shouldShowField('body') && (
        <Textarea
          mb={20}
          id="locale-body-input"
          placeholder={`Add a body for ${selectedLocale}`}
          label={`${selectedLocale} body`}
          withAsterisk
          value={selectedLocaleBody}
          onChange={e => {
            setSelectedLocaleBody(e.target.value)
          }}
        />
      )}

      {shouldShowField('cta') && (
        <TextInput
          mb={20}
          id="locale-cta-input"
          placeholder={`Add a cta for ${selectedLocale}`}
          label={`${selectedLocale} cta`}
          withAsterisk
          value={selectedLocaleCTA}
          onChange={e => {
            setSelectedLocaleCTA(e.target.value)
          }}
        />
      )}

      {shouldShowField('callout') && (
        <TextInput
          mb={20}
          id="locale-callout-input"
          placeholder={`Add a callout for ${selectedLocale}`}
          label={`${selectedLocale} callout`}
          withAsterisk
          value={selectedLocaleCallout}
          onChange={e => {
            setSelectedLocaleCallout(e.target.value)
          }}
        />
      )}

      {shouldShowField('transcription') && (
        <Textarea
          id={`locale-transcription-input`}
          placeholder="add a transcription"
          label={`${selectedLocale} transcription`}
          withAsterisk
          value={selectedLocaleTranscription}
          onChange={e => {
            setSelectedLocaleTranscription(e.target.value)
          }}
        />
      )}

      <Flex justify="flex-end" mt={20}>
        <Button
          className="filled green"
          mt={10}
          onClick={async () => {
            await LocalizedTextsAPI.update(selectedLocalizedText!.id, {
              name: selectedLocaleName,
              title: selectedLocaleTitle,
              subtitle: selectedLocaleSubtitle,
              description: selectedLocaleDescription,
              alt: selectedLocaleAlt,
              summaryDescription: selectedLocaleSummaryDescription,
              activeSummaryDescription: selectedLocaleActiveSummaryDescription,
              body: selectedLocaleBody,
              cta: selectedLocaleCTA,
              callout: selectedLocaleCallout,
              transcription: selectedLocaleTranscription,
              locale: selectedLocale,
            })
            showNotification({
              title: 'success',
              message: `Successfully made changes to ${selectedLocale}`,
              color: 'green',
            })
            await onChange(selectedLocale)
          }}
        >
          save
        </Button>
      </Flex>
    </Paper>
  )
}

export type LocalizationField =
  | 'name'
  | 'title'
  | 'subtitle'
  | 'description'
  | 'summaryDescription'
  | 'cta'
  | 'callout'
  | 'activeSummaryDescription'
  | 'body'
  | 'transcription'
  | 'alt'

export type AdditionalLocalizedTextField = 'transcription' | 'activeSummaryDescription'

function fieldExistsForLocalizedTextType(field: keyof LocalizedText, localizedText: LocalizedText) {
  switch (localizedText.type) {
    case 'CtaAndCalloutLocalizedText':
      return ['title', 'subtitle', 'cta', 'callout'].includes(field)

    case 'ImageLocalizedText':
      return ['title', 'alt'].includes(field)

    case 'PracticeLocalizedText':
      return [
        'name',
        'title',
        'subtitle',
        'summaryDescription',
        'activeSummaryDescription',
        'description',
        'cta',
      ].includes(field)

    case 'ShortMessageLocalizedText':
      return ['title', 'body'].includes(field)

    case 'StandardLocalizedText':
      return ['title', 'subtitle', 'summaryDescription', 'description'].includes(field)

    case 'VideoLocalizedText':
      return ['title', 'subtitle', 'summaryDescription', 'description', 'transcription'].includes(field)

    default:
      throw `Unrecognized localized text type: ${localizedText.type}`
  }
}

import { Anchor, Button, Paper, Table, Text } from '@mantine/core'
import { useState } from 'react'
import { Link } from 'react-router-dom'
import routes from '../../config/routes'
import SupportsAPI from '../../api/supports'
import { NavLink } from 'react-router-dom'
import TitleWithButton from '../../components/title/TitleWithButton'
import useAuthEffect from '../../hooks/useAuthEffect'
import Loader from '../../components/loader'
import { AdminSupport } from '../../api/schema'

export default function SupportsIndexPage() {
  const [supports, setSupports] = useState<AdminSupport[]>([])
  const [errorFetchingSupport, setErrorFetchingSupport] = useState(false)
  const [doneFetching, setDoneFetching] = useState(false)

  useAuthEffect(() => {
    async function fetchSupports() {
      try {
        const supports = await SupportsAPI.index()
        setSupports(supports)
        setDoneFetching(true)
      } catch (error) {
        setErrorFetchingSupport(true)
      }
    }

    // eslint-disable-next-line
    fetchSupports()
  })

  return (
    <div className="page">
      <TitleWithButton title="Supports">
        <Button className="filled green" component={Link} to={routes.app.supports.new}>
          New support
        </Button>
      </TitleWithButton>

      {supports.length > 0 && (
        <Paper mt={20} p="md">
          <Table>
            <thead>
              <tr>
                <td>Name</td>
              </tr>
            </thead>
            <tbody>
              {supports.map((support, index) => (
                <tr key={index}>
                  <td>
                    <Anchor component={NavLink} to={routes.app.supports.show(support.id)}>
                      {support.name}
                    </Anchor>
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
        </Paper>
      )}

      {!doneFetching && <Loader />}

      {supports.length === 0 && doneFetching && (
        <Text style={{ textAlign: 'center' }}>
          <Paper m="lg" p="md">
            No supports have been created yet.{' '}
            <Anchor component={Link} to={routes.app.supports.new}>
              Create one
            </Anchor>{' '}
            to get started.
          </Paper>
        </Text>
      )}
    </div>
  )
}

import { Button, Card } from '@mantine/core'
import { useState } from 'react'
import JourneyTrackerQuestionsAPI from '../../api/journeyTrackerQuestions'
import { AdminJourneyTrackerQuestion } from '../../api/schema'
import ConfirmModal from '../modal/confirm'
import TitleWithButton from '../title/TitleWithButton'

type JourneyTrackerQuestionCardModalType = 'confirm_delete' | null

export default function JourneyTrackerQuestionCard({
  journeyTrackerQuestion,
  onDelete,
}: {
  journeyTrackerQuestion: AdminJourneyTrackerQuestion
  onDelete: (journeyTrackerQuestion: AdminJourneyTrackerQuestion) => void
}) {
  const [modalType, setModalType] = useState<JourneyTrackerQuestionCardModalType>(null)

  const card = (
    <Card
      className={`journey-tracker-question-card ${journeyTrackerQuestion.trackerQuestion.type}`}
      mb={10}
      padding="sm"
      radius="md"
      withBorder
    >
      <TitleWithButton mb={10} title={journeyTrackerQuestion.trackerQuestion.type} order={5}>
        <Button
          className="red outlined delete"
          onClick={async () => {
            setModalType('confirm_delete')
          }}
        >
          delete
        </Button>
      </TitleWithButton>

      <ConfirmModal
        opened={modalType === 'confirm_delete'}
        title="Really delete?"
        message={`Are you sure you want to remove ${journeyTrackerQuestion.trackerQuestion.type} from this journey?`}
        onCancel={() => setModalType(null)}
        onConfirm={async () => {
          try {
            await JourneyTrackerQuestionsAPI.destroy(journeyTrackerQuestion.id)
            onDelete(journeyTrackerQuestion)
          } catch (err) {}
          setModalType(null)
        }}
      />
    </Card>
  )
  return card
}

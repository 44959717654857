import { useState } from 'react'
import PracticesAPI, { AdminPractice, AdminWellnessTagPractice } from '../../api/practices'
import { useNavigate, useParams } from 'react-router-dom'
import { Anchor, Breadcrumbs, Button, Paper, Table } from '@mantine/core'
import { Link } from 'react-router-dom'
import routes from '../../config/routes'
import TitleWithButton from '../../components/title/TitleWithButton'
import ConfirmModal from '../../components/modal/confirm'
import SelectImageModal from '../../components/modal/composite/select-image'
import AddOrEditImage from '../../components/add-or-edit-image'
import FormLocalizationSection from '../../components/forms/form-localization-section'
import useAuthEffect from '../../hooks/useAuthEffect'
import PracticeOnboardingQuestionsCrud from '../../components/crud/practice-onboarding-questions-crud'
import Loader from '../../components/loader'
import PracticeOnboardingQuestionsAPI, {
  PracticeOnboardingQuestion,
} from '../../api/practiceOnboardingQuestions'
import { sortBy } from 'lodash'
import PracticeTrackerQuestionsCrud from '../../components/crud/practice-tracker-questions-crud'
import { AdminPracticeTrackerQuestion } from '../../api/schema'
import PracticeTrackerQuestionsAPI from '../../api/practiceTrackerQuestions'

type PracticeShowModalType = 'delete' | 'add_onboarding_question' | 'add_image'

export default function PracticesShowPage({ practiceId }: { practiceId?: string } = {}) {
  const params = useParams()
  const navigate = useNavigate()
  const [practice, setPractice] = useState<AdminPractice | null>(null)
  const [errorFetchingPractice, setErrorFetchingPractice] = useState(false)
  const [modalType, setModalType] = useState<PracticeShowModalType | null>(null)
  const id = practiceId || params.id!

  async function fetchPractice(id: string) {
    try {
      const practice = await PracticesAPI.show(id)
      setPractice(practice)
    } catch (error) {
      setErrorFetchingPractice(true)
    }
  }

  async function resortOnboardingQuestions(
    journeyOnboardingQuestion: PracticeOnboardingQuestion,
    position: number,
    newOnboardingQuestions: PracticeOnboardingQuestion[]
  ) {
    setPractice({
      ...practice!,
      onboardingQuestions: newOnboardingQuestions,
    })
    await PracticeOnboardingQuestionsAPI.update(journeyOnboardingQuestion.id, { position })
  }

  async function resortPracticeTrackerQuestions(
    practiceTrackerQuestion: AdminPracticeTrackerQuestion,
    position: number,
    newPracticeTrackerQuestions: AdminPracticeTrackerQuestion[]
  ) {
    setPractice({
      ...practice!,
      practiceTrackerQuestions: newPracticeTrackerQuestions,
    })
    await PracticeTrackerQuestionsAPI.update(practiceTrackerQuestion.id, { position })
  }

  // hack until we can get proper drag and drop tests working
  if (process.env.REACT_APP_PSYCHIC_ENV === 'test') {
    // @ts-ignore
    window.__testOnlySimulateOnboardingQuestionDragAndDrop = resortOnboardingQuestions
    // @ts-ignore
    window.__testOnlySimulateTrackerQuestionDragAndDrop = resortPracticeTrackerQuestions
  }

  useAuthEffect(() => {
    // eslint-disable-next-line
    fetchPractice(id)
  })

  if (errorFetchingPractice) return <div>There was an error fetching the practice you requested.</div>
  if (!practice) return <Loader />

  const onboardingQuestions = sortBy(practice.onboardingQuestions, 'position')

  return (
    <div className="page">
      <Breadcrumbs>
        <Anchor component={Link} to={routes.app.practices.index}>
          practices
        </Anchor>
        <Anchor component={Link} to={routes.app.practices.new}>
          {practice.name}
        </Anchor>
      </Breadcrumbs>

      <TitleWithButton title={practice.name}>
        <Button
          className="outlined blue"
          style={{ marginRight: 10 }}
          component={Link}
          to={routes.app.practices.edit(practice.id)}
        >
          edit
        </Button>
        <Button className="outlined red" onClick={() => setModalType('delete')}>
          delete
        </Button>
      </TitleWithButton>

      <AddOrEditImage
        image={practice.image}
        onAddOrEditClick={async () => {
          setModalType('add_image')
        }}
        onRemoveClick={async () => {
          try {
            await PracticesAPI.update(practice.id, { imageId: null })
            await fetchPractice(practice.id)
          } catch (err) {
            // TODO: handle this
          }
        }}
      />

      <FormLocalizationSection
        apiModule={PracticesAPI}
        existingLocalizedTexts={practice.localizedTexts}
        localizableId={practice.id}
        onChange={async _ => {
          await fetchPractice(practice.id)
        }}
        extraFields={['activeSummaryDescription']}
      />

      <Paper mt={20} p="md">
        <Table>
          <tbody>
            <tr>
              <td>Fixed commitment count per day</td>
              <td>{practice.fixedCommitmentCountPerDay}</td>
            </tr>
            <tr>
              <td>Minimum commitment days</td>
              <td>{practice.minCommitmentDays}</td>
            </tr>
            <tr>
              <td>Occasion types</td>
              <td>{practice.occasionTypes?.join(', ')}</td>
            </tr>
            {isWellnessTagPractice(practice) && (
              <tr>
                <td>Wellness tag</td>
                <td>{(practice as AdminWellnessTagPractice).practiceWellnessTag}</td>
              </tr>
            )}
          </tbody>
        </Table>
      </Paper>

      <PracticeOnboardingQuestionsCrud
        practiceId={practice.id}
        practiceOnboardingQuestions={onboardingQuestions}
        onDeleteOnboardingQuestion={async () => {
          await fetchPractice(practice.id)
        }}
        onSelectOnboardingQuestion={async onboardingQuestionType => {
          try {
            await PracticesAPI.createOnboardingQuestion(practice.id, { screen: onboardingQuestionType })
            await fetchPractice(practice.id)
          } catch (err) {
            // TODO: handle this
          }
        }}
        onSort={resortOnboardingQuestions}
      />

      <PracticeTrackerQuestionsCrud
        practiceTrackerQuestions={practice.practiceTrackerQuestions}
        onDeleteTrackerQuestion={async () => {
          await fetchPractice(practice.id)
        }}
        onSelectTrackerQuestion={async trackerQuestion => {
          await PracticesAPI.createPracticeTrackerQuestion(practice.id, {
            trackerQuestionId: trackerQuestion.id,
          })
          await fetchPractice(practice.id)
        }}
        onSort={resortPracticeTrackerQuestions}
      />

      <ConfirmModal
        opened={modalType === 'delete'}
        onCancel={() => setModalType(null)}
        onConfirm={async () => {
          await PracticesAPI.delete(practice.id)
          navigate(routes.app.practices.index)
        }}
        title="Are you sure?"
        message="Are you sure you want to delete this practice?"
        confirmText="Really delete"
      />

      <SelectImageModal
        opened={modalType === 'add_image'}
        onCancel={() => setModalType(null)}
        onSelectImage={async image => {
          setModalType(null)
          try {
            await PracticesAPI.update(practice.id, { imageId: image.id })
            await fetchPractice(practice.id)
          } catch (err) {
            // TODO: handle this
          }
        }}
      />
    </div>
  )
}

function isWellnessTagPractice(practice: AdminPractice) {
  return !!(practice as AdminWellnessTagPractice).practiceWellnessTag
}

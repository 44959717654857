import routes from '../config/routes'
import { api } from './common'
import {
  ChecklistTriggerEventTrigger,
  ChecklistTriggerEventTriggerParams,
} from './supportChecklistTriggerEventTriggers'
import { TriggerableItemChecklistTrigger } from './supportTriggerableItemChecklistTriggers'

export default class ChecklistTriggersAPI {
  public static async create(attrs: ChecklistTriggerParams) {
    return await api.post(routes.api.supports.checklistTriggers.create, { checklistTrigger: attrs })
  }

  public static async update(id: string, attrs: Partial<ChecklistTriggerParams>) {
    return await api.patch(routes.api.supports.checklistTriggers.update(id), { checklistTrigger: attrs })
  }

  public static async delete(id: string) {
    return await api.delete(routes.api.supports.checklistTriggers.delete(id))
  }

  public static async index({ query }: { query?: string } = {}) {
    const res = await api.get(routes.api.supports.checklistTriggers.index, { params: { query } })
    return res.data as ChecklistTrigger[]
  }

  public static async show(id: string) {
    const res = await api.get(routes.api.supports.checklistTriggers.show(id))
    return res.data as ChecklistTrigger
  }

  public static async attachEventTrigger(
    checklistTriggerId: string,
    eventTriggerId: string,
    attrs: Partial<ChecklistTriggerEventTriggerParams>
  ) {
    return await api.post(routes.api.supports.checklistTriggers.eventTriggers.create(checklistTriggerId), {
      checklistTriggerEventTrigger: {
        ...attrs,
        supportEventTriggerId: eventTriggerId,
      },
    })
  }
}

export interface ChecklistTrigger {
  id: string
  type: ChecklistTriggerType
  name: string
  matchEventTriggers: ChecklistTriggerEventTrigger[]
  noMatchEventTriggers: ChecklistTriggerEventTrigger[]
  triggerableItemChecklistTriggers: TriggerableItemChecklistTrigger[]
}

export interface ShortChecklistTrigger {
  id: string
  type: ChecklistTriggerType
  name: string
}

export interface ChecklistTriggerParams {
  name: string
  type: ChecklistTriggerType
}

export type ChecklistTriggerDesiredStatesEnum = 'match' | 'no-match'

export type ChecklistTriggerType = 'DayChecklistTrigger' | 'WeekChecklistTrigger'

export const CHECKLIST_TRIGGER_TYPES = ['DayChecklistTrigger', 'WeekChecklistTrigger']

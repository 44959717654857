import { Anchor, Button, Paper, Table, Text } from '@mantine/core'
import { useState } from 'react'
import { Link } from 'react-router-dom'
import routes from '../../config/routes'
import EventTriggersAPI, { EventTrigger } from '../../api/supportEventTriggers'
import { NavLink } from 'react-router-dom'
import useAuthEffect from '../../hooks/useAuthEffect'
import TitleWithButton from '../../components/title/TitleWithButton'
import Loader from '../../components/loader'

export default function EventTriggersIndexPage() {
  const [eventTriggers, setEventTriggers] = useState<EventTrigger[]>([])
  const [errorFetchingEventTrigger, setErrorFetchingEventTrigger] = useState(false)
  const [doneFetching, setDoneFetching] = useState(false)

  useAuthEffect(() => {
    async function fetchEventTriggers() {
      try {
        const eventTriggers = await EventTriggersAPI.index()
        setEventTriggers(eventTriggers)
        setDoneFetching(true)
      } catch (error) {
        setErrorFetchingEventTrigger(true)
      }
    }

    // eslint-disable-next-line
    fetchEventTriggers()
  })

  return (
    <div className="page">
      <TitleWithButton title="Event Triggers">
        <Button className="filled green" component={Link} to={routes.app.eventTriggers.new}>
          New event trigger
        </Button>
      </TitleWithButton>

      {eventTriggers.length > 0 && (
        <Paper mt={20} p="md">
          <Table>
            <thead>
              <tr>
                <td>Name</td>
              </tr>
            </thead>
            <tbody>
              {eventTriggers.map((eventTrigger, index) => (
                <tr key={index}>
                  <td>
                    <Anchor component={NavLink} to={routes.app.eventTriggers.show(eventTrigger.id)}>
                      {eventTrigger.name}
                    </Anchor>
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
        </Paper>
      )}

      {!doneFetching && <Loader />}

      {doneFetching && eventTriggers.length === 0 && (
        <Text style={{ textAlign: "center" }}>
          <Paper m="lg" p="md">
            No event triggers have been created yet.{' '}
            <Anchor component={Link} to={routes.app.eventTriggers.new}>
              Create one
            </Anchor>{' '}
            to get started.
          </Paper>
        </Text>
      )}
    </div>
  )
}

import { Button, Paper } from '@mantine/core'
import TitleWithButton from '../title/TitleWithButton'
import { ReactNode } from 'react'
import EmptyMessage from '../display/empty-message'
import { Link } from 'react-router-dom'
import routes from '../../config/routes'
import SortableItems from '../sortable-items'
import SupportTrackerTipCard from '../card/support-tracker-tip-card'
import { AdminSupportTrackerTip } from '../../api/schema'

export default function SupportTrackerTipsCrud({
  supportId,
  trackerTips,
  onSort,
  emptyMessage = 'No tracker tips found',
}: {
  supportId: string
  trackerTips: AdminSupportTrackerTip[]
  onSort: (
    trackerTip: AdminSupportTrackerTip,
    position: number,
    newTrackerTips: AdminSupportTrackerTip[]
  ) => void | Promise<void>
  emptyMessage?: ReactNode
}) {
  return (
    <>
      <Paper mt={20} p={20} className="support-tracker-tips-list">
        <TitleWithButton order={2} title="Tracker Tips">
          <Button
            className="green filled"
            component={Link}
            to={routes.app.supports.trackerTips.new(supportId)}
          >
            Add tracker tip
          </Button>
        </TitleWithButton>

        {!trackerTips.length && <EmptyMessage>{emptyMessage}</EmptyMessage>}

        <SortableItems
          itemType="SupportTrackerTip"
          items={trackerTips}
          onSort={onSort}
          render={trackerTip => (
            <SupportTrackerTipCard
              trackerTip={trackerTip}
              href={routes.app.supports.trackerTips.show(supportId, trackerTip.id)}
            />
          )}
        />
      </Paper>
    </>
  )
}

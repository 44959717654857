import { Badge, Button, Card, Group, Image, Text } from '@mantine/core'
import JourneysAPI from '../../api/journeys'
import TitleWithButton from '../title/TitleWithButton'
import { JourneyOnboardingQuestion } from '../../api/journeyOnboardingQuestions'

export default function JourneyOnboardingQuestionCard({
  journeyId,
  journeyOnboardingQuestion,
  onDelete,
}: {
  journeyId: string
  journeyOnboardingQuestion: JourneyOnboardingQuestion
  onDelete: (journeyOnboardingQuestion: JourneyOnboardingQuestion) => void
}) {
  const card = (
    <Card
      className={`journey-question-card ${journeyOnboardingQuestion.screen}`}
      mb={10}
      padding="sm"
      radius="md"
      withBorder
    >
      <TitleWithButton mb={10} title={journeyOnboardingQuestion.screen.replace(/_/g, ' ')} order={5}>
        <Button
          className="red outlined delete"
          onClick={async () => {
            try {
              await JourneysAPI.deleteOnboardingQuestion(journeyId, journeyOnboardingQuestion.id)
              onDelete(journeyOnboardingQuestion)
            } catch (err) {}
          }}
        >
          delete
        </Button>
      </TitleWithButton>
    </Card>
  )
  return card
}

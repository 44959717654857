import routes from '../config/routes'
import { api } from './common'

export default class PracticeTrackerQuestionsAPI {
  public static async update(id: string, attrs: Partial<PracticeTrackerQuestionParams>) {
    return await api.patch(routes.api.supports.practices.practiceTrackerQuestions.update(id), attrs)
  }

  public static async destroy(id: string) {
    return await api.delete(routes.api.supports.practices.practiceTrackerQuestions.delete(id))
  }
}

export interface PracticeTrackerQuestionParams {
  position: number
}

import routes from '../config/routes'
import { api } from './common'

export default class SupportEventVerbsAPI {
  public static async index({ query }: { query?: string } = {}) {
    const res = await api.get(routes.api.supports.eventVerbs.index, { params: { query } })
    return res.data as SupportEventVerb[]
  }
}

export interface SupportEventVerb {
  id: string
  verb: SupportEventVerbsEnum
}

export type SupportEventVerbsEnum =
  | 'exceeded daily goal'
  | 'exceeded daily limit'
  | 'exceeded meal goal'
  | 'exceeded meal limit'
  | 'exceeded snack goal'
  | 'exceeded snack limit'
  | 'exceeded weekly goal'
  | 'exceeded weekly limit'
  | 'fell short of daily goal'
  | 'fell short of meal goal'
  | 'fell short of snack goal'
  | 'fell short of weekly goal'
  | 'logged'
  | 'met daily goal'
  | 'met meal goal'
  | 'met snack goal'
  | 'met weekly goal'
  | 'within daily limit'
  | 'within meal limit'
  | 'within snack limit'
  | 'within weekly limit'

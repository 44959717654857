import { Button, Paper } from '@mantine/core'
import { SupportEventNoun } from '../api/supportEventNouns'
import SupportEventNounCard from './card/support-event-noun-card'
import SelectSupportEventNounModal from './modal/composite/select-support-event-noun'
import { useState } from 'react'

export default function AddOrEditSupportEventNoun({
  supportEventNoun,
  onAddOrEditClick,
  onRemoveClick,
  onSelectSupportEventNoun,
}: {
  supportEventNoun: SupportEventNoun | null
  onAddOrEditClick?: () => Promise<void>
  onSelectSupportEventNoun?: (SupportEventNoun: SupportEventNoun) => void
  onRemoveClick?: () => Promise<void>
}) {
  const [showModal, setShowModal] = useState(false)

  const addOrEditSupportEventNounButton = (
    <Button
      className="outlined blue"
      style={{ marginRight: 10 }}
      fullWidth
      onClick={async () => {
        setShowModal(true)
      }}
    >
      {supportEventNoun ? 'Edit' : 'Add'} noun
    </Button>
  )

  return (
    <div style={{ marginTop: 20 }}>
      {supportEventNoun ? (
        <div style={{ textAlign: 'center' }}>
          <SupportEventNounCard supportEventNoun={supportEventNoun}>
            <>
              {addOrEditSupportEventNounButton}
              <Button
                className="red outlined"
                fullWidth
                mt={10}
                onClick={async () => {
                  await onRemoveClick?.()
                }}
              >
                remove noun
              </Button>
            </>
          </SupportEventNounCard>
        </div>
      ) : (
        <Paper pt={100} pb={100} pl={150} pr={150} style={{ textAlign: 'center' }}>
          <p>No noun set</p>
          {addOrEditSupportEventNounButton}
        </Paper>
      )}

      <SelectSupportEventNounModal
        opened={showModal}
        onCancel={() => setShowModal(false)}
        onSelectSupportEventNoun={async supportEventNoun => {
          setShowModal(false)
          onSelectSupportEventNoun?.(supportEventNoun)
        }}
      />
    </div>
  )
}

import { useCallback, useEffect, useState } from 'react'
import { useAppDispatch, useAppSelector } from '../../../app/hooks'
import { Accordion, Table } from '@mantine/core'
import {
  IconActivity,
  IconAnchor,
  IconApple,
  IconArticle,
  IconNotes,
  IconPyramid,
  IconUser,
} from '@tabler/icons-react'
import UserSupportTable from '../../components/tables/user-support-table'
import InsightsReportsTable from '../../components/tables/insights-reports-table'
import UserJourneysTable from '../../components/tables/user-journeys-table'
import UserProfileTable from '../../components/tables/user-profile-table'
import { store } from '../../../app/stores'
import {
  indexUserActivityLogEntries,
  indexUserInsightsReports,
  indexUserJourneys,
  indexUserSupports,
  indexUserWeightLogEntries,
  lookupUser,
  setWeightLogEntryData,
  setActiveUserSupport,
  setActivityLogEntryData,
  setCompleteUserSupports,
  setCurrentInsightsReport,
  setInsightsReports,
  setNotYetRecommendedUserSupports,
  setRecommendedUserSupports,
  setUser,
  setUserJourneys,
} from '../../../app/stores/users'
import ActivityLogEntryTable from '../../components/tables/activity-log-entry-table'
import WeightLogEntryTable from '../../components/tables/weight-log-entry-table'
import UserNotesTable from '../../components/tables/user-notes-table'

export default function ChromeExtensionUsersShowPage() {
  const dispatch = useAppDispatch()
  const intercomId = useAppSelector(state => state.chromeExtension.intercomId)
  const intercomConversationId = useAppSelector(state => state.chromeExtension.intercomConversationId)
  const defaultAccordionTab = 'profile'
  const [currentAccordion, setCurrentAccordion] = useState<string | null>(defaultAccordionTab)
  const user = useAppSelector(state => state.users.show)
  const userJourneys = useAppSelector(state => state.users.userJourneys)
  const activeUserSupport = useAppSelector(state => state.users.activeUserSupport)
  const completedUserSupports = useAppSelector(state => state.users.completeUserSupports)
  const recommendedUserSupports = useAppSelector(state => state.users.recommendedUserSupports)
  const notYetRecommendedUserSupports = useAppSelector(state => state.users.notYetRecommendedUserSupports)
  const activityLogEntryData = useAppSelector(state => state.users.activityLogEntryData)
  const weightLogEntryData = useAppSelector(state => state.users.weightLogEntryData)
  const insightsReports = useAppSelector(state => state.users.insightsReports)
  const currentInsightsReport = useAppSelector(state => state.users.currentInsightsReport)

  const clearCurrentUserData = (resetAccordion: boolean) => {
    if (resetAccordion) setCurrentAccordion(defaultAccordionTab)
    store.dispatch(setUser(null))
    store.dispatch(setUserJourneys([]))
    store.dispatch(setActivityLogEntryData(null))
    store.dispatch(setWeightLogEntryData(null))
    store.dispatch(setInsightsReports([]))
    store.dispatch(setCurrentInsightsReport(null))
    store.dispatch(setActiveUserSupport(null))
    store.dispatch(setCompleteUserSupports([]))
    store.dispatch(setRecommendedUserSupports([]))
    store.dispatch(setNotYetRecommendedUserSupports([]))
  }

  const lookupIntercomUser = useCallback(
    ({ resetAccordion = true }: { resetAccordion?: boolean } = {}) => {
      if (intercomId) {
        clearCurrentUserData(resetAccordion)
        // eslint-disable-next-line
        dispatch(lookupUser({ intercomId }))
      } else if (intercomConversationId) {
        clearCurrentUserData(resetAccordion)
        // eslint-disable-next-line
        dispatch(lookupUser({ intercomConversationId }))
      } else {
        // unset data, show logo
      }
    },
    [intercomId, intercomConversationId]
  )

  useEffect(() => {
    lookupIntercomUser()
  }, [intercomId, intercomConversationId])

  useEffect(() => {
    switch (currentAccordion) {
      case 'profile':
      case null:
        return

      case 'journeys':
        // eslint-disable-next-line
        if (user) dispatch(indexUserJourneys({ id: user!.id }))
        return

      case 'supports':
        // eslint-disable-next-line
        if (user) dispatch(indexUserSupports({ id: user!.id }))
        return

      case 'tracking-data':
        if (user) {
          // eslint-disable-next-line
          dispatch(indexUserActivityLogEntries({ id: user!.id }))
          // eslint-disable-next-line
          dispatch(indexUserWeightLogEntries({ id: user!.id }))
        }
        return

      case 'insights-reports':
        // eslint-disable-next-line
        if (user) dispatch(indexUserInsightsReports({ id: user!.id }))
        return

      default:
        console.error(`Unhandled value for currentAccordion: ${currentAccordion}`)
    }
  }, [currentAccordion])

  if (!user || (!intercomId && !intercomConversationId))
    return (
      <div className="page" id="dash">
        <div style={{ alignItems: 'center', display: 'flex', justifyContent: 'center', height: '100vh' }}>
          <img src="/img/logo.svg" width={400} />
        </div>
      </div>
    )

  return (
    <div>
      <Accordion
        defaultValue="profile"
        value={currentAccordion}
        onChange={val => {
          setCurrentAccordion(val)
        }}
      >
        <Accordion.Item value="profile">
          <Accordion.Control icon={<IconUser />}>Profile</Accordion.Control>
          <Accordion.Panel>
            <UserProfileTable user={user} />
          </Accordion.Panel>
        </Accordion.Item>

        <Accordion.Item value="journeys">
          <Accordion.Control icon={<IconAnchor />}>Journeys</Accordion.Control>
          <Accordion.Panel>
            <UserJourneysTable
              user={user}
              userJourneys={userJourneys}
              onReset={() => {
                lookupIntercomUser({ resetAccordion: false })

                // eslint-disable-next-line
                dispatch(indexUserJourneys({ id: user!.id }))
              }}
            />
          </Accordion.Panel>
        </Accordion.Item>

        <Accordion.Item value="supports">
          <Accordion.Control icon={<IconPyramid />}>Supports</Accordion.Control>
          <Accordion.Panel>
            <UserSupportTable userSupports={[activeUserSupport]} title="Active support" />
            <UserSupportTable userSupports={completedUserSupports} title="Completed supports" />
            <UserSupportTable userSupports={recommendedUserSupports} title="Recommended supports" />
            <UserSupportTable
              userSupports={notYetRecommendedUserSupports}
              title="Not yet recommended supports"
            />
          </Accordion.Panel>
        </Accordion.Item>

        <Accordion.Item value="tracking-data">
          <Accordion.Control icon={<IconArticle />}>Tracking Data</Accordion.Control>
          <Accordion.Panel>
            <ActivityLogEntryTable user={user} activityLogEntryData={activityLogEntryData} />
            <WeightLogEntryTable user={user} weightLogEntryData={weightLogEntryData} />
          </Accordion.Panel>
        </Accordion.Item>

        <Accordion.Item value="insights-reports">
          <Accordion.Control icon={<IconActivity />}>Insights</Accordion.Control>
          <Accordion.Panel>
            <InsightsReportsTable
              user={user}
              insightsReports={insightsReports}
              currentInsightsReport={currentInsightsReport}
            />
          </Accordion.Panel>
        </Accordion.Item>

        <Accordion.Item value="meal_planner">
          <Accordion.Control icon={<IconApple />}>Meal Planner</Accordion.Control>
          <Accordion.Panel>
            <Table>
              <tbody></tbody>
            </Table>
          </Accordion.Panel>
        </Accordion.Item>

        <Accordion.Item value="notes">
          <Accordion.Control icon={<IconNotes />}>Notes</Accordion.Control>
          <Accordion.Panel>
            <UserNotesTable user={user} />
          </Accordion.Panel>
        </Accordion.Item>
      </Accordion>
    </div>
  )
}

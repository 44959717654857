import { useEffect, useState } from 'react'
import FormGroup from '../../../components/forms/utils/form-group'
import { Anchor, Breadcrumbs, Button, Select, Title } from '@mantine/core'
import routes from '../../../config/routes'
import { useNavigate, useParams } from 'react-router-dom'
import FormButtonGroup from '../../../components/forms/utils/form-button-group'
import { Link } from 'react-router-dom'
import SupportCoachTipsAPI, {
  SupportCoachTipType,
  SUPPORT_COACH_TIP_TYPES,
} from '../../../api/supportCoachTips'
import SupportsAPI from '../../../api/supports'
import useAuthEffect from '../../../hooks/useAuthEffect'
import Loader from '../../../components/loader'
import { AdminSupport } from '../../../api/schema'

export default function SupportsCoachTipsNewPage() {
  const navigate = useNavigate()
  const params = useParams()
  const [support, setSupport] = useState<AdminSupport | null>(null)
  const [errorFetchingSupport, setErrorFetchingSupport] = useState(false)
  const [name, setName] = useState('')
  const [nameErrors, setNameErrors] = useState<string[]>([])
  const [coachTipType, setCoachTipType] = useState<SupportCoachTipType>('SupportTextCoachTip')
  const [coachTipTypeErrors, setCoachTipTypeErrors] = useState<string[]>([])

  async function fetchSupport(id: string) {
    try {
      const support = await SupportsAPI.show(id)
      setSupport(support)
    } catch (error) {
      setErrorFetchingSupport(true)
    }
  }

  useAuthEffect(() => {
    // eslint-disable-next-line
    fetchSupport(params.supportId!)
  })

  if (!support) return <Loader />
  if (errorFetchingSupport) return <div>There was an error fetching the support you requested.</div>

  return (
    <div className="page">
      <Breadcrumbs>
        <Anchor component={Link} to={routes.app.supports.index}>
          supports
        </Anchor>
        <Anchor component={Link} to={routes.app.supports.show(support.id)}>
          {support.name}
        </Anchor>
        <Anchor component={Link} to={routes.app.supports.coachTips.new(support.id)}>
          new coach tip
        </Anchor>
      </Breadcrumbs>

      <Title>Create a Coach Tip</Title>

      <form
        id="new-coach-tip-form"
        onSubmit={async event => {
          event.preventDefault()
          try {
            const res = await SupportCoachTipsAPI.create(support.id, { name, type: coachTipType })
            const coachTipId = res.data
            navigate(routes.app.supports.coachTips.show(support.id, coachTipId))
          } catch (err: any) {
            if (Array.isArray(err.name)) setNameErrors(err.name)
            if (Array.isArray(err.type)) setCoachTipTypeErrors(err.type)
          }
        }}
      >
        <FormGroup
          required
          id="name"
          label="name"
          name="name"
          value={name}
          errors={nameErrors}
          onChange={val => {
            setName(val)
            setNameErrors([])
          }}
        />

        <FormGroup
          required
          inputType="select"
          id="support-coach-tip-type"
          label="coach tip type"
          name="type"
          value={coachTipType}
          errors={coachTipTypeErrors}
          options={Object.keys(SUPPORT_COACH_TIP_TYPES).map(key => ({
            label: key,
            value: (SUPPORT_COACH_TIP_TYPES as any)[key],
          }))}
          onChange={val => {
            setCoachTipType(val)
            setCoachTipTypeErrors([])
          }}
        />

        <FormButtonGroup>
          <Button type="submit">Create</Button>
        </FormButtonGroup>
      </form>
    </div>
  )
}

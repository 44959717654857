import { Anchor, Breadcrumbs, Title } from '@mantine/core'
import routes from '../../config/routes'
import { Link } from 'react-router-dom'
import ChecklistTriggerForm from '../../components/forms/checklist-trigger-form'

export default function ChecklistTriggersNewPage() {
  return (
    <div className="page">
      <Breadcrumbs>
        <Anchor component={Link} to={routes.app.checklistTriggers.index}>
          checklist triggers
        </Anchor>
        <Anchor component={Link} to={routes.app.checklistTriggers.new}>
          new
        </Anchor>
      </Breadcrumbs>

      <Title>Create a checklist trigger</Title>

      <ChecklistTriggerForm />
    </div>
  )
}

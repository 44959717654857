import { Anchor, Button, Paper, Text, Title } from '@mantine/core'
import routes from '../../config/routes'
import { NavLink } from 'react-router-dom'
import lowerEnvironmentType from '../../helpers/lowerEnvironmentType'
import SystemApi from '../../api/system'

export default function SystemIndexPage() {
  return (
    <div className="page">
      <Title order={1} mb={20}>
        System Actions
      </Title>

      <Paper mt={20} p="md">
        <Text style={{ fontWeight: 500 }}>
          {lowerEnvironmentType() === 'NO_LOWER_ENV' &&
          process.env.REACT_APP_PSYCHIC_ENV !== 'test' ? null : (
            <Anchor component={NavLink} to={routes.app.system.portContent}>
              Content Port
            </Anchor>
          )}
        </Text>

        <Text mt={20} style={{ fontWeight: 500 }}>
          <Button
            onClick={async () => {
              const url = await SystemApi.signIntoCentralBullboard()
              window.location.href = url
            }}
          >
            Central Bull Board
          </Button>
        </Text>
      </Paper>
    </div>
  )
}

import type { PayloadAction } from '@reduxjs/toolkit'
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import AdminUsersAPI from '../api/adminUsers'
import { UserContentTreeJourney } from '../api/userJourneys'
import UserNotesAPI, { UserNotesParams } from '../api/userNotes'
import UsersAPI, {
  ActivityResponse,
  InsightsReportDetail,
  InsightsReportSummary,
  User,
  UserSupportsResponse,
  WeightResponse,
} from '../api/users'
import { UserSupport } from '../api/userSupports'

export interface UserState {
  index: User[]
  show: User | null
  adminUserRoles: string[]
  userJourneys: UserContentTreeJourney[]
  activeUserSupport: UserSupport | null
  completeUserSupports: UserSupport[]
  recommendedUserSupports: UserSupport[]
  notYetRecommendedUserSupports: UserSupport[]
  activityLogEntryData: ActivityResponse | null
  weightLogEntryData: WeightResponse | null
  currentInsightsReport: InsightsReportDetail | null
  insightsReports: InsightsReportSummary[]
}

const initialState: UserState = {
  index: [],
  show: null,
  adminUserRoles: [],
  userJourneys: [],
  activeUserSupport: null,
  completeUserSupports: [],
  recommendedUserSupports: [],
  notYetRecommendedUserSupports: [],
  activityLogEntryData: null,
  weightLogEntryData: null,
  currentInsightsReport: null,
  insightsReports: [],
}

export const indexUsers = createAsyncThunk(
  'Users#index',
  async ({ query }: { query?: string } = {}) => await UsersAPI.index({ query })
)

export const showUser = createAsyncThunk('Users#show', async (id: string) => await UsersAPI.show(id))

export const showAdminUser = createAsyncThunk('AdminUsers#me', async () => {
  try {
    return await AdminUsersAPI.me()
  } catch {
    // intentional no-op, since this request is canceled and forwarded to chrome extension
    return null
  }
})

export const lookupUser = createAsyncThunk(
  'Users#lookup',
  async ({
    intercomId,
    intercomConversationId,
  }: { intercomId?: string; intercomConversationId?: string } = {}) => {
    try {
      return await UsersAPI.lookup({ intercomId, intercomConversationId })
    } catch {
      // intentional no-op, since this request is canceled and forwarded to chrome extension
      return null
    }
  }
)

export const indexUserJourneys = createAsyncThunk('Users::Journeys#index', async ({ id }: { id: string }) => {
  try {
    return await UsersAPI.journeys(id)
  } catch {
    // intentional no-op, since this request is canceled and forwarded to chrome extension
    return []
  }
})

export const updateUserNotes = createAsyncThunk(
  'Users::Notes#update',
  async ({ userId, opts }: { userId: string; opts: Partial<UserNotesParams> }) => {
    try {
      return await UserNotesAPI.update(userId, opts)
    } catch {
      // intentional no-op, since this request is canceled and forwarded to chrome extension
      return null
    }
  }
)

export const indexUserSupports = createAsyncThunk('Users::Supports#index', async ({ id }: { id: string }) => {
  try {
    return await UsersAPI.supports(id)
  } catch {
    // intentional no-op, since this request is canceled and forwarded to chrome extension
    return {
      active: null,
      complete: [],
      recommended: [],
      notYetRecommended: [],
    } as UserSupportsResponse
  }
})

export const indexUserActivityLogEntries = createAsyncThunk(
  'Users::Tracker::ActivityLogEntries#index',
  async ({ id }: { id: string }) => {
    try {
      return await UsersAPI.activityLogEntries(id)
    } catch {
      // intentional no-op, since this request is canceled and forwarded to chrome extension
      return null
    }
  }
)

export const indexUserWeightLogEntries = createAsyncThunk(
  'Users::Tracker::WeightLogEntries#index',
  async ({ id }: { id: string }) => {
    try {
      return await UsersAPI.weightLogEntries(id)
    } catch {
      // intentional no-op, since this request is canceled and forwarded to chrome extension
      return null
    }
  }
)

export const indexUserInsightsReports = createAsyncThunk(
  'Users::Tracker::InsightsReports#index',
  async ({ id }: { id: string }) => {
    try {
      return await UsersAPI.insightsReports(id)
    } catch {
      // intentional no-op, since this request is canceled and forwarded to chrome extension
      return []
    }
  }
)

export const showUserInsightsReport = createAsyncThunk(
  'Users::Tracker::InsightsReports#show',
  async ({ id, insightsReportId }: { id: string; insightsReportId: string }) => {
    try {
      const res = await UsersAPI.insightsReport(id, insightsReportId)
      return res
    } catch {
      // intentional no-op, since this request is canceled and forwarded to chrome extension
      return null
    }
  }
)

const usersSlice = createSlice({
  name: 'users',
  initialState,
  reducers: {
    setUser(state, data: PayloadAction<User | null>) {
      state.show = data.payload
    },

    setUsers(state, data: PayloadAction<User[]>) {
      state.index = data.payload
    },

    setAdminUserRoles(state, data: PayloadAction<string[]>) {
      state.adminUserRoles = data.payload
    },

    setUserJourneys(state, data: PayloadAction<UserContentTreeJourney[]>) {
      state.userJourneys = data.payload
    },

    setCompleteUserSupports(state, data: PayloadAction<UserSupport[]>) {
      state.completeUserSupports = data.payload
    },

    setRecommendedUserSupports(state, data: PayloadAction<UserSupport[]>) {
      state.recommendedUserSupports = data.payload
    },

    setNotYetRecommendedUserSupports(state, data: PayloadAction<UserSupport[]>) {
      state.notYetRecommendedUserSupports = data.payload
    },

    setActiveUserSupport(state, data: PayloadAction<UserSupport | null>) {
      state.activeUserSupport = data.payload
    },

    setActivityLogEntryData(state, data: PayloadAction<ActivityResponse | null>) {
      state.activityLogEntryData = data.payload
    },

    setWeightLogEntryData(state, data: PayloadAction<WeightResponse | null>) {
      state.weightLogEntryData = data.payload
    },

    setInsightsReports(state, data: PayloadAction<InsightsReportSummary[]>) {
      state.insightsReports = data.payload
    },

    setCurrentInsightsReport(state, data: PayloadAction<InsightsReportDetail | null>) {
      state.currentInsightsReport = data.payload
    },
  },
  extraReducers: builder => {
    builder.addCase(indexUsers.fulfilled, (state, action) => {
      state.index = action.payload
    })

    builder.addCase(showUser.fulfilled, (state, action) => {
      state.show = action.payload
    })

    builder.addCase(lookupUser.fulfilled, (state, action) => {
      state.show = action.payload
    })

    builder.addCase(indexUserJourneys.fulfilled, (state, action) => {
      state.userJourneys = action.payload!
    })

    builder.addCase(indexUserActivityLogEntries.fulfilled, (state, action) => {
      state.activityLogEntryData = action.payload!
    })

    builder.addCase(indexUserWeightLogEntries.fulfilled, (state, action) => {
      state.weightLogEntryData = action.payload!
    })

    builder.addCase(indexUserInsightsReports.fulfilled, (state, action) => {
      state.insightsReports = action.payload!
    })

    builder.addCase(showUserInsightsReport.fulfilled, (state, action) => {
      state.currentInsightsReport = action.payload!
    })

    builder.addCase(indexUserSupports.fulfilled, (state, action) => {
      state.completeUserSupports = action.payload.complete
      state.activeUserSupport = action.payload.active
      state.recommendedUserSupports = action.payload.recommended
      state.notYetRecommendedUserSupports = action.payload.notYetRecommended
    })
  },
})

export const {
  setUser,
  setUsers,
  setAdminUserRoles,
  setUserJourneys,
  setActiveUserSupport,
  setCompleteUserSupports,
  setNotYetRecommendedUserSupports,
  setRecommendedUserSupports,
  setActivityLogEntryData,
  setWeightLogEntryData,
  setInsightsReports,
  setCurrentInsightsReport,
} = usersSlice.actions
export default usersSlice.reducer

import { api } from './common'

export default class OpsApi {
  static status() {
    return api.get('/ops/status')
  }

  static triggerBackgroundError() {
    return api.get('/ops/background-errors')
  }
}

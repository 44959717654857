import { Anchor, Card, Group, Text } from '@mantine/core'
import { Link } from 'react-router-dom'
import { SupportCoachTip } from '../../api/supportCoachTips'
import { IconNote, IconVideo } from '@tabler/icons-react'

export default function SupportCoachTipCard({
  coachTip,
  href,
}: {
  coachTip: SupportCoachTip
  onClick?: () => void
  href?: string
}) {
  const iconStyle: any = { position: 'relative', top: 8, marginRight: 5 }
  return (
    <Card className={`coach-tip-card-${coachTip.id}`} mb={10} padding="sm" radius="md" withBorder>
      <Group mt="md" mb="xs">
        {href ? (
          <Anchor component={Link} to={href} style={{ verticalAlign: 'middle' }}>
            {coachTip.type === 'SupportTextCoachTip' && <IconNote style={iconStyle} />}
            {coachTip.type === 'SupportVideoCoachTip' && <IconVideo style={iconStyle} />}
            <Text
              style={{
                fontWeight: 500,
                verticalAlign: 'middle',
                display: 'inline-block',
                marginLeft: 'auto',
              }}
            >
              {coachTip.name}
            </Text>
          </Anchor>
        ) : (
          <Text style={{ fontWeight: 500 }}>{coachTip.name}</Text>
        )}
      </Group>
    </Card>
  )
}

import { useState, useEffect } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { Anchor, Breadcrumbs, Button } from '@mantine/core'
import { Link } from 'react-router-dom'
import routes from '../../../config/routes'
import TitleWithButton from '../../../components/title/TitleWithButton'
import ConfirmModal from '../../../components/modal/confirm'
import TriggerableItemChecklistTriggersAPI, {
  TriggerableItemChecklistTrigger,
} from '../../../api/supportTriggerableItemChecklistTriggers'
import SupportsAPI, { Support } from '../../../api/supports'
import useAuthEffect from '../../../hooks/useAuthEffect'
import Loader from '../../../components/loader'

export default function SupportTriggerableItemChecklistTriggersShowPage() {
  const params = useParams()
  const navigate = useNavigate()
  const [supportTriggerableItemChecklistTrigger, setTriggerableItemChecklistTrigger] =
    useState<TriggerableItemChecklistTrigger | null>(null)
  const [errorFetchingTriggerableItemChecklistTrigger, setErrorFetchingTriggerableItemChecklistTrigger] =
    useState(false)
  const [showDeleteModal, setShowDeleteModal] = useState(false)

  async function fetchTriggerableItemChecklistTrigger(id: string) {
    try {
      const supportTriggerableItemChecklistTrigger = await TriggerableItemChecklistTriggersAPI.show(id)
      setTriggerableItemChecklistTrigger(supportTriggerableItemChecklistTrigger)
    } catch (error) {
      setErrorFetchingTriggerableItemChecklistTrigger(true)
    }
  }

  useAuthEffect(() => {
    // eslint-disable-next-line
    fetchTriggerableItemChecklistTrigger(params.id!)
  })

  if (errorFetchingTriggerableItemChecklistTrigger)
    return <div>There was an error fetching the attached checklist trigger you requested.</div>
  if (!supportTriggerableItemChecklistTrigger) return <Loader />

  const support = supportTriggerableItemChecklistTrigger.triggerableItem!

  return (
    <div className="page">
      <Breadcrumbs>
        <Anchor component={Link} to={routes.app.supports.index}>
          supports
        </Anchor>
        <Anchor component={Link} to={routes.app.supports.show(support.id)}>
          {support.name}
        </Anchor>
        <Anchor
          component={Link}
          to={routes.app.supports.triggerableItemChecklistTriggers.show(
            supportTriggerableItemChecklistTrigger.id
          )}
        >
          {supportTriggerableItemChecklistTrigger.checklistTrigger.name}
        </Anchor>
      </Breadcrumbs>

      <TitleWithButton title={supportTriggerableItemChecklistTrigger.checklistTrigger.name}>
        <Button onClick={() => setShowDeleteModal(true)} color="red" variant="outline">
          detach from support
        </Button>
      </TitleWithButton>

      <ConfirmModal
        opened={showDeleteModal}
        onCancel={() => setShowDeleteModal(false)}
        onConfirm={async () => {
          await SupportsAPI.detachChecklistTrigger(supportTriggerableItemChecklistTrigger.id)
          navigate(routes.app.supports.show(support.id))
        }}
        title="Are you sure?"
        message="Are you sure you want to detach this checklist trigger from this support?"
        confirmText="Really detach?"
      />
    </div>
  )
}

import routes from '../config/routes'
import { api } from './common'

export default class JourneyOnboardingQuestionsAPI {
  public static async update(id: string, attrs: Partial<JourneyOnboardingQuestionParams>) {
    return await api.patch(routes.api.journeys.onboardingQuestions.update(id), {
      journeyOnboardingQuestion: attrs,
    })
  }
}

export interface JourneyOnboardingQuestion {
  id: string
  position: number
  screen: JourneyOnboardingQuestionType
}

export const JourneyOnboardingQuestionTypes: JourneyOnboardingQuestionType[] = [
  'activity_level',
  'biological_gender',
  'birthdate',
  'desired_weight_outcome',
  'health_goals',
  'height_and_weight',
  'weight_loss_goal_details',
]

export type JourneyOnboardingQuestionType =
  | 'activity_level'
  | 'biological_gender'
  | 'birthdate'
  | 'desired_weight_outcome'
  | 'health_goals'
  | 'height_and_weight'
  | 'weight_loss_goal_details'

export interface JourneyOnboardingQuestionParams {
  position: number
}

import { Badge, Button, Card, Group, Image, Text } from '@mantine/core'
import PracticesAPI from '../../api/practices'
import TitleWithButton from '../title/TitleWithButton'
import { PracticeOnboardingQuestion } from '../../api/practiceOnboardingQuestions'

export default function PracticeOnboardingQuestionCard({
  practiceId,
  practiceOnboardingQuestion,
  onDelete,
}: {
  practiceId: string
  practiceOnboardingQuestion: PracticeOnboardingQuestion
  onDelete: (practiceOnboardingQuestion: PracticeOnboardingQuestion) => void
}) {
  const card = (
    <Card
      className={`practice-question-card ${practiceOnboardingQuestion.screen}`}
      mb={10}
      padding="sm"
      radius="md"
      withBorder
    >
      <TitleWithButton title={practiceOnboardingQuestion.screen.replace(/_/g, ' ')} order={5}>
        <Button
          className="delete"
          color="red"
          variant="outline"
          onClick={async () => {
            try {
              await PracticesAPI.deleteOnboardingQuestion(practiceId, practiceOnboardingQuestion.id)
              onDelete(practiceOnboardingQuestion)
            } catch (err) {}
          }}
        >
          delete
        </Button>
      </TitleWithButton>
    </Card>
  )
  return card
}

import { Badge, Button, Card, Group, Image, Text } from '@mantine/core'
import { Phase } from '../../api/phases'

export default function PhaseCard({ phase, onClick }: { phase: Phase; onClick?: () => void }) {
  const card = (
    <Card onClick={onClick} mb={10} padding="sm" radius="md" withBorder>
      <Group mt="md" mb="xs">
        <Text style={{ fontWeight: 500, marginLeft: 'auto' }}>{phase.name}</Text>
        <Badge color={phase.foundation ? 'indigo' : 'cyan'} variant="light">
          foundation: {phase.foundation ? 'true' : 'false'}
        </Badge>
      </Group>
      <Text style={{ marginLeft: 'auto' }} size="xs" color="gray">
        Number of parts: {phase.phaseParts?.length}
      </Text>
    </Card>
  )
  return onClick ? <button onClick={onClick}>{card}</button> : card
}

import { useState } from 'react'
import LessonResourcesAPI, { LessonResource } from '../../api/lessonResources'
import { useNavigate, useParams } from 'react-router-dom'
import { Anchor, Breadcrumbs, Button, Paper, Table } from '@mantine/core'
import { Link } from 'react-router-dom'
import routes from '../../config/routes'
import TitleWithButton from '../../components/title/TitleWithButton'
import ConfirmModal from '../../components/modal/confirm'
import AddOrEditImage from '../../components/add-or-edit-image'
import FormLocalizationSection from '../../components/forms/form-localization-section'
import SelectImageModal from '../../components/modal/composite/select-image'
import useAuthEffect from '../../hooks/useAuthEffect'
import { NavLink } from 'react-router-dom'
import Loader from '../../components/loader'

type LessonResourcesShowModalType = 'delete' | 'add_image' | null

export default function LessonResourcesShowPage({ lessonResourceId }: { lessonResourceId?: string } = {}) {
  const params = useParams()
  const navigate = useNavigate()
  const [lessonResource, setLessonResource] = useState<LessonResource | null>(null)
  const [errorFetchingLessonResource, setErrorFetchingLessonResource] = useState(false)
  const [modalType, setModalType] = useState<LessonResourcesShowModalType>(null)
  const id = lessonResourceId || params.id!

  async function fetchLessonResource(id: string) {
    try {
      const lessonResource = await LessonResourcesAPI.show(id)
      setLessonResource(lessonResource)
    } catch (error) {
      setErrorFetchingLessonResource(true)
    }
  }

  useAuthEffect(() => {
    // eslint-disable-next-line
    fetchLessonResource(id)
  })

  if (errorFetchingLessonResource)
    return <div>There was an error fetching the lesson resource you requested.</div>
  if (!lessonResource) return <Loader />

  return (
    <div className="page">
      <Breadcrumbs>
        <Anchor component={Link} to={routes.app.lessonResources.index}>
          lesson resources
        </Anchor>
        <Anchor component={Link} to={routes.app.lessonResources.new}>
          {lessonResource.name}
        </Anchor>
      </Breadcrumbs>

      <TitleWithButton title={lessonResource.name}>
        <Button
          className="outlined blue"
          style={{ marginRight: 10 }}
          component={Link}
          to={routes.app.lessonResources.edit(lessonResource.id)}
        >
          edit
        </Button>

        {lessonResource?.url && (
          <Button
            className="outlined blue"
            component={Link}
            to={lessonResource.url}
            target="_blank"
            style={{ marginRight: 10 }}
          >
            preview
          </Button>
        )}

        <Button className="outlined red" onClick={() => setModalType('delete')}>
          delete
        </Button>
      </TitleWithButton>

      <Paper mb={20} p={20}>
        <Table>
          <tbody>
            {[
              <tr key={1}>
                <td>Approximate Minutes:</td>
                <td style={{ textAlign: 'right' }}>{lessonResource.approximateMinutes}</td>
              </tr>,
              <tr key={1}>
                <td>External Id:</td>
                <td style={{ textAlign: 'right' }}>{lessonResource.externalId}</td>
              </tr>,
              lessonResource.url && (
                <tr key={1}>
                  <td>url:</td>
                  <td style={{ textAlign: 'right' }}>
                    <Anchor target={'_blank'} component={NavLink} to={lessonResource.url}>
                      {lessonResource.url}
                    </Anchor>
                  </td>
                </tr>
              ),
            ]}
          </tbody>
        </Table>
      </Paper>

      <AddOrEditImage
        image={lessonResource.image}
        onAddOrEditClick={async () => {
          setModalType('add_image')
        }}
        onRemoveClick={async () => {
          try {
            await LessonResourcesAPI.update(lessonResource.id, { imageId: null })
            await fetchLessonResource(lessonResource.id)
          } catch (err) {
            // TODO: handle this
          }
        }}
      />

      <FormLocalizationSection
        apiModule={LessonResourcesAPI}
        existingLocalizedTexts={lessonResource.localizedTexts}
        localizableId={lessonResource.id}
        onChange={async newLocale => {
          await fetchLessonResource(lessonResource.id)
        }}
        excludedFields={['description', 'summaryDescription']}
      />

      <ConfirmModal
        opened={modalType === 'delete'}
        onCancel={() => setModalType(null)}
        onConfirm={async () => {
          await LessonResourcesAPI.delete(lessonResource.id)
          navigate(routes.app.lessonResources.index)
        }}
        title="Are you sure?"
        message="Are you sure you want to delete this lesson resource?"
        confirmText="Really delete"
      />

      <SelectImageModal
        opened={modalType === 'add_image'}
        onCancel={() => setModalType(null)}
        onSelectImage={async image => {
          setModalType(null)
          try {
            await LessonResourcesAPI.update(lessonResource.id, { imageId: image.id })
            await fetchLessonResource(lessonResource.id)
          } catch (err) {
            // TODO: handle this
          }
        }}
      />
    </div>
  )
}

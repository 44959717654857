import { Anchor, Card, Group, Text } from '@mantine/core'
import { Link } from 'react-router-dom'
import { ReactNode } from 'react'
import { AdminSupportShortChecklistTrigger } from '../../api/schema'

export default function SupportChecklistTriggerCard({
  supportChecklistTrigger,
  href,
  children,
}: {
  supportChecklistTrigger: AdminSupportShortChecklistTrigger
  onClick?: () => void
  href?: string
  children?: ReactNode
}) {
  return (
    <Card
      className={`support-checklist-trigger-card-${supportChecklistTrigger.id}`}
      mb={10}
      padding="sm"
      radius="md"
      withBorder
    >
      <Group mt="md" mb="xs">
        {href ? (
          <Anchor component={Link} to={href} style={{ verticalAlign: 'middle' }}>
            <Text
              style={{
                fontWeight: 500,
                verticalAlign: 'middle',
                display: 'inline-block',
                marginLeft: 'auto',
              }}
            >
              {supportChecklistTrigger.name}
            </Text>
          </Anchor>
        ) : (
          <Text style={{ fontWeight: 500, marginLeft: 'auto' }}>{supportChecklistTrigger.name}</Text>
        )}
        <Card.Section p={20}>{children}</Card.Section>
      </Group>
    </Card>
  )
}

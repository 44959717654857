import { useEffect, useState } from 'react'
import EventTriggersAPI, { EventTrigger } from '../../api/supportEventTriggers'
import routes from '../../config/routes'
import { useNavigate } from 'react-router-dom'
import FormGroup from './utils/form-group'
import FormButtonGroup from './utils/form-button-group'
import { Button, Paper, Progress } from '@mantine/core'
import axios from 'axios'
import FileInput from './utils/file-input'
import AddOrEditSupportEventVerb from '../add-or-edit-verb'
import { SupportEventVerb } from '../../api/supportEventVerbs'
import { SupportEventNoun } from '../../api/supportEventNouns'
import { SupportEventModifier } from '../../api/supportEventModifiers'
import AddOrEditSupportEventNoun from '../add-or-edit-noun'
import AddOrEditSupportEventModifier from '../add-or-edit-modifier'
import occasionTypes from '../../config/occasionTypes'

export default function EventTriggerForm({ eventTrigger }: { eventTrigger?: EventTrigger }) {
  const navigate = useNavigate()
  const [name, setName] = useState(eventTrigger?.name || '')
  const [nameErrors, setNameErrors] = useState<string[]>([])
  const [verb, setVerb] = useState<SupportEventVerb | null>(eventTrigger?.verb || null)
  const [noun, setNoun] = useState<SupportEventNoun | null>(eventTrigger?.noun || null)
  const [modifier, setModifier] = useState<SupportEventModifier | null>(eventTrigger?.modifier || null)
  const formId = eventTrigger ? 'edit-event-trigger-form' : 'new-event-trigger-form'

  const hasChanges = eventTrigger
    ? name !== eventTrigger.name ||
      verb?.id !== eventTrigger.verb.id ||
      noun?.id !== eventTrigger.noun?.id ||
      modifier?.id !== eventTrigger.modifier?.id
    : true

  useEffect(() => {
    if (verb?.verb !== 'logged' && modifier && occasionTypes.includes(modifier.modifier)) {
      setModifier(null)
    }
  }, [verb])

  return (
    <form
      id={formId}
      onSubmit={async event => {
        event.preventDefault()

        try {
          if (eventTrigger) {
            await EventTriggersAPI.update(eventTrigger.id, {
              name,
              verbId: verb!.id,
              modifierId: modifier?.id,
              nounId: noun?.id,
            })
            navigate(routes.app.eventTriggers.show(eventTrigger.id))
          } else {
            const res = await EventTriggersAPI.create({
              name,
              verbId: verb!.id,
              modifierId: modifier?.id,
              nounId: noun?.id,
            })
            navigate(routes.app.eventTriggers.show(res.data))
          }
        } catch (err: any) {
          if (Array.isArray(err.name)) setNameErrors(err.name)
        }
      }}
    >
      <FormGroup
        required
        id="name"
        label="name"
        name="name"
        value={name}
        errors={nameErrors}
        onChange={val => {
          setName(val)
          setNameErrors([])
        }}
      />

      <AddOrEditSupportEventVerb
        supportEventVerb={verb}
        onSelectSupportEventVerb={verb => {
          setVerb(verb)
        }}
      />

      <AddOrEditSupportEventNoun
        supportEventNoun={noun}
        onSelectSupportEventNoun={noun => {
          setNoun(noun)
        }}
      />

      <AddOrEditSupportEventModifier
        supportEventModifier={modifier}
        verb={verb}
        onSelectSupportEventModifier={modifier => {
          setModifier(modifier)
        }}
      />

      <FormButtonGroup>
        <Button className="filled green" disabled={!hasChanges} type="submit">
          {eventTrigger ? 'Save' : 'Create'}
        </Button>
      </FormButtonGroup>
    </form>
  )
}

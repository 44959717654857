import { Anchor, Breadcrumbs, Title } from '@mantine/core'
import { useEffect, useState } from 'react'
import { Link, useNavigate, useParams } from 'react-router-dom'
import ImagesAPI, { Image } from '../../api/images'
import routes from '../../config/routes'
import ImageForm from '../../components/forms/image-form'
import useAuthEffect from '../../hooks/useAuthEffect'
import Loader from '../../components/loader'

export default function ImagesEditPage() {
  const params = useParams()
  const [image, setImage] = useState<Image | null>(null)
  const [errorFetchingImage, setErrorFetchingImage] = useState(false)

  useAuthEffect(() => {
    async function fetchImage(id: string) {
      try {
        const image = await ImagesAPI.show(id)
        setImage(image)
      } catch (error) {
        setErrorFetchingImage(true)
      }
    }

    // eslint-disable-next-line
    fetchImage(params.id!)
  })

  if (!image) return <Loader />

  return (
    <div className="page">
      <Breadcrumbs>
        <Anchor component={Link} to={routes.app.images.index}>
          images
        </Anchor>
        <Anchor component={Link} to={routes.app.images.show(image.id)}>
          {image.name}
        </Anchor>
        <Anchor component={Link} to={routes.app.images.edit(image.id)}>
          edit
        </Anchor>
      </Breadcrumbs>

      <Title>Edit {image.name}</Title>
      <ImageForm image={image} />
    </div>
  )
}

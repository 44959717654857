import { Anchor, Card, Group, Text } from '@mantine/core'
import { Link } from 'react-router-dom'
import { AdminSupport } from '../../api/schema'

export default function SupportCard({
  support,
  onClick,
  href,
}: {
  support: AdminSupport
  onClick?: () => void
  href?: string
}) {
  return (
    <Card
      className={`support-card-${support.id}`}
      onClick={onClick}
      mb={10}
      padding="sm"
      radius="md"
      withBorder
    >
      <Group mt="md" mb="xs">
        {href ? (
          <Anchor component={Link} to={href}>
            <Text style={{ fontWeight: 500, marginLeft: 'auto' }}>{support.name}</Text>
          </Anchor>
        ) : (
          <Text style={{ fontWeight: 500, marginLeft: 'auto' }}>{support.name}</Text>
        )}
      </Group>
      <Text style={{ marginLeft: 'auto' }} size="xs" color="gray">
        focus nutrient: {support.supportFocusNutrient}
      </Text>
    </Card>
  )
}

import { Modal, Text, TextInput } from '@mantine/core'
import { useEffect, useState } from 'react'
import { AdminTrackerQuestion, TrackerQuestionAssociationTypesEnum } from '../../../api/schema'
import TrackerQuestionsAPI from '../../../api/trackerQuestions'
import TrackerQuestionCard from '../../card/tracker-question-card'

export default function SelectTrackerQuestionModal({
  opened,
  onCancel,
  selectedTrackerQuestionIds = [],
  associationType,
  onSelectTrackerQuestion,
  title = 'Select a tracker question',
}: {
  opened: boolean
  onSelectTrackerQuestion: (trackerQuestion: AdminTrackerQuestion) => void
  onCancel: () => void
  associationType?: TrackerQuestionAssociationTypesEnum
  selectedTrackerQuestionIds?: string[]
  title?: string
  confirmText?: string
  cancelText?: string
}) {
  const [query, setQuery] = useState('')
  const [trackerQuestions, setTrackerQuestions] = useState<AdminTrackerQuestion[]>([])

  async function fetchTrackerQuestions() {
    const questions = await TrackerQuestionsAPI.index({ associationType })
    setTrackerQuestions(questions)
  }

  useEffect(() => {
    // eslint-disable-next-line
    fetchTrackerQuestions()
  }, [])

  const filteredQuestions = trackerQuestions.filter(question =>
    new RegExp(query.toLowerCase()).test(question.type.toLowerCase())
  )

  return (
    <Modal fullScreen opened={opened} onClose={onCancel} title={<h3>{title}</h3>}>
      <Text size={'md'} mt={20} mb={20}>
        Select a tracker question
      </Text>
      <TextInput
        id="search-tracker-questions-input"
        mb={20}
        value={query}
        onChange={e => setQuery(e.target.value)}
        label="Search"
      />

      {filteredQuestions.map((trackerQuestion, index) => (
        <button
          key={index}
          onClick={async () => {
            if (!selectedTrackerQuestionIds.includes(trackerQuestion.id)) {
              onSelectTrackerQuestion(trackerQuestion)
            }
          }}
        >
          <TrackerQuestionCard
            trackerQuestion={trackerQuestion}
            color={selectedTrackerQuestionIds.includes(trackerQuestion.id) ? 'secondary' : 'primary'}
          />
        </button>
      ))}
    </Modal>
  )
}

import { useEffect, useState } from 'react'
import FormGroup from '../../components/forms/utils/form-group'
import { Anchor, Breadcrumbs, Button, Title } from '@mantine/core'
import EventTriggersAPI, { EventTrigger } from '../../api/supportEventTriggers'
import routes from '../../config/routes'
import { useNavigate, useParams } from 'react-router-dom'
import FormButtonGroup from '../../components/forms/utils/form-button-group'
import { Link } from 'react-router-dom'
import useAuthEffect from '../../hooks/useAuthEffect'
import EventTriggerForm from '../../components/forms/event-trigger-form'
import Loader from '../../components/loader'

export default function EventTriggersEditPage() {
  const params = useParams()
  const navigate = useNavigate()
  const [name, setName] = useState('')
  const [nameErrors, setNameErrors] = useState<string[]>([])
  const [eventTrigger, setEventTrigger] = useState<EventTrigger | null>(null)
  const [errorFetchingEventTrigger, setErrorFetchingEventTrigger] = useState(false)

  const hasChanges = eventTrigger && name !== eventTrigger.name

  useAuthEffect(() => {
    async function fetchEventTrigger(id: string) {
      try {
        const eventTrigger = await EventTriggersAPI.show(id)
        setEventTrigger(eventTrigger)
        setName(eventTrigger.name)
      } catch (error) {
        setErrorFetchingEventTrigger(true)
      }
    }

    // eslint-disable-next-line
    fetchEventTrigger(params.id!)
  })

  if (!eventTrigger) return <Loader />

  return (
    <div className="page">
      <Breadcrumbs>
        <Anchor component={Link} to={routes.app.eventTriggers.index}>
          event triggers
        </Anchor>
        <Anchor component={Link} to={routes.app.eventTriggers.show(eventTrigger.id)}>
          {eventTrigger.name}
        </Anchor>
        <Anchor component={Link} to={routes.app.eventTriggers.edit(eventTrigger.id)}>
          edit
        </Anchor>
      </Breadcrumbs>

      <Title>Edit {eventTrigger.name}</Title>

      <EventTriggerForm eventTrigger={eventTrigger} />
    </div>
  )
}
